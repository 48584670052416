<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <el-main class="main">
                <div class="main_item">
                    <div class="jinru">
                    <!-- <a href="https://baidu.com" target="_blank"> -->
                        欢迎进入管理系统
                    </div>
                    <!-- </a> -->
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";


const router = useRouter();
    const route = useRoute();
   
</script>

<style>
    .leftnavbar {
        position: fixed;
        top: 0;
        left: 0;
        width: 15vw;
        height: 100vh;
        background-color: #545c64;
    }

    .container {
        position: fixed;
        top: 0;
        left: 15vw;
    }

    .container_top {
        width: 85vw;
        height: 10vh;
        background-color: #545c64;
    }

    .main {
        position: fixed;
        top: 10vh;
        left: 15vw;
        width: 85vw;
        height: 90vh;
    }

    .main_item {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 50px;
    }
    .jinru{
        margin-top: -20vh;
        margin-left: -10vh;
    }
/**
add by Liwh time:2024-09-01 description:为弹窗添加全局样式
 */
    .el-dialog {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      margin: 0px !important;
    }
</style>