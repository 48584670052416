<template>
    <el-menu :default-active="activeIndex2" class="el-menu-demo" mode="horizontal" background-color="#545c64"
        text-color="#fff" active-text-color="#ffd04b" @select="handleSelect" router>
        <el-sub-menu index="2" class="top_item">
            <template #title class="item_font">
                <p class="item_font1">{{ data }}</p>
            </template>
            <el-menu-item index="/" class="item_font" @click="logoout" :icon="button">退出登录</el-menu-item>
            <!-- <el-menu-item index="/profile" class="item_font">个人中心</el-menu-item> -->
            <el-menu-item index="/changethepassword" class="item_font">修改密码</el-menu-item>
        </el-sub-menu>
    </el-menu>
</template>

<script setup>
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import instance from '../network/storester.js'
import { setting } from '@element-plus/icons-vue'
import { ref } from 'vue'
import router from '@/router'

//储存用户名
const data = localStorage.getItem('myData');
const token = ref(localStorage.getItem('token') || '');
const logoout = () => {
    //清除浏览器缓存
    // sessionStorage.clear();
    // //清楚本地缓存
    // localStorage.clear();
    instance.post('/api/domain/logout')
        .then(response => {
            console.log(response.data)
            //清除浏览器缓存
            sessionStorage.clear();
            //清楚本地缓存
            localStorage.clear();
            // token.value = '';
            // location.reload();
            console.log(localStorage.getItem('token'));
            console.log(localStorage.getItem('authID'));
            router.push('/');
            ElMessage({
                message: '退出登录成功',
                type: 'success',
            })
        })
        .catch(error => {
            console.error(error);
            //清除浏览器缓存
            sessionStorage.clear();
            //清楚本地缓存
            localStorage.clear();
            router.push('/');
        });

}
  window.onbeforeunload = ()=>{
    console.log(localStorage.getItem('token') + '退出');
    if (localStorage.getItem('token') === '') {
      console.log('退出');
      router.push('./')
    }
  }
</script>

<style>
.top_item {
    position: fixed;

    right: 0;
    height: 7vh;
}

.item_font1 {
    font-size: 12px;
    margin-left: 8vw;
}

.item_font {
    font-size: 20px;
}

.container_top {
    height: 7vh !important;
}
</style>