<!-- 权限管理 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <el-main class="main">
                <div class="main_item">
                    <div class="rermissions">
                        <el-button type="primary" @click="AddPermissions" class="newrole">新增权限</el-button>
                        <el-table :data="rermissionse" height="400" style="width: 100%">
                            <el-table-column prop="name" label="权限划分" width="180" />
                            <el-table-column prop="gmtCreate" label="创建时间" idth="180" />
                            <el-table-column prop="gmtModified" label="修改时间" idth="180" />
                            <el-table-column prop="description" label="权限描述" idth="180" />
                            <el-table-column prop="value" label="路径" idth="180" />
                        </el-table>
                    </div>
                    <div v-if="showModalse" class="modal">
                        <div class="modals-content">
                            <span class="close" @click="toggleModalse">&times;</span>
                            <span class="edadmin">新增权限</span>
                            <el-form :model="form" label-width="auto" style="max-width: 300px">
                                <el-form-item label="权限划分">
                                    <el-input v-model="form.name" />
                                </el-form-item>
                                <el-form-item label="权限描述">
                                    <el-input v-model="form.description" />
                                </el-form-item>
                                <el-form-item label="权限路径">
                                    <el-input v-model="form.route" />
                                </el-form-item>
                                <el-form-item label="权限排序">
                                    <el-input v-model="form.sort" />
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="confirm">确认</el-button>
                                    <el-button @click="toggleModalse">取消</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script setup>
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { ref, toRaw } from 'vue'


const rermissions = ref(null)
const rermissionse = ref([])
const showModalse = ref(false)
const form = ref({
    name: '',
    description: '',
    route: '',
    sort: ''
})

instance.get('/api/adminPermission/getPermissionList')
    .then(response => {
        rermissions.value = response.data.data
        console.log(rermissions.value);
        rermissionse.value = toRaw(rermissions.value)
        console.log(rermissionse.value);
    }).catch(error => {
        console.log(error);
        ElMessage({
            showClose: true,
            message: '请登录',
            type: 'error',
        })
        router.push('./')
    })
const toggleModalse = () => {
    showModalse.value = !showModalse.value;
};
const AddPermissions = () => {
    showModalse.value = !showModalse.value;
}
const confirm = () => {
    if (form.value.name == '' || form.value.description == '' || form.value.route == '' || form.value.sort == '') {
        ElMessage({
            showClose: true,
            message: '有内容未填写',
            type: 'error',
        })
        return;
    }
    const addroute = {
        name: form.value.name,
        description: form.value.description,
        route: form.value.route,
        sort: form.value.sort
    }
    instance.post('/api/adminPermission/AddPermission', addroute)
        .then(response => {
            ElMessage({
                showClose: true,
                message: '新增成功',
                type: 'success',
            })
        }).catch(error => {
            console.log(error)
            ElMessage({
            showClose: true,
            message: '新增失败',
            type: 'error',
        })
        })

}
</script>

<style>


.container {
    position: fixed;
    top: 0;
    left: 15vw;
}



.main {
    position: fixed;
    top: 10vh;
    left: 15vw;
    width: 85vw;
    height: 90vh;
}

.rermissions {
    width: 90%;
    height: 100%;
}

.modals-content {
    background-color: #fefefe;
    margin: 8% 15% 15% 35%;
    padding: 20px;
    border: 1px solid #888;
    width: 400px;
    left: 50vw;
}

.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.modal .edadmin {
    font-size: 16px;
}
</style>