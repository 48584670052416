<!-- 库存管理 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <el-main class="main">
                <div class="main_item">
                    <div class="allusershang">
                        <div class="shang">
                            <div class="alluserheade">
                                <el-button type="primary" @click="synchronization">同步</el-button>
                            </div>
                            <div class="alluserbodye">
                                <el-table ref="multipleTableRef" :data="management" style="width: 90%"
                                    @selection-change="handleSelectionChange">
                                    <el-table-column type="selection" width="55" />
                                    <el-table-column label="序号" width="50">
                                        <template v-slot="{ $index }">
                                            {{ $index + 1 }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="goodsName" label="名称" width="100">
                                    </el-table-column>
                                    <el-table-column prop="goodsId" label="商品ID" width="100">
                                    </el-table-column>
                                    <el-table-column prop="" label="是否同步" width="50">
                                        <template #default="scope">
                                            <el-tag v-if="scope.row.State == 1" type="success">已同步</el-tag>
                                            <el-tag v-if="scope.row.State == 0" type="error"
                                                style="color: red;">未同步</el-tag>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="price" label="价格" width="50">
                                    </el-table-column>
                                    <el-table-column prop="storage" label="库存" width="50">
                                    </el-table-column>
                                </el-table>
                            </div>
                            <div v-if="showModals" class="modeae">
                                <div class="modals-content">
                                    <span class="close" @click="toggleModals">&times;</span>
                                    <span class="edadmin">增加分类</span>
                                    <el-form :model="form" label-width="auto" style="max-width: 30vw; margin-top: 5vh">
                                        <el-form-item label="门店名">
                                            <el-select v-model="value1" placeholder="请选择商品的门店" @change="addsd">
                                                <el-option v-for="item in store" :key="item.value" :label="item.name"
                                                    :value="item.id" />
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item label="分类名">
                                            <el-select v-model="values" clearable placeholder="请选择商品的分类">
                                                <el-option v-for="item in managemente" :key="item.value"
                                                    :label="item.name" :value="item.id" />
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item>
                                            <el-button type="primary" @click="confirm">确认</el-button>
                                            <el-button @click="toggleModals">取消</el-button>
                                        </el-form-item>
                                    </el-form>
                                </div>
                            </div>
                            <!-- <div class="alluserfoots">
                        <div class="demo-pagination-blocks">
                            <el-pagination v-model:current-page="currentPage4" v-model:page-size="pageSize4"
                                @size-change="handleSizeChangese" @current-change="handleCurrentChangese"
                                :current-page="currentPage4" :page-sizes="[10, 20, 30, 40]" :page-size="10"
                                layout="total, sizes, prev, pager, next, jumper" :total="tatol">
                            </el-pagination>
                        </div>
                    </div> -->
                        </div>
                    </div>
                    </div>
                    </el-main>
        </el-container>
    </el-container>
</template>
<script setup>
import { ref, reactive } from 'vue';
import { toRaw } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox } from 'element-plus'


const management = ref([]);
const managem = ref(null);
const loading = ref(true)
const router = useRouter();
const state = ref(null)

//todo: 获取存储在本地的authID
const authID = localStorage.getItem('authID')
console.log(authID);
//todo: 将字符转换成对应的百分比编码
const authIds = encodeURIComponent(authID)
// console.log(authIds);
const showModals = ref(false)
const storee = ref('')
const value1 = ref('')
const values = ref('')

instance.get(`/api/inventory/getCommodityAll?authID=${authIds}`)
    .then(response => {
        // console.log(authIds);
        // console.log(11111111);
        // console.log(response);
        if (response.data.data.retCode === "查询商品失败") {
            ElMessage({
                type: 'error',
                message: `账号失效，请联系管理员!`,
                // message: `${response.data.data.retDesc}`,
            })
            return;
        }
        managem.value = response.data.data.goodsList
        // console.log(managem.value[0].goodsId);
        instance.get('/api/tproduct/getSystemType')
            .then(response => {
                // console.log(response);
                state.value = response.data.data
                // console.log(stringArray);
                for (let i = 0; i < managem.value.length; i++) {
                    for (let j = 0; j < state.value.length + 1; j++) {
                        // console.log(parseInt(managem.value[i].goodsId));
                        if (parseInt(managem.value[i].goodsId) === parseInt(state.value)) {
                            managem.value[i].State = 1
                            // console.log('成功');
                            // return;
                        } else {
                            managem.value[i].State = 0
                            // console.log('失败');
                        }
                        // console.log(managem.value[i].goodsId);
                    }
                }
            }
            ).catch(error => {
                console.log('账号失效，请联系管理员');
            })

        management.value = toRaw(managem.value)
        // console.log(management.value);
        loading.value = false
    }).catch(error => {
        console.log(error);
        ElMessage({
            showClose: true,
            message: '请登录',
            type: 'error',
        })
        router.push('./')
    })
const managemente = ref([])
const searche = ref('')
const addsd = (id) => {
    console.log(id);
    instance.get(`/api/stores/cate/${id}`)
        .then(response => {
            console.log(id);
            searche.value = response.data.data;//获取数据
            managemente.value = toRaw(searche.value);
            console.log(managemente.value);
        }).catch(error => {
            console.log(error);
            console.log(error);
            ElMessage({
                showClose: true,
                message: '请登录',
                type: 'error',
            })
            // router.push('/')

        })
}


const stores = ref(null)
const store = ref([])
const toggleModals = () => {
    showModals.value = !showModals.value;
};


instance.get(`/api/stores`)
    .then(response => {
        stores.value = response.data;
        store.value = toRaw(stores.value)
        console.log(store.value);
    })
    .catch(error => {
        console.log(error);
        ElMessage({
            showClose: true,
            message: '请登录',
            type: 'error',
        })
        router.push('./')
    })
const stringArray = ref('')
const confirm = () => {
    console.log(values.value);
    if (values.value === '') {
        ElMessage({
            type: 'error',
            message: `请选择分类`,
        })
        return;
    }
    for (let i = 0; i < cancel.value.length; i++) {
        instance.get(`/api/inventory/getCommodity?goodsId=${cancel.value[i].goodsId}&authID=${authIds}`)
            .then(response => {
                if (response.data.data.retCode === "查询商品失败") {
                    ElMessage({
                        type: 'error',
                        message: `${response.data.data.retDesc}`,
                    })
                    return;
                }
                console.log(response.data.data.goodsList);
                goodsLists.value = response.data.data.goodsList
                console.log(goodsLists.value.productStatus);
                if (goodsLists.value.productStatus === "ShangJia") {
                    console.log('1111');
                    goodsLists.value.productStatus = 1
                }
                if (goodsLists.value.productStatus === 'XiaJia') {
                    console.log('2222');
                    goodsLists.value.productStatus = 0
                }
                const stringArray = parseInt(goodsLists.value.goodsId);
                // // console.log(2222);
                // let length = stringArray.toString().length
                // const add = goodsLists.value.goodsId.length - length
                // const zeros = Array.from({ length: add }, () => 0).join('');
                // console.log(zeros + stringArray);
                // let addesa = zeros + stringArray
                // console.log(parseFloat(addesa));
                if (stringArray === 0) {
                    ElMessage({
                        type: 'error',
                        message: `商品ID不能为0`,
                    })
                } else {
                    if (goodsLists.value.category === 'Pn') {
                        console.log('进入了上面');
                        const addes = {
                            "id": stringArray,   // goodsLists.value.goodsId
                            "name": goodsLists.value.goodsName,
                            "price": goodsLists.value.price,
                            "commission": goodsLists.value.commission,
                            "categoriesId": values.value,
                            "stock": goodsLists.value.storage,
                            "storeId": goodsLists.value.storeId,
                            "productStatus": goodsLists.value.productStatus,
                            "coverUrl": goodsLists.value.coverUrl,
                            "coverUrl1": goodsLists.value.coverUrl1,
                            "coverUrl2": goodsLists.value.coverUrl2,
                            "coverUrl3": goodsLists.value.coverUrl3,
                            "coverUrl4": goodsLists.value.coverUrl4,
                            "systemType": 1,
                        }
                        instance.post('/api/tproduct', addes)
                            .then(response => {
                                console.log(response);
                                if (response.data.code !== 0) {
                                    loading.value = true
                                    ElMessage({
                                        type: 'success',
                                        message: `同步成功`,
                                    })
                                    // siftsea();
                                    window.location.reload()
                                    return;
                                }
                                ElMessage({
                                    type: 'error',
                                    message: `${response.data.msg}`,
                                })
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else if (goodsLists.value.category === 'En') {
                        console.log('进入了下面');
                        const addes = {
                            "id": stringArray,
                            "name": goodsLists.value.goodsName,
                            "price": goodsLists.value.price,
                            "commission": goodsLists.value.commission,
                            "categoriesId": values.value,
                            "stock": goodsLists.value.storage,
                            "storeId": goodsLists.value.storeId,
                            "productStatus": goodsLists.value.productStatus,
                            "coverUrl": goodsLists.value.coverUrl,
                            "coverUrl1": goodsLists.value.coverUrl1,
                            "coverUrl2": goodsLists.value.coverUrl2,
                            "coverUrl3": goodsLists.value.coverUrl3,
                            "coverUrl4": goodsLists.value.coverUrl4,
                            "systemType": 1,
                        }
                        console.log(addes);
                        instance.post('/api/tproduct', addes)
                            .then(response => {
                                console.log(response);
                                if (response.data.code !== 0) {
                                    loading.value = true
                                    ElMessage({
                                        type: 'success',
                                        message: `同步成功`,
                                    })
                                    // siftsea();
                                    window.location.reload()
                                    return;
                                }
                                ElMessage({
                                    type: 'error',
                                    message: `${response.data.msg}`,
                                })
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    }
                }
            }).catch(error => {
                console.log(error);
            })

    }
}
const cancel = ref([])
const handleSelectionChange = (val) => {
    console.log(val);
    cancel.value = val

}
const synch = ref(null)
const goodsLists = ref('')
const synchronization = () => {
    // console.log(id);

    console.log(cancel.value);
    if (cancel.value.length === 0) {
        ElMessage({
            type: 'error',
            message: `请先勾选需要添加的内容`,
        })
        return;
    }
    showModals.value = !showModals.value;
}
</script>
<style>
.container {
    position: fixed;
    top: 0;
    left: 15vw;
}



.main {
    position: fixed;
    top: 10vh;
    left: 11vw;
    width: 85vw;
    height: 90vh;
}

.OrderNumber {
    /* top: -46.5vh !important; */
    left: -3vw !important;
}

.orders {
    position: fixed;
    margin-top: 15vh;
    left: 8vw;
}

.fen .el-select {
    position: fixed;
    top: 14vh;
    left: 28vw;
    width: 14vw;
    font-size: 12px;
}

.comm .el-table {
    position: fixed;
    top: 6%;
    left: 1%;
}

.comm .el-table tr.el-table__row {
    height: 65px !important;
    font-size: 12px !important;
}

.comm .el-button {
    height: 24px !important;
    font-size: 10px !important;
}

.allusershang {
    width: 90vw;
    height: 90vh;
    margin-top: -30vh;
    margin-left: -4vw;
}

.shang {
    /* width: 85%; */
    font-size: 12px !important;
}

.el-table {
    width: 100%;
}

.el-table__header-wrapper table,
.el-table__body-wrapper table {
    width: 100% !important;
}

.el-table__body,
.el-table__footer,
.el-table__header {
    table-layout: auto;
}

.addproduct {
    position: fixed;
    top: 14vh;
    right: 20vw;
}

.addproducts {
    position: fixed;
    top: 14vh;
    right: 15vw;
}

.fen {
    position: fixed;
    top: 13vh;
    z-index: 1;
}

/* .fen .el-switch {
    top: -46.5vh !important;
    left: -5vw !important;
} */

.fen .el-input {
    width: 15vw;
}

.fen .switchs {
    left: 30vw;
}

.alluserfoots .demo-pagination-blocks {
    position: fixed;
    width: 100vw;
    top: 94vh;
    height: 10vh;
    left: 30vw;
    /* background-color: red; */
}

.modeae .edadmin {
    font-size: 16px;
}

.modeae {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /* overflow: auto; */
    background-color: rgba(0, 0, 0, 0.5);
}

.alluserheade {
    margin-top: 10vh;
}
</style>
