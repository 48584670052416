<!-- 商品编辑 -->
<template>
    <el-container class="Wrapper">
        <el-container class="containerse">
            <el-main>
                <div class="addition" :model="loginForm">
                    <el-form :label-position="label - postion">
                        <p>
                            <span class="name"><i style="color: red;">*</i>商品名称：</span>
                            <span class="input"><el-input v-model="names" placeholder="请输入商品名称" clearable /></span>
                        </p>
                        <p v-if="storeLV">
                            <span class="name"><i style="color: red;">*</i>商品门店：</span>
                            <span> <el-select v-model="value" clearable placeholder="请选择商品的门店" @change="addsds(id)">
                                    <!-- <el-option v-for="item in store" :key="item.value" :label="item.name"
                                        :value="item.id" /> -->
                                </el-select></span>
                        </p>
                        <p>
                            <span class="name" v-if="value != ''"><i style="color: red;">*</i>商品分类：</span>
                            <span>
                                <el-select v-if="value != ''" v-model="values" clearable placeholder="请选择商品的分类">
                                    <el-option v-for="item in managemente" :key="item.value" :label="item.name"
                                        :value="item.id" />
                                </el-select>
                            </span>
                        </p>
                        <p>
                            <span class="name"><i style="color: red;">*</i>商品状态：</span>
                            <span> <el-select v-model="state" clearable placeholder="请选择商品的状态" @change="addsde">
                                    <el-option v-for="item in managementesa" :key="item.value" :label="item.label"
                                        :value="item.value" />
                                </el-select></span>
                        </p>
                        <p>
                            <span class="name"><i style="color: red;">*</i>商品库存</span>
                            <span class="input"><el-input v-model="stock" placeholder="请输入商品库存" clearable
                                    v-on:keyup="checkNun" /></span>
                        </p>
                        <p>
                            <span class="name"><i style="color: red;">*</i>商品价格:</span>
                            <span class="input"><el-input v-model="prices" placeholder="请输入价格" clearable
                                    v-on:keyup="checkNus" /></span>
                            <span style="color: red; font-size: 12px;" v-if="!isNus">请输入数字</span>
                        </p>
                        <p>
                            <span class="name">商品封面图</span>
                            <span class="avatar1">
                                <el-upload v-model:file-list="fileList"
                                    action="https://www.jingtengyiliao.com/api/announcement/upload" name="file" limit="5"
                                    list-type="picture-card" :on-preview="handlePictureCardPreview"
                                    :on-remove="handleRemove">
                                    <el-icon>
                                        +
                                    </el-icon>
                                </el-upload>
                                <el-dialog v-model="dialogVisible">
                                    <img w-full :src="dialogImageUrl" alt="Preview Image" />
                                </el-dialog>
                            </span>

                        </p>
                        <p>
                            <span class="name">商品详情图</span>
                        <div class="xiang">
                            <span class="yi avatar1">
                                <el-upload v-model:file-list="fileListt"
                                    action="https://www.jingtengyiliao.com/api/announcement/upload" name="file" limit="10"
                                    list-type="picture-card" :on-preview="handlePictureCardPreview"
                                    :on-remove="handleRemove">
                                    <el-icon>
                                        +
                                    </el-icon>
                                </el-upload>
                                <el-dialog v-model="dialogVisiblet">
                                    <img w-full :src="dialogImageUrlt" alt="Preview Image" />
                                </el-dialog>
                            </span>
                        </div>
                        </p>
                        <p>
                            <span class="name">商品详情视频</span>
                            <span class="avatar1">
                                <el-upload class="upload-demo" action="" :http-request="upload_16"
                                    :before-upload="beforeAvatarUploads" multiple :limit="3">
                                    <video v-if="videoUrl !== ''" :src="videoUrl" controls autoplay></video>
                                    <el-button v-else type="primary">点击上传</el-button>
                                    <div v-if="uploading">正在上传...</div>
                                    <div v-if="uploaded">上传完成！</div>
                                </el-upload>
                                <img v-if="videoUrl != '' && videoUrl != undefined" @click="videoUrl1" class="delete"
                                    src="../assets/delete.png"></span>
                        </p>
                        <p class="button">
                            <el-button type="primary" @click="addtoses()">保存</el-button>
                            <el-button @click="Cancel">取消</el-button>
                        </p>
                        <p></p>
                    </el-form>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script setup>
import { ref } from 'vue';
import { toRaw } from 'vue';
import { useRoute, useRouter } from "vue-router";
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox, Plus } from 'element-plus'


const route = useRoute();
const router = useRouter();
const checkList = ref([])
const stock = ref('')
stock.value = route.query.stock
const value = ref('');
value.value = route.query.storeName
console.log(value);
const managemente = ref([]);
const searche = ref(null)
const values = ref('')
values.value = route.query.categoriesName
console.log(route.query.categoriesName);
const systemType = route.query.systemType
console.log(route.query.systemType);
const storee = ref('')
const stores = ref([]);
const textarea = ref('')
// const commission = ref('')
// commission.value = route.query.commission
const revisese = ref([]);
const revises = ref(undefined);
//获取到图片的url地址
//获取到图片的url地址
const imageUrl = ref(``);
const imageUrl1 = ref('');
const imageUrl2 = ref('');
const imageUrl3 = ref('');
const imageUrl4 = ref('');
const imageUrl5 = ref('');
const imageUrl6 = ref('');
const imageUrl7 = ref('');
const imageUrl8 = ref('');
const imageUrl9 = ref('');
const imageUrl11 = ref('')
const imageUrl12 = ref('')
const imageUrl13 = ref('')
const imageUrl14 = ref('')
const imageUrl15 = ref('')
const isNue = ref(true)
const isNun = ref(true)
const isNus = ref(true)
const state = ref('')
const fileList = ref([])
const dialogImageUrl = ref('')
const dialogVisible = ref(false)
const fileListt = ref([])
const dialogImageUrlt = ref('')
const dialogVisiblet = ref(false)
const addsde = (value) => {
    state.value = value
    console.log(state.value);
}
const handlePictureCardPreview= (uploadFile) => {
    console.log(uploadFile.url);
  dialogImageUrl.value = uploadFile.url
  dialogVisible.value = true
}
const reg = /^\d{0,9}(\.\d{0,9})?$/
const checkNus = () => {
    // 匹配数字的正则表达式
    if (!reg.test(stock.value)) {
        isNun.value = false
    } else {
        isNun.value = true
    }
}
state.value = route.query.productStatus
const checkNun = () => {
    // 匹配数字的正则表达式
    if (!reg.test(stock.value)) {
        isNun.value = false
    } else {
        isNun.value = true
    }
}
const managementesa = [
    {
        value: '1',
        label: '上架',
    },
    {
        value: '0',
        label: '下架',
    }
]
// const checkNue = () => {
//     // 匹配数字的正则表达式
//     if (!reg.test(commission.value)) {
//         isNue.value = false
//     } else {
//         isNue.value = true
//     }
// }
console.log(route.query.msg);
console.log(route.query.name);
console.log(route.query.price);
console.log(route.query.images);
console.log(route.query.storeName);
console.log(route.query.categoriesName);
console.log(route.query.storeId);
const loginForm = ref({
    storename: route.query.name,
    storprice: route.query.price,
    storimages: route.query.images,
    storeName: route.query.storeName,
});
const videoUrl1 = () => {
    instance.get('/api/productVideo/' + route.query.msg)
        .then(response => {
            console.log(response.data);
            if (response.data.data === undefined) {
                videoUrl.value = ''
            } else {
                instance.delete(`/api/productVideo/${route.query.msg}`)
                videoUrl.value = ''
            }
        })

}

//监听门店下拉内容
const addsds = (id) => {
    instance.get(`/api/stores/cate/${localStorage.getItem('sysStoreId')}`)
        .then(response => {
            console.log(id);
            searche.value = response.data.data;//获取数据
            managemente.value = toRaw(searche.value);
            console.log(managemente.value);
        }).catch(error => {
            console.log(error);
            console.log(error);
            ElMessage({
                showClose: true,
                message: '请登录',
                type: 'error',
            })
            router.push('/')

        })
}
const Cancel = () => {
    router.push('./commodity')
}

//获取商品名字跟价格
const names = ref(route.query.name);
const prices = ref(route.query.price);
console.log(names.value);
console.log(prices.value);
console.log(route.query.storeId);
//获取到门店下面的分类
instance.get(`/api/stores/cate/${route.query.storeId}`)
    .then(response => {
        searche.value = response.data.data;//获取到数据
        managemente.value = toRaw(searche.value);
        console.log(managemente.value);
    }).catch(error => {
        console.log(error);
        console.log('1111');
        console.log(error);
        ElMessage({
            showClose: true,
            message: '请登录',
            type: 'error',
        })
        // router.push('/')

    })

//判断是否是超管
if (localStorage.getItem('permissionsStatus') === '0') {
    instance.get('/api/stores')
        .then(response => {
            //获取门店数据
            storee.value = response.data;
            console.log(storee.value);
            stores.value = toRaw(storee.value);//保存并渲染
            console.log(stores.value);
        }).catch(error => {
            console.log(error);
            ElMessage({
                showClose: true,
                message: '请登录',
                type: 'error',
            })
            // router.push('/')
        })
} else {
    //获取门店数据，
    instance.get(`/api/stores/${localStorage.getItem('sysStoreId')}`)
        .then((response => {
            storee.value = response.data.data;
            stores.value = [toRaw(storee.value)]//保存并渲染
            console.log(stores.value);
        }))
        .catch(error => {
            console.log(error);
            ElMessage({
                showClose: true,
                message: '请登录',
                type: 'error',
            })
            // router.push('/')
        })
}
//获取到商品详情图
instance.get('/api/tproduct/details/' + route.query.msg)
    .then(response => {
        revises.value = response.data.data;
        console.log(revises.value);
        // // 判断详情图是否为0
        if (revises.value.length !== 0) {
            revisese.value = toRaw(revises.value);
            console.log(revisese.value);
            instance.get(`/api/tproduct/details/` + route.query.msg)
                .then(response => {
                    //找出为空的详情图
                    // console.log(response.data.data[0].img1)
                    if (response.data.data[0].img10 === null || response.data.data[0].img10 === '') {
                        imageUrl9.value = '';
                    } else {
                        fileListt.value = [
                            {
                                name: 1,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img1}`
                            },
                            {
                                name: 2,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img2}`
                            },
                            {
                                name: 3,
                                url: `https://www.www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img3}`
                            },
                            {
                                name: 4,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img4}`
                            },
                            {
                                name: 5,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img5}`
                            },
                            {
                                name: 6,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img6}`
                            },
                            {
                                name: 7,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img7}`
                            },
                            {
                                name: 8,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img8}`
                            },
                            {
                                name: 9,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img9}`
                            },
                            {
                                name: 10,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img10}`
                            },
                        ]
                        return;
                    }
                    if (response.data.data[0].img9 === null || response.data.data[0].img9 === '') {
                        imageUrl8.value = '';
                    } else {
                        fileListt.value = [
                            {
                                name: 1,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img1}`
                            },
                            {
                                name: 2,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img2}`
                            },
                            {
                                name: 3,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img3}`
                            },
                            {
                                name: 4,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img4}`
                            },
                            {
                                name: 5,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img5}`
                            },
                            {
                                name: 6,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img6}`
                            },
                            {
                                name: 7,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img7}`
                            },
                            {
                                name: 8,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img8}`
                            },
                            {
                                name: 9,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img9}`
                            },
                        ]
                        return;
                    }
                    if (response.data.data[0].img8 === null || response.data.data[0].img8 === '') {
                        imageUrl7.value = '';
                    } else {
                        fileListt.value = [
                            {
                                name: 1,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img1}`
                            },
                            {
                                name: 2,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img2}`
                            },
                            {
                                name: 3,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img3}`
                            },
                            {
                                name: 4,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img4}`
                            },
                            {
                                name: 5,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img5}`
                            },
                            {
                                name: 6,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img6}`
                            },
                            {
                                name: 7,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img7}`
                            },
                            {
                                name: 8,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img8}`
                            }
                        ]
                        return;
                    }
                    if (response.data.data[0].img7 === null || response.data.data[0].img7 === '') {
                        imageUrl6.value = '';
                    } else {
                        fileListt.value = [
                            {
                                name: 1,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img1}`
                            },
                            {
                                name: 2,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img2}`
                            },
                            {
                                name: 3,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img3}`
                            },
                            {
                                name: 4,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img4}`
                            },
                            {
                                name: 5,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img5}`
                            },
                            {
                                name: 6,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img6}`
                            },
                            {
                                name: 7,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img7}`
                            },
                        ]
                        return;
                    }
                    if (response.data.data[0].img6 === null || response.data.data[0].img6 === '') {
                        imageUrl5.value = '';
                    } else {
                        fileListt.value = [
                            {
                                name: 1,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img1}`
                            },
                            {
                                name: 2,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img2}`
                            },
                            {
                                name: 3,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img3}`
                            },
                            {
                                name: 4,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img4}`
                            },
                            {
                                name: 5,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img5}`
                            },
                            {
                                name: 6,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img6}`
                            },
                        ]
                        return;
                    }
                    if (response.data.data[0].img5 === null || response.data.data[0].img5 === '') {
                        imageUrl4.value = '';
                    } else {
                        fileListt.value = [
                            {
                                name: 1,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img1}`
                            },
                            {
                                name: 2,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img2}`
                            },
                            {
                                name: 3,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img3}`
                            },
                            {
                                name: 4,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img4}`
                            },
                            {
                                name: 5,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img5}`
                            },
                        ]
                        return;
                    }
                    if (response.data.data[0].img4 === null || response.data.data[0].img4 === '') {
                        imageUrl3.value = '';
                    } else {
                        fileListt.value = [
                            {
                                name: 1,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img1}`
                            },
                            {
                                name: 2,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img2}`
                            },
                            {
                                name: 3,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img3}`
                            },
                            {
                                name: 4,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img4}`
                            },
                        ]
                        return;
                    }
                    if (response.data.data[0].img3 === null || response.data.data[0].img3 === '') {
                        imageUrl2.value = '';
                    } else {
                        fileListt.value = [
                            {
                                name: 1,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img1}`
                            },
                            {
                                name: 2,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img2}`
                            },
                            {
                                name: 3,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img3}`
                            },
                        ]
                        return;
                    }
                    if (response.data.data[0].img2 === null || response.data.data[0].img2 === '') {
                        imageUrl1.value = '';
                    } else {
                        fileListt.value = [
                            {
                                name: 1,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img1}`
                            },
                            {
                                name: 2,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img2}`
                            },
                        ]
                        return;
                    }
                    if (response.data.data[0].img1 === null || response.data.data[0].img1 === '') {
                        imageUrl.value = '';
                    } else {
                        fileListt.value = [
                            {
                                name: 1,
                                url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data[0].img1}`
                            }
                        ]
                        return;
                    }
                })
        } else {
            // router.push('./commodity')
            // ElMessage({
            //     message: '该商品无商品详情，请添加',
            //     type: 'error',
            // })
            imageUrl.value = '';
            imageUrl1.value = '';
            imageUrl3.value = '';
            imageUrl4.value = '';
            imageUrl5.value = '';
            imageUrl6.value = '';
            imageUrl7.value = '';
            imageUrl8.value = '';
            imageUrl9.value = '';
        }
    }).catch(error => {
        console.log(error);
        ElMessage({
            showClose: true,
            message: '请登录',
            type: 'error',
        })
        // router.push('/')
    })
//获取商品展示图
instance.get('/api/tproduct/' + route.query.msg)
    .then(response => {
        console.log(response.data.data.coverUrl)
        console.log(response.data.data.coverUrl5)
        console.log(response.data.data.coverUrl2);
        console.log(response.data.data.coverUrl3);
        console.log(response.data.data.coverUrl4);
        console.log(response.data.data)

        //判断展示图是否为空
        if (response.data.data.coverUrl5 === null || response.data.data.coverUrl5 === '' ||response.data.data.coverUrl5 === undefined ) {
            imageUrl15.value = '';
        } else {
            fileList.value = [
                {
                    name: 11,
                    url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data.coverUrl}`
                },
                {
                    name: 12,
                    url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data.coverUrl2}`
                },
                {
                    name: 13,
                    url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data.coverUrl3}`
                },
                {
                    name: 14,
                    url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data.coverUrl4}`
                },
                {
                    name: 15,
                    url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data.coverUrl5}`
                },
            ]
            return;
        }
        if (response.data.data.coverUrl4 === null || response.data.data.coverUrl4 === '' ||response.data.data.coverUrl4 === undefined) {
            imageUrl14.value = '';
        } else {
            fileList.value = [
                {
                    name: 11,
                    url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data.coverUrl}`
                },
                {
                    name: 12,
                    url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data.coverUrl2}`
                },
                {
                    name: 13,
                    url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data.coverUrl3}`
                },
                {
                    name: 14,
                    url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data.coverUrl4}`
                }
            ]
            return;
        }
        if (response.data.data.coverUrl3 === null || response.data.data.coverUrl3 === '' ||response.data.data.coverUrl3 === undefined) {
            imageUrl13.value = '';
        } else {
            fileList.value = [
                {
                    name: 11,
                    url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data.coverUrl}`
                },
                {
                    name: 12,
                    url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data.coverUrl2}`
                },
                {
                    name:13,
                    url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data.coverUrl3}`
                },
            ]
            return;
        }
        if (response.data.data.coverUrl2 === null || response.data.data.coverUrl2 === '' ||response.data.data.coverUrl2 === undefined) {
            imageUrl12.value = '';
        } else {
            fileList.value = [
                {
                    name: 11,
                    url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data.coverUrl}`
                },
                {
                    name: 12,
                    url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data.coverUrl2}`
                },
            ]
            return;
        }
        if (response.data.data.coverUrl === null || response.data.data.coverUrl === '' ||response.data.data.coverUrl === undefined) {
            imageUrl11.value = '';
        } else {
            fileList.value = [
                {
                    name: 11,
                    url: `https://www.jingtengyiliao.com/api/common/download?name=${response.data.data.coverUrl}`
                }
            ]
            return;
        }




    }).catch(error => {
        console.log(error);
        // ElMessage({
        //     showClose: true,
        //     message: '请登录',
        //     type: 'error',
        // })
        // router.push('/')
    })


//图片在本地地址
const imgurl = ref('');
const imgurl1 = ref('');
const imgurl2 = ref('');
const imgurl3 = ref('');
const imgurl4 = ref('');
const imgurl5 = ref('');
const imgurl6 = ref('');
const imgurl7 = ref('');
const imgurl8 = ref('');
const imgurl9 = ref('');



console.log(prices);
console.log(names);
const imageUrl16 = ref('');
//图片在本地地址
const imgurl16 = ref('');
const upload_16 = (param) => {
    console.log(param);
    const formData = new FormData();
    formData.append('file', param.file);
    console.log("2222");
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            //本地
            imgurl16.value = response.data.data;
            //请求
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl16.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                })
        }).catch(error => {
            console.log(error);
            console.log('图片上传失败')
        })
}

const name = ref('')
const nama = ref('')
const videoUrl = ref('')
instance.get('/api/productVideo/' + route.query.msg)
    .then(response => {
        console.log(response.data.data);
        if (response.data.data === '' || response.data.data === null || response.data.data === undefined) {
            videoUrl.value = ''
            console.log(1);
            console.log(videoUrl.value);
        } else {
            instance.get(`/api/common/downloadVideo?name=${response.data.data.videoName1}`)
                .then(response => {
                    videoUrl.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                })
            console.log(videoUrl.value);
        }
    })
const beforeAvatarUploads = (file) => {
    console.log(file);
    if (file.type !== 'video/mp4') {
        ElMessage({
            type: 'error',
            message: '请上传mp4格式视频',
        })
        return;
    }
    const formData = new FormData();
    formData.append('file', file);
    instance.post('/api/common/uploadVideo', formData)
        .then(response => {
            console.log('上传成功');
            console.log(response.data.data);
            name.value = response.data.data;
            videoUrl.value = URL.createObjectURL(file)
            console.log(name.value);
        }).catch(response => {
            console.log(response);
            console.log('上传失败')
        })

    console.log(formData);
    // const isJPG = file.type === '.mp4';
    // const isLt2M = file.size / 1024 / 1024 < 200;
    const timer = ref(null);
    timer.value = setTimeout(() => {
        // 执行需要停止的操作
        alert('响应时间超过规定时间');
    }, 10000);
    clearTimeout(timer.value);

    // if (!isJPG) {
    //     alert('上传头像图片只能是 JPG 格式!');
    // }
    if (!isLt2M) {
        alert('上传头像图片大小不能超过 2MB!');
    }
    console.log("1111");
    return isJPG && isLt2M;
};


console.log(name.value);
const specification = ref('')
const addtoses = () => {
    console.log(fileList.value[1]);
    // const regex = /(http:\/\/localhost:8080\/([^,]+))|(https:\/\/199shq.com\/api\/common\/download\?name=([^,]+))/;
    const regex = /(https:\/\/www.jingtengyiliao.com\/api\/common\/download\?name=([^,]+))/;
    const regexs = /(https:\/\/www.jingtengyiliao.com\/api\/common\/download\?name=([^,]+))/;
    // let matches = fileList.value[1].url.match(regex);
    // console.log(matches);
console.log(commission);
    let id = route.query.msg
    ElMessageBox.confirm(
        '是否确认该商品详情',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            // if (commission.value === '') {
            //     ElMessage({
            //         message: '商品修改失败，商品佣金未输入',
            //         type: 'error',
            //     })
            //     return;
            // }
            if (prices.value === '') {
                ElMessage({
                    message: '商品修改失败，商品价格未输入',
                    type: 'error',
                })
                return;
            }
            if (stock.value === '') {
                ElMessage({
                    message: '商品修改失败，商品库存未输入',
                    type: 'error',
                })
                return;
            }
            // if (commission / 10 <= prices) {
            //     ElMessage({
            //         type: 'error',
            //         message: '添加失败，商品佣金大于价格10%',
            //     })
            //     return;
            // }
            if (isNus.value === false) {
                ElMessage({
                    type: 'error',
                    message: '添加失败，商品价格只能填写数字',
                })
                return;
            }
            if (isNue.value === false) {
                ElMessage({
                    type: 'error',
                    message: '添加失败，商品佣金填写有除数字以外',
                })
                return;
            }
            if (isNun.value === false) {
                ElMessage({
                    type: 'error',
                    message: '添加失败，商品库存填写有除数字以外',
                })
                return;
            }
            if (names.value === '') {
                ElMessage({
                    message: '商品修改失败，商品名称未输入',
                    type: 'error',
                })
                return;
            }
            if (fileList.value[0] === undefined) {
                ElMessage({
                    message: '商品修改失败，商品展示图未输入',
                    type: 'error',
                })
                return;
            }
            instance.get('/api/tproduct/details/' + route.query.msg)
                .then(response => {
                    console.log(response.data.data);
                    if (response.data.data.length !== 0) {
                        console.log(1);
                        let img1 = response.data.data[0].img1;
                        let img2 = response.data.data[0].img2;
                        let img3 = response.data.data[0].img3;
                        let img4 = response.data.data[0].img4;
                        let img5 = response.data.data[0].img5;
                        let img6 = response.data.data[0].img6;
                        let img7 = response.data.data[0].img7;
                        let img8 = response.data.data[0].img8;
                        let img9 = response.data.data[0].img9;
                        let img10 = response.data.data[0].img10;
                        if (fileListt.value[0] === undefined) {
                            if (response.data.data[0].img1 === null) {
                                img1 = ""
                            } else {
                                if (fileListt.value[0] === undefined) {
                                    img1 = ""
                                } else {
                                    img1 = response.data.data[0].img1
                                }
                            }

                        } else {
                            let matches = fileListt.value[0].url.match(regex);

                            if (matches === null) {
                                img1 = fileListt.value[0].response.data
                            } else {
                                if (matches[2] === undefined) {
                                    img1 = matches[4]
                                } else {
                                    img1 = matches[2]
                                }
                            }
                            console.log(matches);

                        }
                        if (fileListt.value[1] === undefined) {
                            if (response.data.data[0].img2 === null) {
                                img2 = ""
                            } else {
                                if (fileListt.value[1] === undefined) {
                                    img2 = ""
                                } else {
                                    img2 = response.data.data[0].img2
                                }
                            }

                        } else {
                            let matches = fileListt.value[1].url.match(regex);
                            console.log(matches);
                            if (matches === null) {
                                img2 = fileListt.value[1].response.data
                            } else {
                                if (matches[2] === undefined) {
                                    img2 = matches[4]
                                } else {
                                    img2 = matches[2]
                                }
                            }
                        }
                        if (fileListt.value[2] === undefined) {
                            if (response.data.data[0].img3 === null) {
                                img3 = ""
                            } else {
                                if (fileListt.value[2] === undefined) {
                                    img3 = ""
                                } else {
                                    img3 = response.data.data[0].img3
                                }
                            }

                        } else {
                            let matches = fileListt.value[2].url.match(regex);
                            console.log(matches);
                            if (matches === null) {
                                img3 = fileListt.value[2].response.data
                            } else {
                                if (matches[2] === undefined) {
                                    img3 = matches[4]
                                } else {
                                    img3 = matches[2]
                                }
                            }
                        }
                        if (fileListt.value[3] === undefined) {
                            if (response.data.data[0].img4 === null) {
                                img4 = ""
                            } else {
                                if (fileListt.value[3] === undefined) {
                                    img4 = ""
                                } else {
                                    img4 = response.data.data[0].img4
                                }
                            }

                        } else {
                            let matches = fileListt.value[3].url.match(regex);
                            console.log(matches);
                            if (matches === null) {
                                img4 = fileListt.value[3].response.data
                            } else {
                                if (matches[2] === undefined) {
                                    img4 = matches[4]
                                } else {
                                    img4 = matches[2]
                                }
                            }
                        }
                        if (fileListt.value[4] === undefined) {
                            if (response.data.data[0].img5 === null) {
                                img5 = ""
                            } else {
                                if (fileListt.value[4] === undefined) {
                                    img5 = ""
                                } else {
                                    img5 = response.data.data[0].img5
                                }
                            }

                        } else {
                            let matches = fileListt.value[4].url.match(regex);
                            console.log(matches);
                            if (matches === null) {
                                img5 = fileListt.value[4].response.data
                            } else {
                                if (matches[2] === undefined) {
                                    img5 = matches[4]
                                } else {
                                    img5 = matches[2]
                                }
                            }
                        }
                        if (fileListt.value[5] === undefined) {
                            if (response.data.data[0].img6 === null) {
                                img6 = ""
                            } else {
                                if (fileListt.value[5] === undefined) {
                                    img6 = ""
                                } else {
                                    img6 = response.data.data[0].img6
                                }
                            }

                        } else {
                            let matches = fileListt.value[5].url.match(regex);
                            console.log(matches);
                            if (matches === null) {
                                img6 = fileListt.value[5].response.data
                            } else {
                                if (matches[2] === undefined) {
                                    img6 = matches[4]
                                } else {
                                    img6 = matches[2]
                                }
                            }
                        }
                        if (fileListt.value[6] === undefined) {
                            if (response.data.data[0].img7 === null) {
                                img7 = ""
                            } else {
                                if (fileListt.value[6] === undefined) {
                                    img7 = ""
                                } else {
                                    img7 = response.data.data[0].img7
                                }
                            }

                        } else {
                            let matches = fileListt.value[6].url.match(regex);
                            console.log(matches);
                            if (matches === null) {
                                img7 = fileListt.value[6].response.data
                            } else {
                                if (matches[2] === undefined) {
                                    img7 = matches[4]
                                } else {
                                    img7 = matches[2]
                                }
                            }
                        }
                        if (fileListt.value[7] === undefined) {
                            if (response.data.data[0].img8 === null) {
                                img8 = ""
                            } else {
                                if (fileListt.value[7] === undefined) {
                                    img8 = ""
                                } else {
                                    img8 = response.data.data[0].img8
                                }
                            }

                        } else {
                            let matches = fileListt.value[7].url.match(regex);
                            console.log(matches);
                            if (matches === null) {
                                img8 = fileListt.value[7].response.data
                            } else {
                                if (matches[2] === undefined) {
                                    img8 = matches[4]
                                } else {
                                    img8 = matches[2]
                                }
                            }
                        }
                        if (fileListt.value[8] === undefined) {
                            if (response.data.data[0].img9 === null) {
                                img9 = ""
                            } else {
                                if (fileListt.value[8] === undefined) {
                                    img9 = ""
                                } else {
                                    img9 = response.data.data[0].img9
                                }
                            }

                        } else {
                            let matches = fileListt.value[8].url.match(regex);
                            console.log(matches);
                            if (matches === null) {
                                img9 = fileListt.value[8].response.data
                            } else {
                                if (matches[2] === undefined) {
                                    img9 = matches[4]
                                } else {
                                    img9 = matches[2]
                                }
                            }
                        }
                        if (fileListt.value[9] === undefined) {
                            if (response.data.data[0].img10 === null) {
                                img10 = ""
                            } else {
                                if (fileListt.value[9] === undefined) {
                                    img10 = ""
                                } else {
                                    img10 = response.data.data[0].img10
                                }
                            }

                        } else {
                            let matches = fileListt.value[9].url.match(regex);
                            console.log(matches);
                            if (matches === null) {
                                img10 = fileListt.value[9].response.data
                            } else {
                                if (matches[2] === undefined) {
                                    img10 = matches[4]
                                } else {
                                    img10 = matches[2]
                                }
                            }
                        }
                        if (stock === '') {
                            ElMessage({
                                message: '商品修改失败，商品库存未添加',
                                type: 'error',
                            })
                            return;
                        }
                        console.log(img1);
                        console.log(img2);
                        console.log(img3);
                        console.log(img4);
                        console.log(img5);
                        console.log(img6);
                        console.log(img7);
                        console.log(img8);
                        console.log(img9);
                        console.log(img10);
                        console.log(id);
                        const add = {
                            "productId": route.query.msg,
                            "img1": img1,
                            "img2": img2,
                            "img3": img3,
                            "img4": img4,
                            "img5": img5,
                            "img6": img6,
                            "img7": img7,
                            "img8": img8,
                            "img9": img9,
                            "img10": img10,
                        };
                        console.log(add);
                        instance.put('/api/details', add)
                            .then(response => {
                                console.log(add);
                                console.log(response.data);
                                console.log('修改成功');
                                // ElMessage({
                                //     message: '商品详情修改成功',
                                //     type: 'success',
                                // })

                            })
                            .catch(error => {
                                console.error(error);
                            });
                        instance.get('/api/tproduct/' + route.query.msg)
                            .then(response => {
                                console.log(response.data.data.coverUrl);
                                console.log(response.data.data.coverUrl2);
                                console.log(response.data.data.coverUrl3);
                                console.log(response.data.data.coverUrl4);
                                console.log(response.data.data.coverUrl5);
                                let coverUrl = response.data.data.coverUrl
                                let coverUrl1 = response.data.data.coverUrl2
                                let coverUrl2 = response.data.data.coverUrl3
                                let coverUrl3 = response.data.data.coverUrl4
                                let coverUrl4 = response.data.data.coverUrl5

                                if (name.value === '') {
                                    instance.get('/api/productVideo/' + route.query.msg)
                                        .then(response => {
                                            nama.value = response.data.data;
                                            if (response.data.data !== '' && response.data.data !== null && response.data.data !== undefined) {
                                                console.log(name.value);
                                                const data = {
                                                    "productId": route.query.msg,
                                                    "videoName1": nama.value,
                                                }
                                                instance.put('/api/productVideo', data)
                                                    .then(response => {
                                                        console.log('上传成功2');
                                                        console.log(response.data);
                                                    }).catch(response => {
                                                        console.log(response);
                                                        console.log('上传失败')
                                                    })
                                            }
                                        }).catch(error => {
                                            console.log(error);
                                            ElMessage({
                                                showClose: true,
                                                message: '请登录',
                                                type: 'error',
                                            })
                                            router.push('/')
                                        })
                                } else {
                                    console.log(14);
                                    instance.get('/api/productVideo/' + route.query.msg)
                                        .then(response => {
                                            nama.value = response.data.data;
                                            console.log(nama.value);
                                            console.log(response.data.data);
                                            if (nama.value === '' || nama.value === null || nama.value === undefined) {
                                                console.log(14);
                                                if (name.value === undefined) {
                                                    name.value = ''
                                                    console.log(14);
                                                }
                                                const data = {
                                                    "productId": route.query.msg,
                                                    "videoName1": name.value
                                                }
                                                instance.post('/api/productVideo', data)
                                                    .then(response => {
                                                        console.log('修改成功3');
                                                        console.log(response.data);
                                                        // router.push('/commodity');
                                                       
                                                        // }
                                                        // router.push('/commodity');
                                                    }).catch(response => {
                                                        console.log(response);
                                                        console.log('上传失败')
                                                    })
                                            } else {
                                                console.log(122);
                                                if (name.value === undefined) {
                                                    name.value = ''
                                                    console.log(122);
                                                    console.log(name.value);
                                                }
                                                const data = {
                                                    "productId": route.query.msg,
                                                    "videoName1": name.value
                                                }
                                                instance.put('/api/productVideo', data)
                                                    .then(response => {
                                                        console.log(name.value);
                                                        console.log('修改成功4');
                                                        console.log(response);
                                                        // router.push('/commodity');
                                                    }).catch(error => {
                                                        console.log(error);
                                                        console.log('修改失败')
                                                    })
                                            }
                                        }).catch(error => {
                                            console.log(error);
                                            ElMessage({
                                                showClose: true,
                                                message: '请登录',
                                                type: 'error',
                                            })
                                            router.push('/')
                                        })
                                }
                                if (fileList.value[0] === undefined) {
                                    if (response.data.data.coverUrl === null) {
                                        coverUrl = ""
                                    } else {
                                        if (fileList.value[0] === undefined) {
                                            coverUrl = ""
                                        } else {
                                            coverUrl = response.data.data.coverUrl
                                        }
                                    }

                                } else {
                                    let matches = fileList.value[0].url.match(regexs);
                                    console.log(matches);
                                    if (matches === null) {
                                        coverUrl = fileList.value[0].response.data
                                    } else {
                                        if (matches[2] === undefined) {
                                            coverUrl = matches[4]
                                        } else {
                                            coverUrl = matches[2]
                                        }
                                    }
                                }
                                if (fileList.value[1] === undefined) {
                                    if (response.data.data.coverUrl1 === null) {
                                        coverUrl1 = ""
                                    } else {
                                        if (fileList.value[1] === undefined) {
                                            coverUrl1 = ""
                                        } else {
                                            coverUrl1 = response.data.data.coverUrl1
                                        }
                                    }

                                } else {
                                    let matches = fileList.value[1].url.match(regexs);
                                    console.log(matches);
                                    if (matches === null) {
                                        coverUrl1 = fileList.value[1].response.data
                                    } else {
                                        if (matches[2] === undefined) {
                                            coverUrl1 = matches[4]
                                        } else {
                                            coverUrl1 = matches[2]
                                        }
                                    }
                                }
                                if (fileList.value[2] === undefined) {
                                    if (response.data.data.coverUrl2 === null) {
                                        coverUrl2 = ""
                                    } else {
                                        if (fileList.value[2] === undefined) {
                                            coverUrl2 = ""
                                        } else {
                                            coverUrl2 = response.data.data.coverUrl2
                                        }
                                    }

                                } else {
                                    let matches = fileList.value[2].url.match(regexs);
                                    console.log(matches);
                                    if (matches === null) {
                                        coverUrl2 = fileList.value[2].response.data
                                    } else {
                                        if (matches[2] === undefined) {
                                            coverUrl2 = matches[4]
                                        } else {
                                            coverUrl2 = matches[2]
                                        }
                                    }
                                }
                                if (fileList.value[3] === undefined) {
                                    if (response.data.data.coverUrl3 === null) {
                                        coverUrl3 = ""
                                    } else {
                                        if (fileList.value[3] === undefined) {
                                            coverUrl3 = ""
                                        } else {
                                            coverUrl3 = response.data.data.coverUrl3
                                        }
                                    }

                                } else {
                                    let matches = fileList.value[3].url.match(regexs);
                                    console.log(matches);
                                    if (matches === null) {
                                        coverUrl3 = fileList.value[3].response.data
                                    } else {
                                        if (matches[2] === undefined) {
                                            coverUrl3 = matches[4]
                                        } else {
                                            coverUrl3 = matches[2]
                                        }
                                    }
                                }
                                if (fileList.value[4] === undefined) {
                                    if (response.data.data.coverUrl4 === null) {
                                        coverUrl4 = ""
                                    } else {
                                        if (fileList.value[4] === undefined) {
                                            coverUrl4 = ""
                                        } else {
                                            coverUrl4 = response.data.data.coverUrl4
                                        }
                                    }

                                } else {
                                    let matches = fileList.value[4].url.match(regexs);
                                    console.log(matches);
                                    if (matches === null) {
                                        coverUrl4 = fileList.value[4].response.data
                                    } else {
                                        if (matches[2] === undefined) {
                                            coverUrl4 = matches[4]
                                        } else {
                                            coverUrl4 = matches[2]
                                        }
                                    }
                                }
                                if (values.value === route.query.categoriesName) {
                                    if (checkList.value.length === 0) {
                                        const ediera = {
                                            id: route.query.msg,
                                            name: names.value,
                                            price: prices.value,
                                            commission: 0,
                                            stock: stock.value,
                                            systemType:route.query.systemType,
                                            productStatus: state.value,
                                            coverUrl: coverUrl,
                                            coverUrl2: coverUrl1,
                                            coverUrl3: coverUrl2,
                                            coverUrl4: coverUrl3,
                                            coverUrl5: coverUrl4,
                                        };
                                        console.log('shangaa');
                                        instance.put(`/api/tproduct`, ediera)
                                            .then(response => {
                                                //处理响应数据
                                                console.log(response);
                                            })
                                            .catch(error => {
                                                console.log(error);
                                            });
                                        ElMessage({
                                            message: '商品修改成功',
                                            type: 'success',
                                        })
                                        return;
                                    } else {
                                        if (checkList.value[3] !== undefined) {
                                            const ediera = {
                                                id: route.query.msg,
                                                name: names.value,
                                                price: prices.value,
                                                systemType:route.query.systemType,
                                                specName: checkList.value[0],
                                                specPrice: prices.value,
                                                specName1: checkList.value[1],
                                                specPrice1: prices.value,
                                                specName2: checkList.value[2],
                                                specPrice2: prices.value,
                                                specName3: checkList.value[3],
                                                specPrice3: prices.value,
                                                commission: 0,
                                                stock: stock.value,
                                                productStatus: state.value,
                                                coverUrl: coverUrl,
                                                coverUrl2: coverUrl1,
                                                coverUrl3: coverUrl2,
                                                coverUrl4: coverUrl3,
                                                coverUrl5: coverUrl4,
                                            };
                                            console.log('shang');
                                            instance.put(`/api/tproduct`, ediera)
                                                .then(response => {
                                                    //处理响应数据
                                                    console.log(response);
                                                })
                                                .catch(error => {
                                                    console.log(error);
                                                });
                                            ElMessage({
                                                message: '商品修改成功',
                                                type: 'success',
                                            })
                                            return;
                                        }
                                        if (checkList.value[2] !== undefined) {
                                            const ediera = {
                                                id: route.query.msg,
                                                name: names.value,
                                                price: prices.value,
                                                systemType:route.query.systemType,
                                                specName: checkList.value[0],
                                                specPrice: prices.value,
                                                specName1: checkList.value[1],
                                                specPrice1: prices.value,
                                                specName2: checkList.value[2],
                                                specPrice2: prices.value,
                                                commission: 0,
                                                stock: stock.value,
                                                productStatus: state.value,
                                                coverUrl: coverUrl,
                                                coverUrl2: coverUrl1,
                                                coverUrl3: coverUrl2,
                                                coverUrl4: coverUrl3,
                                                coverUrl5: coverUrl4,
                                            };
                                            console.log('shang');
                                            instance.put(`/api/tproduct`, ediera)
                                                .then(response => {
                                                    //处理响应数据
                                                    console.log(response);
                                                })
                                                .catch(error => {
                                                    console.log(error);
                                                });
                                            ElMessage({
                                                message: '商品修改成功',
                                                type: 'success',
                                            })
                                            return;
                                        }
                                        if (checkList.value[1] !== undefined) {
                                            const ediera = {
                                                id: route.query.msg,
                                                name: names.value,
                                                price: prices.value,
                                                systemType:route.query.systemType,
                                                specName: checkList.value[0],
                                                specPrice: prices.value,
                                                specName1: checkList.value[1],
                                                specPrice1: prices.value,
                                                commission: 0,
                                                stock: stock.value,
                                                productStatus: state.value,
                                                coverUrl: coverUrl,
                                                coverUrl2: coverUrl1,
                                                coverUrl3: coverUrl2,
                                                coverUrl4: coverUrl3,
                                                coverUrl5: coverUrl4,
                                            };
                                            console.log('shang');
                                            instance.put(`/api/tproduct`, ediera)
                                                .then(response => {
                                                    //处理响应数据
                                                    console.log(response);
                                                })
                                                .catch(error => {
                                                    console.log(error);
                                                });
                                            ElMessage({
                                                message: '商品修改成功',
                                                type: 'success',
                                            })
                                            return;
                                        }
                                        if (checkList.value[0] !== undefined) {
                                            const ediera = {
                                                id: route.query.msg,
                                                name: names.value,
                                                price: prices.value,
                                                systemType:route.query.systemType,
                                                specName: checkList.value[0],
                                                specPrice: prices.value,
                                                commission: 0,
                                                stock: stock.value,
                                                productStatus: state.value,
                                                coverUrl: coverUrl,
                                                coverUrl2: coverUrl1,
                                                coverUrl3: coverUrl2,
                                                coverUrl4: coverUrl3,
                                                coverUrl5: coverUrl4,
                                            };
                                            console.log('shang');
                                            instance.put(`/api/tproduct`, ediera)
                                                .then(response => {
                                                    //处理响应数据
                                                    console.log(response);
                                                })
                                                .catch(error => {
                                                    console.log(error);
                                                });
                                            ElMessage({
                                                message: '商品修改成功',
                                                type: 'success',
                                            })
                                            return;
                                        }
                                    }
                                } else {
                                    if (checkList.value.length === 0) {
                                        const ediera = {
                                            id: route.query.msg,
                                            name: names.value,
                                            price: prices.value,
                                            systemType:route.query.systemType,
                                            commission: 0,
                                            categoriesId: values.value,
                                            stock: stock.value,
                                            productStatus: state.value,
                                            coverUrl: coverUrl,
                                            coverUrl2: coverUrl1,
                                            coverUrl3: coverUrl2,
                                            coverUrl4: coverUrl3,
                                            coverUrl5: coverUrl4,
                                        };
                                        console.log('shang');
                                        instance.put(`/api/tproduct`, ediera)
                                            .then(response => {
                                                //处理响应数据
                                                console.log(response);
                                            })
                                            .catch(error => {
                                                console.log(error);
                                            });
                                        ElMessage({
                                            message: '商品修改成功',
                                            type: 'success',
                                        })
                                        return;
                                    } else {
                                        if (checkList.value[3] !== undefined) {
                                            const ediera = {
                                                id: route.query.msg,
                                                name: names.value,
                                                price: prices.value,
                                                systemType:route.query.systemType,
                                                categoriesId: values.value,
                                                specName: checkList.value[0],
                                                specPrice: prices.value,
                                                specName1: checkList.value[1],
                                                specPrice1: prices.value,
                                                specName2: checkList.value[2],
                                                specPrice2: prices.value,
                                                specName3: checkList.value[3],
                                                specPrice3: prices.value,
                                                commission: 0,
                                                stock: stock.value,
                                                productStatus: state.value,
                                                coverUrl: coverUrl,
                                                coverUrl2: coverUrl1,
                                                coverUrl3: coverUrl2,
                                                coverUrl4: coverUrl3,
                                                coverUrl5: coverUrl4,
                                            };
                                            console.log('shang');
                                            instance.put(`/api/tproduct`, ediera)
                                                .then(response => {
                                                    //处理响应数据
                                                    console.log(response);
                                                })
                                                .catch(error => {
                                                    console.log(error);
                                                });
                                            ElMessage({
                                                message: '商品修改成功',
                                                type: 'success',
                                            })
                                            return;
                                        }
                                        if (checkList.value[2] !== undefined) {
                                            const ediera = {
                                                id: route.query.msg,
                                                name: names.value,
                                                price: prices.value,
                                                systemType:route.query.systemType,
                                                categoriesId: values.value,
                                                specName: checkList.value[0],
                                                specPrice: prices.value,
                                                specName1: checkList.value[1],
                                                specPrice1: prices.value,
                                                specName2: checkList.value[2],
                                                specPrice2: prices.value,
                                                commission: 0,
                                                stock: stock.value,
                                                productStatus: state.value,
                                                coverUrl: coverUrl,
                                                coverUrl2: coverUrl1,
                                                coverUrl3: coverUrl2,
                                                coverUrl4: coverUrl3,
                                                coverUrl5: coverUrl4,
                                            };
                                            console.log('shang');
                                            instance.put(`/api/tproduct`, ediera)
                                                .then(response => {
                                                    //处理响应数据
                                                    console.log(response);
                                                })
                                                .catch(error => {
                                                    console.log(error);
                                                });
                                            ElMessage({
                                                message: '商品修改成功',
                                                type: 'success',
                                            })
                                            return;
                                        }
                                        if (checkList.value[1] !== undefined) {
                                            const ediera = {
                                                id: route.query.msg,
                                                name: names.value,
                                                price: prices.value,
                                                systemType:route.query.systemType,
                                                categoriesId: values.value,
                                                specName: checkList.value[0],
                                                specPrice: prices.value,
                                                specName1: checkList.value[1],
                                                specPrice1: prices.value,
                                                commission: 0,
                                                stock: stock.value,
                                                productStatus: state.value,
                                                coverUrl: coverUrl,
                                                coverUrl2: coverUrl1,
                                                coverUrl3: coverUrl2,
                                                coverUrl4: coverUrl3,
                                                coverUrl5: coverUrl4,
                                            };
                                            console.log('shang');
                                            instance.put(`/api/tproduct`, ediera)
                                                .then(response => {
                                                    //处理响应数据
                                                    console.log(response);
                                                })
                                                .catch(error => {
                                                    console.log(error);
                                                });
                                            ElMessage({
                                                message: '商品修改成功',
                                                type: 'success',
                                            })
                                            return;
                                        }
                                        if (checkList.value[0] !== undefined) {
                                            const ediera = {
                                                id: route.query.msg,
                                                name: names.value,
                                                price: prices.value,
                                                systemType:route.query.systemType,
                                                categoriesId: values.value,
                                                specName: checkList.value[0],
                                                specPrice: prices.value,
                                                commission: 0,
                                                stock: stock.value,
                                                productStatus: state.value,
                                                coverUrl: coverUrl,
                                                coverUrl2: coverUrl1,
                                                coverUrl3: coverUrl2,
                                                coverUrl4: coverUrl3,
                                                coverUrl5: coverUrl4,
                                            };
                                            console.log('shang');
                                            instance.put(`/api/tproduct`, ediera)
                                                .then(response => {
                                                    //处理响应数据
                                                    console.log(response);
                                                })
                                                .catch(error => {
                                                    console.log(error);
                                                });
                                            ElMessage({
                                                message: '商品修改成功',
                                                type: 'success',
                                            })
                                            return;
                                        }
                                    }
                                }

                                // }
                                // router.push('/commodity');

                            })
                        // 用axios将数据传到后端
                    } else {
                        console.log(2);
                        // if (fileListt.value[0] === undefined) {
                        //     ElMessage({
                        //         message: '商品修改失败，商品详情未添加',
                        //         type: 'error',
                        //     })
                        //     return;
                        // }

                        let img1 = ref('')
                        let img2 = ref('')
                        let img3 = ref('')
                        let img4 = ref('')
                        let img5 = ref('')
                        let img6 = ref('')
                        let img7 = ref('')
                        let img8 = ref('')
                        let img9 = ref('')
                        let img10 = ref('')
                        if (imgurl.value === '' || imgurl.value === undefined) {
                            img1 = ''
                        } else {
                            img1 = imgurl.value
                            console.log(img1);
                        }
                        if (imgurl1.value === '' || imgurl1.value === undefined) {
                            img2 = ''
                            console.log(img2);
                        } else {
                            img2 = imgurl1.value
                        }
                        if (imgurl2.value === '' || imgurl2.value === undefined) {
                            img3 = ''
                        } else {
                            img3 = imgurl2.value
                        }
                        if (imgurl3.value === '' || imgurl3.value === undefined) {
                            img4 = ''
                        } else {
                            img4 = imgurl3.value
                        }
                        if (imgurl4.value === '' || imgurl4.value === undefined) {
                            img5 = ''
                        } else {
                            img5 = imgurl4.value
                        }
                        if (imgurl5.value === '' || imgurl5.value === undefined) {
                            img6 = ''
                        } else {
                            img6 = imgurl5.value
                        }
                        if (imgurl6.value === '' || imgurl6.value === undefined) {
                            img7 = ''
                        } else {
                            img7 = imgurl6.value
                        }
                        if (imgurl7.value === '' || imgurl7.value === undefined) {
                            img8 = ''
                        } else {
                            img8 = imgurl7.value
                        }
                        if (imgurl8.value === '' || imgurl8.value === undefined) {
                            img9 = ''
                        } else {
                            img9 = imgurl8.value
                        }
                        if (imgurl9.value === '' || imgurl9.value === undefined) {
                            img10 = ''
                        } else {
                            img10 = imgurl9.value
                        }
                        if (img1 === undefined) {
                            img1 = ''
                        }
                        if (img2 === undefined) {
                            img2 = ''
                        }
                        if (img3 === undefined) {
                            img3 = ''
                        }
                        if (img4 === undefined) {
                            img4 = ''
                        }
                        if (img5 === undefined) {
                            img5 = ''
                        }
                        if (img6 === undefined) {
                            img6 = ''
                        }
                        if (img7 === undefined) {
                            img7 = ''
                        }
                        if (img8 === undefined) {
                            img8 = ''
                        }
                        if (img9 === undefined) {
                            img9 = ''
                        }
                        if (img10 === undefined) {
                            img10 = ''
                        }
                        // if (img1 === '') {
                        //     ElMessage({
                        //         message: '商品修改失败，商品详情未添加',
                        //         type: 'error',
                        //     })
                        //     return;
                        // }
                        const adds = {
                            "productId": route.query.msg,
                            "img1": img1,
                            "img2": img2,
                            "img3": img3,
                            "img4": img4,
                            "img5": img5,
                            "img6": img6,
                            "img7": img7,
                            "img8": img8,
                            "img9": img9,
                            "img10": img10
                        }
                        console.log(adds);
                        instance.post('/api/details', adds)
                            .then(response => {
                                console.log(response);
                            }).catch(error => {
                                console.log(error);
                            })

                    }
                    instance.get('/api/tproduct/' + route.query.msg)
                        .then(response => {
                            if (response.data.data.coverUrl === undefined) {
                                if (name.value === '') {
                                instance.get('/api/productVideo/' + route.query.msg)
                                    .then(response => {
                                        nama.value = response.data.data;
                                        if (response.data.data !== '' && response.data.data !== null && response.data.data !== undefined) {
                                            console.log(name.value);
                                            const data = {
                                                "productId": route.query.msg,
                                                "videoName1": nama.value,
                                            }
                                            instance.put('/api/productVideo', data)
                                                .then(response => {
                                                    console.log('上传成功2');
                                                    console.log(response.data);
                                                }).catch(response => {
                                                    console.log(response);
                                                    console.log('上传失败')
                                                })
                                        }
                                    }).catch(error => {
                                        console.log(error);
                                        ElMessage({
                                            showClose: true,
                                            message: '请登录',
                                            type: 'error',
                                        })
                                        router.push('/')
                                    })
                            } else {
                                console.log(14);
                                instance.get('/api/productVideo/' + route.query.msg)
                                    .then(response => {
                                        nama.value = response.data.data;
                                        console.log(nama.value);
                                        console.log(response.data.data);
                                        if (nama.value === '' || nama.value === null || nama.value === undefined) {
                                            console.log(14);
                                            if (name.value === undefined) {
                                                name.value = ''
                                                console.log(14);
                                            }
                                            const data = {
                                                "productId": route.query.msg,
                                                "videoName1": name.value
                                            }
                                            instance.post('/api/productVideo', data)
                                                .then(response => {
                                                    console.log('修改成功3');
                                                    console.log(response.data);
                                                    // router.push('/commodity');
                                                    // }
                                                    // router.push('/commodity');
                                                }).catch(response => {
                                                    console.log(response);
                                                    console.log('上传失败')
                                                })
                                        } else {
                                            console.log(122);
                                            if (name.value === undefined) {
                                                name.value = ''
                                                console.log(122);
                                                console.log(name.value);
                                            }
                                            const data = {
                                                "productId": route.query.msg,
                                                "videoName1": name.value
                                            }
                                            instance.put('/api/productVideo', data)
                                                .then(response => {
                                                    console.log(name.value);
                                                    console.log('修改成功4');
                                                    console.log(response);
                                                    // router.push('/commodity');
                                                }).catch(error => {
                                                    console.log(error);
                                                    console.log('修改失败')
                                                })
                                        }
                                    }).catch(error => {
                                        console.log(error);
                                        ElMessage({
                                            showClose: true,
                                            message: '请登录',
                                            type: 'error',
                                        })
                                        router.push('/')
                                    })
                            }
                                if (fileList.value[0] === undefined) {
                                if (response.data.data.coverUrl === null) {
                                    coverUrl = ""
                                } else {
                                    if (fileList.value[0] === undefined) {
                                        coverUrl = ""
                                    } else {
                                        coverUrl = response.data.data.coverUrl
                                    }
                                }

                            } else {
                                let matches = fileList.value[0].url.match(regex);
                                console.log(matches);
                                if (matches === null) {
                                    coverUrl = fileList.value[0].response.data
                                } else {
                                    if (matches[2] === undefined) {
                                        coverUrl = matches[4]
                                    } else {
                                        coverUrl = matches[2]
                                    }
                                }
                            }
                            if (fileList.value[1] === undefined) {
                                if (response.data.data.coverUrl1 === null) {
                                    coverUrl1 = ""
                                } else {
                                    if (fileList.value[1] === undefined) {
                                        coverUrl1 = ""
                                    } else {
                                        coverUrl1 = response.data.data.coverUrl1
                                    }
                                }

                            } else {
                                let matches = fileList.value[1].url.match(regex);
                                console.log(matches);
                                if (matches === null) {
                                    coverUrl1 = fileList.value[1].response.data
                                } else {
                                    if (matches[2] === undefined) {
                                        coverUrl1 = matches[4]
                                    } else {
                                        coverUrl1 = matches[2]
                                    }
                                }
                            }
                            if (fileList.value[2] === undefined) {
                                if (response.data.data.coverUrl2 === null) {
                                    coverUrl2 = ""
                                } else {
                                    if (fileList.value[2] === undefined) {
                                        coverUrl2 = ""
                                    } else {
                                        coverUrl2 = response.data.data.coverUrl2
                                    }
                                }

                            } else {
                                let matches = fileList.value[2].url.match(regex);
                                console.log(matches);
                                if (matches === null) {
                                    coverUrl2 = fileList.value[2].response.data
                                } else {
                                    if (matches[2] === undefined) {
                                        coverUrl2 = matches[4]
                                    } else {
                                        coverUrl2 = matches[2]
                                    }
                                }
                            }
                            if (fileList.value[3] === undefined) {
                                if (response.data.data.coverUrl3 === null) {
                                    coverUrl3 = ""
                                } else {
                                    if (fileList.value[3] === undefined) {
                                        coverUrl3 = ""
                                    } else {
                                        coverUrl3 = response.data.data.coverUrl3
                                    }
                                }

                            } else {
                                let matches = fileList.value[3].url.match(regex);
                                console.log(matches);
                                if (matches === null) {
                                    coverUrl3 = fileList.value[3].response.data
                                } else {
                                    if (matches[2] === undefined) {
                                        coverUrl3 = matches[4]
                                    } else {
                                        coverUrl3 = matches[2]
                                    }
                                }
                            }
                            if (fileList.value[4] === undefined) {
                                if (response.data.data.coverUrl4 === null) {
                                    coverUrl4 = ""
                                } else {
                                    if (fileList.value[4] === undefined) {
                                        coverUrl4 = ""
                                    } else {
                                        coverUrl4 = response.data.data.coverUrl4
                                    }
                                }

                            } else {
                                let matches = fileList.value[4].url.match(regex);
                                console.log(matches);
                                if (matches === null) {
                                    coverUrl4 = fileList.value[4].response.data
                                } else {
                                    if (matches[2] === undefined) {
                                        coverUrl4 = matches[4]
                                    } else {
                                        coverUrl4 = matches[2]
                                    }
                                }
                            }
                            if (values.value === route.query.categoriesName) {
                                if (checkList.value.length === 0) {
                                    const ediera = {
                                        id: route.query.msg,
                                        name: names.value,
                                        systemType:route.query.systemType,
                                        price: prices.value,
                                        commission: 0,
                                        stock: stock.value,
                                        productStatus: state.value,
                                        coverUrl: coverUrl,
                                        coverUrl2: coverUrl1,
                                        coverUrl3: coverUrl2,
                                        coverUrl4: coverUrl3,
                                        coverUrl5: coverUrl4,
                                    };
                                    console.log('shang');
                                    instance.put(`/api/tproduct`, ediera)
                                        .then(response => {
                                            //处理响应数据
                                            console.log(response);
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        });
                                    ElMessage({
                                        message: '商品修改成功',
                                        type: 'success',
                                    })
                                    return;
                                } else {
                                    if (checkList.value[3] !== undefined) {
                                        const ediera = {
                                            id: route.query.msg,
                                            name: names.value,
                                            price: prices.value,
                                            systemType:route.query.systemType,
                                            specName: checkList.value[0],
                                            specPrice: prices.value,
                                            specName1: checkList.value[1],
                                            specPrice1: prices.value,
                                            specName2: checkList.value[2],
                                            specPrice2: prices.value,
                                            specName3: checkList.value[3],
                                            specPrice3: prices.value,
                                            commission: 0,
                                            stock: stock.value,
                                            productStatus: state.value,
                                            coverUrl: coverUrl,
                                            coverUrl2: coverUrl1,
                                            coverUrl3: coverUrl2,
                                            coverUrl4: coverUrl3,
                                            coverUrl5: coverUrl4,
                                        };
                                        console.log('shang');
                                        instance.put(`/api/tproduct`, ediera)
                                            .then(response => {
                                                //处理响应数据
                                                console.log(response);
                                            })
                                            .catch(error => {
                                                console.log(error);
                                            });
                                        ElMessage({
                                            message: '商品修改成功',
                                            type: 'success',
                                        })
                                        return;
                                    }
                                    if (checkList.value[2] !== undefined) {
                                        const ediera = {
                                            id: route.query.msg,
                                            name: names.value,
                                            price: prices.value,
                                            systemType:route.query.systemType,
                                            specName: checkList.value[0],
                                            specPrice: prices.value,
                                            specName1: checkList.value[1],
                                            specPrice1: prices.value,
                                            specName2: checkList.value[2],
                                            specPrice2: prices.value,
                                            commission: 0,
                                            stock: stock.value,
                                            productStatus: state.value,
                                            coverUrl: coverUrl,
                                            coverUrl2: coverUrl1,
                                            coverUrl3: coverUrl2,
                                            coverUrl4: coverUrl3,
                                            coverUrl5: coverUrl4,
                                        };
                                        console.log('shang');
                                        instance.put(`/api/tproduct`, ediera)
                                            .then(response => {
                                                //处理响应数据
                                                console.log(response);
                                            })
                                            .catch(error => {
                                                console.log(error);
                                            });
                                        ElMessage({
                                            message: '商品修改成功',
                                            type: 'success',
                                        })
                                        return;
                                    }
                                    if (checkList.value[1] !== undefined) {
                                        const ediera = {
                                            id: route.query.msg,
                                            name: names.value,
                                            price: prices.value,
                                            specName: checkList.value[0],
                                            systemType:route.query.systemType,
                                            specPrice: prices.value,
                                            specName1: checkList.value[1],
                                            specPrice1: prices.value,
                                            commission: 0,
                                            stock: stock.value,
                                            productStatus: state.value,
                                            coverUrl: coverUrl,
                                            coverUrl2: coverUrl1,
                                            coverUrl3: coverUrl2,
                                            coverUrl4: coverUrl3,
                                            coverUrl5: coverUrl4,
                                        };
                                        console.log('shang');
                                        instance.put(`/api/tproduct`, ediera)
                                            .then(response => {
                                                //处理响应数据
                                                console.log(response);
                                            })
                                            .catch(error => {
                                                console.log(error);
                                            });
                                        ElMessage({
                                            message: '商品修改成功',
                                            type: 'success',
                                        })
                                        return;
                                    }
                                    if (checkList.value[0] !== undefined) {
                                        const ediera = {
                                            id: route.query.msg,
                                            name: names.value,
                                            price: prices.value,
                                            systemType:route.query.systemType,
                                            specName: checkList.value[0],
                                            specPrice: prices.value,
                                            commission: 0,
                                            stock: stock.value,
                                            productStatus: state.value,
                                            coverUrl: coverUrl,
                                            coverUrl2: coverUrl1,
                                            coverUrl3: coverUrl2,
                                            coverUrl4: coverUrl3,
                                            coverUrl5: coverUrl4,
                                        };
                                        console.log('shang');
                                        instance.put(`/api/tproduct`, ediera)
                                            .then(response => {
                                                //处理响应数据
                                                console.log(response);
                                            })
                                            .catch(error => {
                                                console.log(error);
                                            });
                                        ElMessage({
                                            message: '商品修改成功',
                                            type: 'success',
                                        })
                                        return;
                                    }
                                }
                            } else {
                                if (checkList.value.length === 0) {
                                    const ediera = {
                                        id: route.query.msg,
                                        name: names.value,
                                        price: prices.value,
                                        systemType:route.query.systemType,
                                        commission: 0,
                                        categoriesId: values.value,
                                        stock: stock.value,
                                        productStatus: state.value,
                                        coverUrl: coverUrl,
                                        coverUrl2: coverUrl1,
                                        coverUrl3: coverUrl2,
                                        coverUrl4: coverUrl3,
                                        coverUrl5: coverUrl4,
                                    };
                                    console.log('shang');
                                    instance.put(`/api/tproduct`, ediera)
                                        .then(response => {
                                            //处理响应数据
                                            console.log(response);
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        });
                                    ElMessage({
                                        message: '商品修改成功',
                                        type: 'success',
                                    })
                                    return;
                                } else {
                                    if (checkList.value[3] !== undefined) {
                                        const ediera = {
                                            id: route.query.msg,
                                            name: names.value,
                                            price: prices.value,
                                            categoriesId: values.value,
                                            systemType:route.query.systemType,
                                            specName: checkList.value[0],
                                            specPrice: prices.value,
                                            specName1: checkList.value[1],
                                            specPrice1: prices.value,
                                            specName2: checkList.value[2],
                                            specPrice2: prices.value,
                                            specName3: checkList.value[3],
                                            specPrice3: prices.value,
                                            commission: 0,
                                            stock: stock.value,
                                            productStatus: state.value,
                                            coverUrl: coverUrl,
                                            coverUrl2: coverUrl1,
                                            coverUrl3: coverUrl2,
                                            coverUrl4: coverUrl3,
                                            coverUrl5: coverUrl4,
                                        };
                                        console.log('shang');
                                        instance.put(`/api/tproduct`, ediera)
                                            .then(response => {
                                                //处理响应数据
                                                console.log(response);
                                            })
                                            .catch(error => {
                                                console.log(error);
                                            });
                                        ElMessage({
                                            message: '商品修改成功',
                                            type: 'success',
                                        })
                                        return;
                                    }
                                    if (checkList.value[2] !== undefined) {
                                        const ediera = {
                                            id: route.query.msg,
                                            name: names.value,
                                            price: prices.value,
                                            categoriesId: values.value,
                                            systemType:route.query.systemType,
                                            specName: checkList.value[0],
                                            specPrice: prices.value,
                                            specName1: checkList.value[1],
                                            specPrice1: prices.value,
                                            specName2: checkList.value[2],
                                            specPrice2: prices.value,
                                            commission: 0,
                                            stock: stock.value,
                                            productStatus: state.value,
                                            coverUrl: coverUrl,
                                            coverUrl2: coverUrl1,
                                            coverUrl3: coverUrl2,
                                            coverUrl4: coverUrl3,
                                            coverUrl5: coverUrl4,
                                        };
                                        console.log('shang');
                                        instance.put(`/api/tproduct`, ediera)
                                            .then(response => {
                                                //处理响应数据
                                                console.log(response);
                                            })
                                            .catch(error => {
                                                console.log(error);
                                            });
                                        ElMessage({
                                            message: '商品修改成功',
                                            type: 'success',
                                        })
                                        return;
                                    }
                                    if (checkList.value[1] !== undefined) {
                                        const ediera = {
                                            id: route.query.msg,
                                            name: names.value,
                                            price: prices.value,
                                            categoriesId: values.value,
                                            systemType:route.query.systemType,
                                            specName: checkList.value[0],
                                            specPrice: prices.value,
                                            specName1: checkList.value[1],
                                            specPrice1: prices.value,
                                            commission: 0,
                                            stock: stock.value,
                                            productStatus: state.value,
                                            coverUrl: coverUrl,
                                            coverUrl2: coverUrl1,
                                            coverUrl3: coverUrl2,
                                            coverUrl4: coverUrl3,
                                            coverUrl5: coverUrl4,
                                        };
                                        console.log('shang');
                                        instance.put(`/api/tproduct`, ediera)
                                            .then(response => {
                                                //处理响应数据
                                                console.log(response);
                                            })
                                            .catch(error => {
                                                console.log(error);
                                            });
                                        ElMessage({
                                            message: '商品修改成功',
                                            type: 'success',
                                        })
                                        return;
                                    }
                                    if (checkList.value[0] !== undefined) {
                                        const ediera = {
                                            id: route.query.msg,
                                            name: names.value,
                                            price: prices.value,
                                            categoriesId: values.value,
                                            systemType:route.query.systemType,
                                            specName: checkList.value[0],
                                            specPrice: prices.value,
                                            commission: 0,
                                            stock: stock.value,
                                            productStatus: state.value,
                                            coverUrl: coverUrl,
                                            coverUrl2: coverUrl1,
                                            coverUrl3: coverUrl2,
                                            coverUrl4: coverUrl3,
                                            coverUrl5: coverUrl4,
                                        };
                                        console.log('shang');
                                        instance.put(`/api/tproduct`, ediera)
                                            .then(response => {
                                                //处理响应数据
                                                console.log(response);
                                            })
                                            .catch(error => {
                                                console.log(error);
                                            });
                                        ElMessage({
                                            message: '商品修改成功',
                                            type: 'success',
                                        })
                                        return;
                            }}
                            console.log(response.data.data.coverUrl);
                            console.log(response.data.data.coverUrl2);
                            console.log(response.data.data.coverUrl3);
                            console.log(response.data.data.coverUrl4);
                            console.log(response.data.data.coverUrl5);
                            let coverUrl = response.data.data.coverUrl
                            let coverUrl1 = response.data.data.coverUrl2
                            let coverUrl2 = response.data.data.coverUrl3
                            let coverUrl3 = response.data.data.coverUrl4
                            let coverUrl4 = response.data.data.coverUrl5
                            console.log(prices);
                            console.log(names);
                            // console.log(commission);
                            console.log(stock);
                            // if (commission === '') {
                            //     ElMessage({
                            //         message: '商品修改失败，商品佣金未输入',
                            //         type: 'error',
                            //     })
                            //     return;
                            // }
                            if (prices === '') {
                                ElMessage({
                                    message: '商品修改失败，商品价格未输入',
                                    type: 'error',
                                })
                                return;
                            }
                            if (stock === '') {
                                ElMessage({
                                    message: '商品修改失败，商品库存未输入',
                                    type: 'error',
                                })
                                return;
                            }
                            // if (commission / 10 <= prices) {
                            //     ElMessage({
                            //         type: 'error',
                            //         message: '添加失败，商品佣金大于价格10%',
                            //     })
                            //     return;
                            // }
                            if (isNus.value === false) {
                                ElMessage({
                                    type: 'error',
                                    message: '添加失败，商品价格只能填写数字',
                                })
                                return;
                            }
                            if (isNue.value === false) {
                                ElMessage({
                                    type: 'error',
                                    message: '添加失败，商品佣金填写有除数字以外',
                                })
                                return;
                            }
                            if (isNun.value === false) {
                                ElMessage({
                                    type: 'error',
                                    message: '添加失败，商品库存填写有除数字以外',
                                })
                                return;
                            }
                            if (names === '') {
                                ElMessage({
                                    message: '商品修改失败，商品名称未输入',
                                    type: 'error',
                                })
                                return;
                            }
                            // if (imageUrl11.value === '') {
                            //     ElMessage({
                            //         message: '商品修改失败，商品展示图未输入',
                            //         type: 'error',
                            //     })
                            //     return;
                            // }

                            if (coverUrl === undefined) {
                                ElMessage({
                                    message: '商品修改失败，商品展示图未输入',
                                    type: 'error',
                                })
                                return;
                            }
                            if (name.value === '') {
                                instance.get('/api/productVideo/' + route.query.msg)
                                    .then(response => {
                                        nama.value = response.data.data;
                                        if (response.data.data !== '' && response.data.data !== null && response.data.data !== undefined) {
                                            console.log(name.value);
                                            const data = {
                                                "productId": route.query.msg,
                                                "videoName1": nama.value,
                                            }
                                            instance.put('/api/productVideo', data)
                                                .then(response => {
                                                    console.log('上传成功2');
                                                    console.log(response.data);
                                                }).catch(response => {
                                                    console.log(response);
                                                    console.log('上传失败')
                                                })
                                        }
                                    }).catch(error => {
                                        console.log(error);
                                        ElMessage({
                                            showClose: true,
                                            message: '请登录',
                                            type: 'error',
                                        })
                                        router.push('/')
                                    })
                            } else {
                                console.log(14);
                                instance.get('/api/productVideo/' + route.query.msg)
                                    .then(response => {
                                        nama.value = response.data.data;
                                        console.log(nama.value);
                                        console.log(response.data.data);
                                        if (nama.value === '' || nama.value === null || nama.value === undefined) {
                                            console.log(14);
                                            if (name.value === undefined) {
                                                name.value = ''
                                                console.log(14);
                                            }
                                            const data = {
                                                "productId": route.query.msg,
                                                "videoName1": name.value
                                            }
                                            instance.post('/api/productVideo', data)
                                                .then(response => {
                                                    console.log('修改成功3');
                                                    console.log(response.data);
                                                    // router.push('/commodity');
                                                    // }
                                                    // router.push('/commodity');
                                                }).catch(response => {
                                                    console.log(response);
                                                    console.log('上传失败')
                                                })
                                        } else {
                                            console.log(122);
                                            if (name.value === undefined) {
                                                name.value = ''
                                                console.log(122);
                                                console.log(name.value);
                                            }
                                            const data = {
                                                "productId": route.query.msg,
                                                "videoName1": name.value
                                            }
                                            instance.put('/api/productVideo', data)
                                                .then(response => {
                                                    console.log(name.value);
                                                    console.log('修改成功4');
                                                    console.log(response);
                                                    // router.push('/commodity');
                                                }).catch(error => {
                                                    console.log(error);
                                                    console.log('修改失败')
                                                })
                                        }
                                    }).catch(error => {
                                        console.log(error);
                                        ElMessage({
                                            showClose: true,
                                            message: '请登录',
                                            type: 'error',
                                        })
                                        router.push('/')
                                    })
                            }
                            if (fileList.value[0] === undefined) {
                                if (response.data.data.coverUrl === null) {
                                    coverUrl = ""
                                } else {
                                    if (fileList.value[0] === undefined) {
                                        coverUrl = ""
                                    } else {
                                        coverUrl = response.data.data.coverUrl
                                    }
                                }

                            } else {
                                let matches = fileList.value[0].url.match(regex);
                                console.log(matches);
                                if (matches === null) {
                                    coverUrl = fileList.value[0].response.data
                                } else {
                                    if (matches[2] === undefined) {
                                        coverUrl = matches[4]
                                    } else {
                                        coverUrl = matches[2]
                                    }
                                }
                            }
                            if (fileList.value[1] === undefined) {
                                if (response.data.data.coverUrl1 === null) {
                                    coverUrl1 = ""
                                } else {
                                    if (fileList.value[1] === undefined) {
                                        coverUrl1 = ""
                                    } else {
                                        coverUrl1 = response.data.data.coverUrl1
                                    }
                                }

                            } else {
                                let matches = fileList.value[1].url.match(regex);
                                console.log(matches);
                                if (matches === null) {
                                    coverUrl1 = fileList.value[1].response.data
                                } else {
                                    if (matches[2] === undefined) {
                                        coverUrl1 = matches[4]
                                    } else {
                                        coverUrl1 = matches[2]
                                    }
                                }
                            }
                            if (fileList.value[2] === undefined) {
                                if (response.data.data.coverUrl2 === null) {
                                    coverUrl2 = ""
                                } else {
                                    if (fileList.value[2] === undefined) {
                                        coverUrl2 = ""
                                    } else {
                                        coverUrl2 = response.data.data.coverUrl2
                                    }
                                }

                            } else {
                                let matches = fileList.value[2].url.match(regex);
                                console.log(matches);
                                if (matches === null) {
                                    coverUrl2 = fileList.value[2].response.data
                                } else {
                                    if (matches[2] === undefined) {
                                        coverUrl2 = matches[4]
                                    } else {
                                        coverUrl2 = matches[2]
                                    }
                                }
                            }
                            if (fileList.value[3] === undefined) {
                                if (response.data.data.coverUrl3 === null) {
                                    coverUrl3 = ""
                                } else {
                                    if (fileList.value[3] === undefined) {
                                        coverUrl3 = ""
                                    } else {
                                        coverUrl3 = response.data.data.coverUrl3
                                    }
                                }

                            } else {
                                let matches = fileList.value[3].url.match(regex);
                                console.log(matches);
                                if (matches === null) {
                                    coverUrl3 = fileList.value[3].response.data
                                } else {
                                    if (matches[2] === undefined) {
                                        coverUrl3 = matches[4]
                                    } else {
                                        coverUrl3 = matches[2]
                                    }
                                }
                            }
                            if (fileList.value[4] === undefined) {
                                if (response.data.data.coverUrl4 === null) {
                                    coverUrl4 = ""
                                } else {
                                    if (fileList.value[4] === undefined) {
                                        coverUrl4 = ""
                                    } else {
                                        coverUrl4 = response.data.data.coverUrl4
                                    }
                                }

                            } else {
                                let matches = fileList.value[4].url.match(regex);
                                console.log(matches);
                                if (matches === null) {
                                    coverUrl4 = fileList.value[4].response.data
                                } else {
                                    if (matches[2] === undefined) {
                                        coverUrl4 = matches[4]
                                    } else {
                                        coverUrl4 = matches[2]
                                    }
                                }
                            }
                            if (values.value === route.query.categoriesName) {
                                if (checkList.value.length === 0) {
                                    const ediera = {
                                        id: route.query.msg,
                                        name: names.value,
                                        systemType:route.query.systemType,
                                        price: prices.value,
                                        commission: 0,
                                        stock: stock.value,
                                        productStatus: state.value,
                                        coverUrl: coverUrl,
                                        coverUrl2: coverUrl1,
                                        coverUrl3: coverUrl2,
                                        coverUrl4: coverUrl3,
                                        coverUrl5: coverUrl4,
                                    };
                                    console.log('shang');
                                    instance.put(`/api/tproduct`, ediera)
                                        .then(response => {
                                            //处理响应数据
                                            console.log(response);
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        });
                                    ElMessage({
                                        message: '商品修改成功',
                                        type: 'success',
                                    })
                                    return;
                                } else {
                                    if (checkList.value[3] !== undefined) {
                                        const ediera = {
                                            id: route.query.msg,
                                            name: names.value,
                                            price: prices.value,
                                            systemType:route.query.systemType,
                                            specName: checkList.value[0],
                                            specPrice: prices.value,
                                            specName1: checkList.value[1],
                                            specPrice1: prices.value,
                                            specName2: checkList.value[2],
                                            specPrice2: prices.value,
                                            specName3: checkList.value[3],
                                            specPrice3: prices.value,
                                            commission: 0,
                                            stock: stock.value,
                                            productStatus: state.value,
                                            coverUrl: coverUrl,
                                            coverUrl2: coverUrl1,
                                            coverUrl3: coverUrl2,
                                            coverUrl4: coverUrl3,
                                            coverUrl5: coverUrl4,
                                        };
                                        console.log('shang');
                                        instance.put(`/api/tproduct`, ediera)
                                            .then(response => {
                                                //处理响应数据
                                                console.log(response);
                                            })
                                            .catch(error => {
                                                console.log(error);
                                            });
                                        ElMessage({
                                            message: '商品修改成功',
                                            type: 'success',
                                        })
                                        return;
                                    }
                                    if (checkList.value[2] !== undefined) {
                                        const ediera = {
                                            id: route.query.msg,
                                            name: names.value,
                                            price: prices.value,
                                            systemType:route.query.systemType,
                                            specName: checkList.value[0],
                                            specPrice: prices.value,
                                            specName1: checkList.value[1],
                                            specPrice1: prices.value,
                                            specName2: checkList.value[2],
                                            specPrice2: prices.value,
                                            commission: 0,
                                            stock: stock.value,
                                            productStatus: state.value,
                                            coverUrl: coverUrl,
                                            coverUrl2: coverUrl1,
                                            coverUrl3: coverUrl2,
                                            coverUrl4: coverUrl3,
                                            coverUrl5: coverUrl4,
                                        };
                                        console.log('shang');
                                        instance.put(`/api/tproduct`, ediera)
                                            .then(response => {
                                                //处理响应数据
                                                console.log(response);
                                            })
                                            .catch(error => {
                                                console.log(error);
                                            });
                                        ElMessage({
                                            message: '商品修改成功',
                                            type: 'success',
                                        })
                                        return;
                                    }
                                    if (checkList.value[1] !== undefined) {
                                        const ediera = {
                                            id: route.query.msg,
                                            name: names.value,
                                            price: prices.value,
                                            specName: checkList.value[0],
                                            systemType:route.query.systemType,
                                            specPrice: prices.value,
                                            specName1: checkList.value[1],
                                            specPrice1: prices.value,
                                            commission: 0,
                                            stock: stock.value,
                                            productStatus: state.value,
                                            coverUrl: coverUrl,
                                            coverUrl2: coverUrl1,
                                            coverUrl3: coverUrl2,
                                            coverUrl4: coverUrl3,
                                            coverUrl5: coverUrl4,
                                        };
                                        console.log('shang');
                                        instance.put(`/api/tproduct`, ediera)
                                            .then(response => {
                                                //处理响应数据
                                                console.log(response);
                                            })
                                            .catch(error => {
                                                console.log(error);
                                            });
                                        ElMessage({
                                            message: '商品修改成功',
                                            type: 'success',
                                        })
                                        return;
                                    }
                                    if (checkList.value[0] !== undefined) {
                                        const ediera = {
                                            id: route.query.msg,
                                            name: names.value,
                                            price: prices.value,
                                            systemType:route.query.systemType,
                                            specName: checkList.value[0],
                                            specPrice: prices.value,
                                            commission: 0,
                                            stock: stock.value,
                                            productStatus: state.value,
                                            coverUrl: coverUrl,
                                            coverUrl2: coverUrl1,
                                            coverUrl3: coverUrl2,
                                            coverUrl4: coverUrl3,
                                            coverUrl5: coverUrl4,
                                        };
                                        console.log('shang');
                                        instance.put(`/api/tproduct`, ediera)
                                            .then(response => {
                                                //处理响应数据
                                                console.log(response);
                                            })
                                            .catch(error => {
                                                console.log(error);
                                            });
                                        ElMessage({
                                            message: '商品修改成功',
                                            type: 'success',
                                        })
                                        return;
                                    }
                                }
                            } else {
                                if (checkList.value.length === 0) {
                                    const ediera = {
                                        id: route.query.msg,
                                        name: names.value,
                                        price: prices.value,
                                        systemType:route.query.systemType,
                                        commission: 0,
                                        categoriesId: values.value,
                                        stock: stock.value,
                                        productStatus: state.value,
                                        coverUrl: coverUrl,
                                        coverUrl2: coverUrl1,
                                        coverUrl3: coverUrl2,
                                        coverUrl4: coverUrl3,
                                        coverUrl5: coverUrl4,
                                    };
                                    console.log('shang');
                                    instance.put(`/api/tproduct`, ediera)
                                        .then(response => {
                                            //处理响应数据
                                            console.log(response);
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        });
                                    ElMessage({
                                        message: '商品修改成功',
                                        type: 'success',
                                    })
                                    return;
                                } else {
                                    if (checkList.value[3] !== undefined) {
                                        const ediera = {
                                            id: route.query.msg,
                                            name: names.value,
                                            price: prices.value,
                                            categoriesId: values.value,
                                            systemType:route.query.systemType,
                                            specName: checkList.value[0],
                                            specPrice: prices.value,
                                            specName1: checkList.value[1],
                                            specPrice1: prices.value,
                                            specName2: checkList.value[2],
                                            specPrice2: prices.value,
                                            specName3: checkList.value[3],
                                            specPrice3: prices.value,
                                            commission: 0,
                                            stock: stock.value,
                                            productStatus: state.value,
                                            coverUrl: coverUrl,
                                            coverUrl2: coverUrl1,
                                            coverUrl3: coverUrl2,
                                            coverUrl4: coverUrl3,
                                            coverUrl5: coverUrl4,
                                        };
                                        console.log('shang');
                                        instance.put(`/api/tproduct`, ediera)
                                            .then(response => {
                                                //处理响应数据
                                                console.log(response);
                                            })
                                            .catch(error => {
                                                console.log(error);
                                            });
                                        ElMessage({
                                            message: '商品修改成功',
                                            type: 'success',
                                        })
                                        return;
                                    }
                                    if (checkList.value[2] !== undefined) {
                                        const ediera = {
                                            id: route.query.msg,
                                            name: names.value,
                                            price: prices.value,
                                            categoriesId: values.value,
                                            systemType:route.query.systemType,
                                            specName: checkList.value[0],
                                            specPrice: prices.value,
                                            specName1: checkList.value[1],
                                            specPrice1: prices.value,
                                            specName2: checkList.value[2],
                                            specPrice2: prices.value,
                                            commission: 0,
                                            stock: stock.value,
                                            productStatus: state.value,
                                            coverUrl: coverUrl,
                                            coverUrl2: coverUrl1,
                                            coverUrl3: coverUrl2,
                                            coverUrl4: coverUrl3,
                                            coverUrl5: coverUrl4,
                                        };
                                        console.log('shang');
                                        instance.put(`/api/tproduct`, ediera)
                                            .then(response => {
                                                //处理响应数据
                                                console.log(response);
                                            })
                                            .catch(error => {
                                                console.log(error);
                                            });
                                        ElMessage({
                                            message: '商品修改成功',
                                            type: 'success',
                                        })
                                        return;
                                    }
                                    if (checkList.value[1] !== undefined) {
                                        const ediera = {
                                            id: route.query.msg,
                                            name: names.value,
                                            price: prices.value,
                                            categoriesId: values.value,
                                            systemType:route.query.systemType,
                                            specName: checkList.value[0],
                                            specPrice: prices.value,
                                            specName1: checkList.value[1],
                                            specPrice1: prices.value,
                                            commission: 0,
                                            stock: stock.value,
                                            productStatus: state.value,
                                            coverUrl: coverUrl,
                                            coverUrl2: coverUrl1,
                                            coverUrl3: coverUrl2,
                                            coverUrl4: coverUrl3,
                                            coverUrl5: coverUrl4,
                                        };
                                        console.log('shang');
                                        instance.put(`/api/tproduct`, ediera)
                                            .then(response => {
                                                //处理响应数据
                                                console.log(response);
                                            })
                                            .catch(error => {
                                                console.log(error);
                                            });
                                        ElMessage({
                                            message: '商品修改成功',
                                            type: 'success',
                                        })
                                        return;
                                    }
                                    if (checkList.value[0] !== undefined) {
                                        const ediera = {
                                            id: route.query.msg,
                                            name: names.value,
                                            price: prices.value,
                                            categoriesId: values.value,
                                            systemType:route.query.systemType,
                                            specName: checkList.value[0],
                                            specPrice: prices.value,
                                            commission: 0,
                                            stock: stock.value,
                                            productStatus: state.value,
                                            coverUrl: coverUrl,
                                            coverUrl2: coverUrl1,
                                            coverUrl3: coverUrl2,
                                            coverUrl4: coverUrl3,
                                            coverUrl5: coverUrl4,
                                        };
                                        console.log('shang');
                                        instance.put(`/api/tproduct`, ediera)
                                            .then(response => {
                                                //处理响应数据
                                                console.log(response);
                                            })
                                            .catch(error => {
                                                console.log(error);
                                            });
                                        ElMessage({
                                            message: '商品修改成功',
                                            type: 'success',
                                        })
                                        return;
                                    }
                                }
                            }

                            // }
                            // router.push('/commodity');

                }}})
                })
        }).catch(() => {
            ElMessage({
                type: 'info',
                message: '已取消添加',
            })
        })
}
</script>

<style>


.containerse {
    position: fixed;
    top: 10vh;
    left: 15vw;
}



.addition {
    width: 100vw;
    height: 80vh;
}

.addition .el-input {
    width: 25vw;
}

.addition p {
    padding-top: 5vh;
    display: flex;
}

.addition .el-textarea {
    width: 25vw;
}

.name {
    width: 9vw;
}

.addition .input {
    display: flex;
}

.addition .button {
    display: flex;
    margin-left: 12vw;
}

.xiang {
    width: 50vw;
}

.xiang .avatar-uploader {
    display: inline-block;
}

.avatar1 {
    position: relative;
}

.delete {
    position: absolute;
    top: -1vh;
    right: 0;
    width: 1vw;
    height: 2.5vh;
    cursor: pointer;
}
</style>