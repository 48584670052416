<!-- 会员用户 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <el-main class="main">
                <div class="main_item">
                    <div class="feedbackessw">
                        <div class="feedbackesswhead">
                            <el-input v-model="feedinputs" placeholder="请输入用户ID" @keyup.enter="sift"
                                class="feedbackesswSearch"></el-input>
                            <el-button type="primary" @click="sift" class="feedbackesswSearchBtn">查询</el-button>
                            <el-select class="" v-model="region" placeholder="请选择状态" @change="sift">
                                <el-option label="会员" value="会员" />
                                <el-option label="审核" value="审核" />
                                <el-option label="临时会员" value="临时会员" />
                            </el-select>
                        </div>
                        <div class="feedbackesswbody">
                            <el-table :data="member" v-loading="loading" element-loading-text="加载中，请稍后..."
                                style="width: 100vw" height="80vh" border ref="table">
                                <el-table-column prop="wxUserId" label="用户id" width="50" />
                                <el-table-column prop="nickName" label="用户名" width="50" />
                                <el-table-column prop="consecutiveDaysSignedIn" label="期限" width="50"
                                    v-if="region === '临时会员'" />
                                <el-table-column prop="membershipStartDate" label="会员开始日期" width="50"
                                    v-if="region === '临时会员'" />
                                <el-table-column prop="membershipEndDate" label="会员结束日期" width="50"
                                    v-if="region === '临时会员'" />
                                <el-table-column prop="memberPrice" label="折扣" width="50"
                                    v-if="region === '会员' || region === '临时会员'">
                                    <template #default="scope">
                                        <span>{{ scope.row.memberPrice * 10 }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="" label="头像" width="50">
                                    <template #default="scope">
                                        <el-avatar :src="appendHttpsIfNeeded(scope.row.avatarUrl)"
                                            :size="50"></el-avatar>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="gender" label="性别" width="100">
                                    <template #default="scope">
                                        <span v-if="scope.row.gender == 0 || scope.row.gender == null">未知</span>
                                        <span v-else-if="scope.row.gender == 1">男</span>
                                        <span v-else-if="scope.row.gender == 2">女</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="" label="操作" width="100">
                                    <template #default="scope">
                                        <span v-if="scope.row.member === 1"><el-button type="danger" size="small"
                                                @click="Delete(scope.row.wxUserId)">删除</el-button></span>
                                        <span v-else-if="scope.row.member === 2"><el-button type="primary" size="small"
                                                @click="Pass(scope.row.wxUserId)">通过</el-button></span>
                                        <span v-if="scope.row.member === 2"><el-button type="danger" size="small"
                                                @click="refuse(scope.row.wxUserId)">拒绝</el-button></span>
                                                <span v-if="scope.row.member === 1"><el-button
                                                type="primary" size="small" plain
                                                @click="membership(scope.row.wxUserId)">修改会员价</el-button></span>
                                        <span v-if="scope.row.member !== 2 && scope.row.member !== 1"><el-button
                                                type="primary" size="small" plain
                                                @click="revise(scope.row.wxUserId, scope.row.memberId)">修改</el-button></span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div class="feedbackesswfoot">
                            <div class="demo-pagination-blocks">
                                <el-pagination v-model:current-page="currentPage4" v-model:page-size="pageSize4"
                                    @size-change="handleSizeChanges" @current-change="handleCurrentChanges"
                                    :current-page="currentPage4" :page-sizes="[10, 20, 30, 40]" :page-size="10"
                                    layout="total, sizes, prev, pager, next, jumper" :total="tatol">
                                </el-pagination>
                            </div>
                        </div>
                        <el-dialog v-model="dialogTableVisible" title="临时会员" width="500">
                            <el-form :model="form">
                                <el-form-item label="折扣" :label-width="formLabelWidth">
                                    <el-input v-model="names" autocomplete="off" />
                                </el-form-item>
                                <el-form-item label="到期时间" :label-width="formLabelWidth">
                                    <el-date-picker v-model="value1" type="datetime" placeholder="选择到期时间" />
                                </el-form-item>
                            </el-form>
                            <template #footer>
                                <div class="dialog-footer">
                                    <el-button @click="dialogTableVisible = false">取消</el-button>
                                    <el-button type="primary" @click="Confirm">
                                        确认
                                    </el-button>
                                </div>
                            </template>
                        </el-dialog>
                    </div>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script setup>
import { ref, toRaw, reactive } from 'vue'
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRouter } from 'vue-router'

const feedinputs = ref('')
const loading = ref(true)
const member = ref([])
const members = ref(null)
const region = ref('会员')
const router = useRouter();
const dialogTableVisible = ref(false)
const formLabelWidth = '5vw'
const tatol = ref(0)
const value1 = ref('')
const wxUserIds = ref('')
const memberIds = ref('')
const page = reactive({ pageNum: 1, pageSize: 10 });
instance.get(`/api/UserControl/memberUser?pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
    .then(response => {
        console.log(response);
        members.value = response.data.data.records
        member.value = toRaw(members.value)
        tatol.value = response.data.data.totalCount
        // console.log(member.value);
        loading.value = false;
    })
    .catch(error => {
        console.log(error)
        ElMessage({
            message: '请重新登录',
            type: 'error',
        })
        router.push('./')
    })
const appendHttpsIfNeeded = (url) => {
    if (region.value !== '临时会员') {
        if (!url.startsWith('https')) {
            return 'https://www.jingtengyiliao.com/api/common/download?name=' + url;
        }
        return url;
    }
}


const handleSizeChanges = (val) => {
    page.pageSize = val;
    console.log(val);
    sift();
};
//当前页改变时触发
const handleCurrentChanges = (val) => {
    page.pageNum = val;
    console.log(val);
    sift();
};
const sift = () => {
    loading.value = true
    console.log(region.value);
    // loading.value = false
    if (feedinputs.value === '') {
        if (region.value === '会员') {
            instance.get(`/api/UserControl/memberUser?pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
                .then(response => {
                    members.value = response.data.data.records
                    member.value = toRaw(members.value)
                    tatol.value = response.data.data.totalCount
                    loading.value = false;
                })
                .catch(error => {
                    console.log(error)
                    ElMessage({
                        message: '请重新登录',
                        type: 'error',
                    })
                    router.push('./')
                })
        }
        if (region.value === '审核') {
            instance.get(`/api/UserControl/selectAudit?pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
                .then(response => {
                    members.value = response.data.data.records
                    member.value = toRaw(members.value)
                    tatol.value = response.data.data.totalCount
                    loading.value = false;
                })
                .catch(error => {
                    console.log(error)
                    ElMessage({
                        message: '请重新登录',
                        type: 'error',
                    })
                    router.push('./')
                })
        }
        if (region.value === '临时会员') {
            console.log('进入');
            instance.get(`/api/members/getProvisionalMemberList?pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
                .then(response => {
                    console.log(response);
                    members.value = response.data.data.records
                    member.value = toRaw(members.value)
                    tatol.value = response.data.data.totalCount
                    console.log(tatol.value);
                    loading.value = false;
                })
                .catch(error => {
                    console.log(error)
                    ElMessage({
                        message: '请重新登录',
                        type: 'error',
                    })
                    router.push('./')
                })
        }
    } else {
        if (region.value === '会员') {
            instance.get(`/api/UserControl/memberUser?pageNum=${1}&pageSize=${1000000}`)
                .then(response => {
                    members.value = response.data.data;
                    member.value = []
                    for (let i = 0; i < members.value.length; i++) {
                        if (members.value[i].wxUserId === feedinputs.value) {
                            member.value.push(members.value[i]);
                        }
                    }

                    loading.value = false;
                })
                .catch(error => {
                    console.log(error);
                    ElMessage({
                        message: '请重新登录',
                        type: 'error',
                    })
                    router.push('./')
                });
        } else if (region.value === '审核') {
            instance.get(`/api/UserControl/selectAudit?pageNum=${1}&pageSize=${1000000}`)
                .then(response => {
                    members.value = response.data.data;
                    member.value = []
                    for (let i = 0; i < members.value.length; i++) {
                        if (members.value[i].wxUserId === feedinputs.value) {
                            member.value.push(members.value[i]);
                        }
                    }

                    loading.value = false;
                })
                .catch(error => {
                    console.log(error);
                    ElMessage({
                        message: '请重新登录',
                        type: 'error',
                    })
                    router.push('./')
                });
        } else {
            instance.get(`/api/members/getProvisionalMemberList?pageNum=${1}&pageSize=${1000000}`)
                .then(response => {
                    members.value = response.data.data;
                    member.value = []
                    for (let i = 0; i < members.value.length; i++) {
                        if (members.value[i].wxUserId === feedinputs.value) {
                            member.value.push(members.value[i]);
                        }
                    }

                    loading.value = false;
                })
                .catch(error => {
                    console.log(error);
                    ElMessage({
                        message: '请重新登录',
                        type: 'error',
                    })
                    router.push('./')
                });
        }

    }

}

const Delete = (wxUserId) => {
    console.log(wxUserId);
    ElMessageBox.confirm(
        '您确定要删除该会员吗?',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            instance.delete(`/api/members/deleteMember/${wxUserId}`)
                .then(response => {
                    ElMessage({
                        type: 'success',
                        message: '删除成功',
                    })
                    sift();
                })

        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消删除',
            })
        })
}
const Pass = (wxUserId) => {
    console.log(wxUserId);
    loading.value = true
    ElMessageBox.prompt('请输入会员价', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
    })
        .then(({ value }) => {
            console.log(value);
            const isValidInput = /^(?:[1-9](\.[0-9])?|[1-9])$/.test(value);
            if (!isValidInput) {
                ElMessage({
                    type: 'warning',
                    message: '请输入有效的数字，如8.5',
                });
                sift();
                return;
            }
            let values = value / 10
            instance.put(`/api/members/applying?userID=${wxUserId}&memberPrice=${values}`)
                .then(response => {

                    ElMessage({
                        type: 'success',
                        message: `通过成功`,
                    })
                    loading.value = false
                    sift();
                }).catch(error => {
                    console.log(error);
                })

        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消通过',
            })
        })
}
const membership = (wxUserId) => {
    console.log(wxUserId);
    loading.value = true
    ElMessageBox.prompt('请输入会员价', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
    })
        .then(({ value }) => {
            console.log(value);
            const isValidInput = /^(?:[1-9](\.[0-9])?|[1-9])$/.test(value);
            if (!isValidInput) {
                ElMessage({
                    type: 'warning',
                    message: '请输入有效的数字，如8.5',
                });
                sift();
                return;
            }
            let values = value / 10
            instance.put(`/api/members/applying?userID=${wxUserId}&memberPrice=${values}`)
                .then(response => {

                    ElMessage({
                        type: 'success',
                        message: `通过成功`,
                    })
                    loading.value = false
                    sift();
                }).catch(error => {
                    console.log(error);
                })

        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消通过',
            })
        })
}

const refuse = (wxUserId) => {
    ElMessageBox.confirm(
        '您确定要拒绝通过该用户吗?',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            instance.put(`/api/members/rejectMember/${wxUserId}`)
            ElMessage({
                type: 'success',
                message: '拒绝成功',
            })
            sift();
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消拒绝',
            })
        })
}
const names = ref('')
const Confirm = () => {
    console.log(wxUserIds.value);
    const isValidInput = /^[1-9]{1}$/.test(names.value);
    if (!isValidInput) {
        ElMessage({
            type: 'warning',
            message: '请输入1到9之间的数字且只能输入一位',
        });
        return;
    }
    if (value1.value === '') {
        // console.log('kong');
        let memberPrice = names.value / 10
        let data = {
            wxUserId: wxUserIds.value,
            memberPrice: memberPrice,
            memberId: memberIds.value
        }
        instance.put(`/api/members/updateProvisionalMember`, data)
            .then(response => {
                console.log(response);
                if (response.data.state = 20000) {
                    ElMessage({
                        type: 'success',
                        message: `修改成功`,
                    })
                    dialogTableVisible.value = false
                    sift();
                }
            }).catch(error => {
                console.log(error);
            })
    } else {
        const chineseTime = ref('');
        const date = new Date(value1.value);
        // console.log(date);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        console.log(hours, minutes, seconds);
        chineseTime.value = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        console.log(chineseTime.value);

        const currentTime = ref('')
        const now = new Date();
        const years = now.getFullYear();
        const months = String(now.getMonth() + 1).padStart(2, '0');
        const dates = String(now.getDate()).padStart(2, '0');
        const hourss = String(now.getHours()).padStart(2, '0');
        const minutess = String(now.getMinutes()).padStart(2, '0');
        const secondss = String(now.getSeconds()).padStart(2, '0');
        currentTime.value = `${years}-${months}-${dates} ${hourss}:${minutess}:${secondss}`;
        console.log(currentTime.value);

        let memberPrice = names.value / 10
        let data = {
            wxUserId: wxUserIds.value,
            memberPrice: memberPrice,
            membershipEndDate: chineseTime.value,
            membershipStartDate: currentTime.value,
            memberId: memberIds.value
        }
        instance.put(`/api/members/updateProvisionalMember`, data)
            .then(response => {
                console.log(response);
                if (response.data.state = 20000) {
                    ElMessage({
                        type: 'success',
                        message: `修改成功`,
                    })
                    console.log('jinr');
                    dialogTableVisible.value = false
                    sift();

                }
            }).catch(error => {
                console.log(error);
            })
    }
}

const revise = (wxUserId, memberId) => {
    console.log(wxUserId);
    wxUserIds.value = wxUserId
    memberIds.value = memberId
    names.value = ''
    value1.value = ''
    dialogTableVisible.value = true

}
</script>

<style>
.container {
    position: fixed;
    top: 0;
    left: 15vw;
}

.main {
    position: fixed;
    top: 10vh;
    left: 15vw;
    width: 85vw;
    height: 90vh;
}

.feedbackesswhead .el-select {
    left: 5vw;
    width: 8vw;
}
</style>