<!-- 背景图 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <div class="atas">
                <div class="sta">
                    <p>
                        <span>修改背景图1</span>
                        <span>
                            <el-upload class="avatar-uploader" action="" :http-request="upload_24"
                                :show-file-list="false" :on-success="handleAvatarSuccess2"
                                :before-upload="beforeAvatarUpload" list-type="picture-card">
                                <img :key="imageUrl24" v-if="imageUrl24 != '' && imageUrl24 != undefined"
                                    :src="imageUrl24" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon">+</i>
                            </el-upload>
                            <el-button type="primary" @click="add1" >确认</el-button>
                        </span>
                    </p>
                    <p>
                        <span>修改背景图2</span>
                        <span>
                            <el-upload class="avatar-uploader" action="" :http-request="upload_25"
                                :show-file-list="false" :on-success="handleAvatarSuccess2"
                                :before-upload="beforeAvatarUpload" list-type="picture-card">
                                <img :key="imageUrl25" v-if="imageUrl25 != '' && imageUrl25 != undefined"
                                    :src="imageUrl25" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon">+</i>
                            </el-upload>
                            <el-button type="primary" @click="add2" >确认</el-button>
                        </span>
                    </p>
                    <p>
                        <span>修改背景图3</span>
                        <span>
                            <el-upload class="avatar-uploader" action="" :http-request="upload_26"
                                :show-file-list="false" :on-success="handleAvatarSuccess2"
                                :before-upload="beforeAvatarUpload" list-type="picture-card">
                                <img :key="imageUrl26" v-if="imageUrl26 != '' && imageUrl26 != undefined"
                                    :src="imageUrl26" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon">+</i>
                            </el-upload>
                            <el-button type="primary" @click="add3" >确认</el-button>
                        </span>
                    </p>
                    <p>
                        <span>修改背景图4</span>
                        <span>
                            <el-upload class="avatar-uploader" action="" :http-request="upload_27"
                                :show-file-list="false" :on-success="handleAvatarSuccess2"
                                :before-upload="beforeAvatarUpload" list-type="picture-card">
                                <img :key="imageUrl27" v-if="imageUrl27 != '' && imageUrl27 != undefined"
                                    :src="imageUrl27" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon">+</i>
                            </el-upload>
                            <el-button type="primary" @click="add4">确认</el-button>
                        </span>
                    </p>
                </div>
            </div>
        </el-container>
    </el-container>
</template>

<script setup>
import LeftNav from '../navbar/LeftNav.vue'
import TopNav from '../navbar/TopNav.vue'
import { ref, toRaw } from 'vue';
import { Plus } from 'element-plus';
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { Edit } from 'vxe-table';
import { useRouter, useRoute } from 'vue-router';
const imgurl24 = ref('')
const imageUrl24 = ref('')
const imgurl25 = ref('')
const imageUrl25 = ref('')
const imgurl26 = ref('')
const imageUrl26 = ref('')
const imgurl27 = ref('')
const imageUrl27 = ref('')
const data = ref(null);
const list = ref([]);
instance.get(`/api/statics`)
    .then(response => {
        console.log(response.data.data);
        data.value = response.data.data;
        list.value = data.value;
        console.log(list.value[0].background);
        instance.get(`/api/common/download?name=${list.value[0].background}`)
            .then(response => {
                imageUrl24.value = response.request.responseURL
            }).catch(error => {
                console.log(error);
                ElMessage({
                    showClose: true,
                    message: '请登录',
                    type: 'error',
                })
                router.push('/')
            })
        instance.get(`/api/common/download?name=${list.value[1].background}`)
            .then(response => {
                imageUrl25.value = response.request.responseURL
            }).catch(error => {
                console.log(error);
                ElMessage({
                    showClose: true,
                    message: '请登录',
                    type: 'error',
                })
                router.push('/')
            })
        instance.get(`/api/common/download?name=${list.value[2].background}`)
            .then(response => {
                imageUrl26.value = response.request.responseURL
            }).catch(error => {
                console.log(error);
                ElMessage({
                    showClose: true,
                    message: '请登录',
                    type: 'error',
                })
                router.push('/')
            })
            instance.get(`/api/common/download?name=${list.value[3].background}`)
            .then(response => {
                imageUrl27.value = response.request.responseURL
            }).catch(error => {
                console.log(error);
                ElMessage({
                    showClose: true,
                    message: '请登录',
                    type: 'error',
                })
                router.push('/')
            })
    })
    .catch(error => {
        //token失效跳转登录页面
        ElMessage({
            message: '请重新登录',
            type: 'error',
        })
        router.push('/')
    });
const upload_24 = (param) => {
    const formData = new FormData();
    formData.append('file', param.file);
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            //本地
            imgurl24.value = response.data.data;
            //请求
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl24.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                })
        }).catch(response => {
            console.log('图片上传失败')
        })
};
const upload_25 = (param) => {
    const formData = new FormData();
    formData.append('file', param.file);
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            //本地
            imgurl25.value = response.data.data;
            //请求
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl25.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                })
        }).catch(response => {
            console.log('图片上传失败')
        })
};
const upload_26 = (param) => {
    const formData = new FormData();
    formData.append('file', param.file);
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            //本地
            imgurl26.value = response.data.data;
            //请求
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl26.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                })
        }).catch(response => {
            console.log('图片上传失败')
        })
};
const upload_27 = (param) => {
    const formData = new FormData();
    formData.append('file', param.file);
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            //本地
            imgurl27.value = response.data.data;
            //请求
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl27.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                })
        }).catch(response => {
            console.log('图片上传失败')
        })
};
const add1 = () => {
    ElMessageBox.confirm(
        '是否确认修改静态图一',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: '提示',
        }
    )
        .then(() => {
            const stores = {
                "background": imgurl24.value,
                "id": 1001
            };
            console.log(stores);

            instance.put('/api/statics', stores)
                .then(response => {
                    console.log(response.data);
                    console.log('修改成功');
                    ElMessage({
                        message: '修改成功',
                        type: 'success',
                    })
                })
                .catch(error => {
                    console.error(error);
                });
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '已取消修改',
            })
        })
};

const add2 = () => {
    ElMessageBox.confirm(
        '是否确认修改静态图二',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: '提示',
        }
    )
        .then(() => {
            const stores = {
                "background": imgurl25.value,
                "id": 1002
            };
            console.log(stores);

            instance.put('/api/statics', stores)
                .then(response => {
                    console.log(response.data);
                    console.log('修改成功');
                    ElMessage({
                        message: '修改成功',
                        type: 'success',
                    })
                })
                .catch(error => {
                    console.error(error);
                });
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '已取消修改',
            })
        })
};

const add3 = () => {
    ElMessageBox.confirm(
        '是否确认修改静态图三',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: '提示',
        }
    )
        .then(() => {
            const stores = {
                "background": imgurl26.value,
                "id": 1003
            };
            console.log(stores);

            instance.put('/api/statics', stores)
                .then(response => {
                    console.log(response.data);
                    console.log('修改成功');
                    ElMessage({
                        message: '修改成功',
                        type: 'success',
                    })
                })
                .catch(error => {
                    console.error(error);
                });
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '已取消修改',
            })
        })
};
const add4 = () => {
    ElMessageBox.confirm(
        '是否确认修改静态图四',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: '提示',
        }
    )
        .then(() => {
            const stores = {
                "background": imgurl27.value,
                "id": 1004
            };
            console.log(stores);

            instance.put('/api/statics', stores)
                .then(response => {
                    console.log(response.data);
                    console.log('修改成功');
                    ElMessage({
                        message: '修改成功',
                        type: 'success',
                    })
                })
                .catch(error => {
                    console.error(error);
                });
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '已取消修改',
            })
        })
};
</script>

<style>
.leftnavbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 15vw;
    height: 100vh;
    background-color: #545c64;
}

.container {
    position: fixed;
    top: 0;
    left: 15vw;
}

.container_top {
    width: 85vw;
    height: 10vh;
    background-color: #545c64;
}


.adds {
    position: fixed;
    top: 0 !important;
    width: 100vw;
    height: 100vh;
    background-image: url('../assets/register.jpg');
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
}

.add {
    top: 20vh !important;
    position: fixed;
    width: 20vw;
    height: 70vh;
    left: 40vw;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
    padding: 0 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

}

.add p {
    display: flex;
    margin-top: 0vh !important;
}

.add input,
.add button {
    display: block;
    margin: 0 auto;
    width: 50%;
    height: 5vh;
}

.sta {
    overflow: auto;
    /* 设置页面内容溢出时显示滚动条 */
    height: 100%;
    width: 100%;
    position: relative;

    /* display: flex; */
}

.atas {
    width: 100vw;
    height: 90vh;
    display: flex;
    margin-top: 10vh;
}
</style>