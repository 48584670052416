<!-- 门店管理区域 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <el-main class="main">
                <div class="main_item">
                    <div class="stores">
                        <div class="main_topnav">
                            <div class="topnav_input">
                                <!-- <el-input v-model="input1" class="w-50 m-2" size="large" placeholder="搜索门店"
                                :prefix-icon="Search" />
                            <el-button class="input_btn" type="primary">搜索</el-button> -->
                            </div>
                            <el-button class="addStore" type="primary" :icon="Edit" @click="goToAddstore"
                                v-if="stata === '0'">新增门店</el-button>
                            <!-- <el-button type="primary" class="addStore">
                            导出<el-icon class="el-icon--right">
                                <Upload />
                            </el-icon>
                        </el-button> -->
                        </div>
                        <div class="table-a">
                            <el-table v-loading="loading" element-loading-text="加载中，请稍后..." :data="list" border="true"
                                style="width: 100%; font-size: 12px;" height="100%">
                                <el-table-column :prop="item.prop" :label="item.label" v-for="(item, index) in options"
                                    :key="index" :width="item.width">

                                    <template #default="scope" v-if="item.prop === 'address'"></template>

                                    <template #default="scope" v-if="item.prop === 'name'"></template>

                                    <template #default="scope" v-if="item.prop === 'indexImgUrl'">
                                        <div style="width: 50px;height: 50px;display: flex;align-items: center;">
                                            <el-image fit="fill"
                                                :src="`https://www.jingtengyiliao.com/api/common/download?name=${scope.row.indexImgUrl}`"></el-image>
                                        </div>
                                    </template>

                                    <template #default="scope" v-if="item.prop === 'storeBgimgUrl'">
                                        <div style="width: 50px;height: 50px;display: flex;align-items: center;">
                                            <el-image fit="fill"
                                                :src="`https://www.jingtengyiliao.com/api/common/download?name=${scope.row.storeBgimgUrl}`"></el-image>
                                        </div>
                                    </template>

                                    <template #default="scope" v-if="item.prop === 'storeTximgUrl'">
                                        <div style="width: 50px;height: 50px;display: flex;align-items: center;">
                                            <el-image fit="fill"
                                                :src="`https://www.jingtengyiliao.com/api/common/download?name=${scope.row.storeTximgUrl}`"></el-image>
                                        </div>
                                    </template>
                                    <template #default="scope" v-if="item.prop === 'action'">
                                        <div class="button-group">
                                            <div class="button-row">
                                                <el-button class="input_btn" type="primary"
                                                    @click="goToUpdaStore(scope.row.id, scope.row.address, scope.row.indexImgUrl, scope.row.storeTximgUrl, scope.row.storeBgimgUrl, scope.row.name, scope.row.longitude, scope.row.latitude, scope.row.storeSort)">编辑</el-button>
                                                <el-button class="input_btn" type="danger"
                                                    @click="delStore(scope.row.id)">删除</el-button>
                                                <el-button type="primary" @click="open(scope.row.id)" class="but"
                                                    style="justify-content: center;">添加分类</el-button>
                                                <el-button type="primary" @click="prompt(scope.row.id)" class="but"
                                                    style="justify-content: center;">提示</el-button>
                                                <el-button type="danger" @click="DeletePrompt(scope.row.id)" class="but"
                                                    style="justify-content: center;">删除提示</el-button>
                                                <el-button type="primary" @click="configurations(scope.row.id)"
                                                    class=" delete-btn"
                                                    style="justify-content: center;">新增配置</el-button>
                                                <el-button type="danger" @click="Editconfigurations(scope.row.id)"
                                                    class=" delete-btn"
                                                    style="justify-content: center;">删除配置</el-button>
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>

                        </div>
                        <el-dialog v-model="dialogVisible" title="门店提示" width="500" draggable
                            :before-close="handleClose">
                            <el-form :model="form" label-width="10vw">
                                <el-form-item label="提示标题">
                                    <el-input class="inputbox" v-model="TipHead" style="width: 20vw;" />
                                </el-form-item>
                                <el-form-item label="提示内容">
                                    <el-input v-model="tiele" style="width: 20vw" :autosize="{ minRows: 2, maxRows: 4 }"
                                        type="textarea" placeholder="提示内容" />
                                </el-form-item>
                            </el-form>
                            <template #footer>
                                <div class="dialog-footer">
                                    <el-button @click="toggleModalss">取消</el-button>
                                    <el-button type="primary" @click="onSubmitss">
                                        确认
                                    </el-button>
                                </div>
                            </template>
                        </el-dialog>
                        <el-dialog v-model="dialogVisibles" title="偏远地区配置" width="500" draggable
                            :before-close="handleClos">
                            <el-form :model="form" label-width="10vw">
                                <el-form-item label="地区">
                                    <el-select style="width: 20vw" v-model="value1" multiple placeholder="选择地区"
                                        @remove-tag="handleChange">
                                        <el-option :style="{ display: isDropdownVisible ? 'block' : 'none' }"
                                            v-for="(item, index) in store" :key="item.storeRemoteCityId"
                                            :label="item.name" :value="item.cityId" loading-text />
                                    </el-select>
                                </el-form-item>
                            </el-form>
                            <template #footer v-if="isDropdownVisible">
                                <div class="dialog-footer">
                                    <el-button @click="toggleModals">取消</el-button>
                                    <el-button type="primary" @click="onSubmits">
                                        确认
                                    </el-button>
                                </div>
                            </template>
                        </el-dialog>
                    </div>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script setup>
import { ref } from 'vue'
import { Search } from '@element-plus/icons-vue'
import { Edit, Upload, Delete, Plus } from '@element-plus/icons-vue'
import { toRaw } from '@vue/reactivity'
import { options } from '../network/storesOptions'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import instance from '../network/storester.js'

const input1 = ref('');
const dialogVisibles = ref(false)
const data = ref(null);
const list = ref([]);
const router = useRouter();
const loading = ref(true);
const images = ref([])
const image = ref([])
const stata = localStorage.getItem('permissionsStatus')
const dialogVisible = ref(false)
const isDropdownVisible = ref(false)
const TipHead = ref('')
const tiele = ref('')
const value1 = ref([])
const value = ref([])
const store = ref([])
const stor = ref('')
const flae = ref(false)
const handleChange = (removedTag) => {
    // const index = value1.value.indexOf(removedTag);
    // console.log(index)
    const removedItem = value.value.find(item => item.cityId === removedTag);
    console.log(removedItem.storeRemoteCityId)
    instance.delete(`/api/remoteCity/deleteStoreRemoteCityById?storeRemoteCityId=${removedItem.storeRemoteCityId}`)
        .then(res => {
            if (res.data.code == 0) {
                ElMessage({
                    type: 'error',
                    message: '删除失败,该门店可能不存在偏远地区配置',

                })
                return;
            }
            ElMessage({
                type: 'success',
                message: '删除成功',
            })
            instance.get(`/api/remoteCity/getStoreRemoteCityByStoreId?storeId=${ide.value}`)
                .then(res => {
                    console.log(res.data.data)
                        value.value = res.data.data
                        console.log(value1.value)
                        Stores()
                }).catch(err => {
                    console.log(err)
                })
        }).catch(error => {
            console.log(error)
        })
}
const relationship = ref('')
const toggleModalss = () => {
    dialogVisible.value = !dialogVisible.value
    TipHead.value = ''
    tiele.value = ''
}
const handleClose = () => {
    toggleModalss()
}
const handleClos = () => {
    toggleModals()
}
const toggleModals = () => {
    dialogVisibles.value = !dialogVisibles.value
    console.log(value1.value)
}
if (localStorage.getItem('permissionsStatus') === '0') {
    instance.get('/api/stores')
        .then(response => {
            data.value = response.data;
            list.value = toRaw(data.value);
            console.log(list.value);
            console.log(images.value);
            console.log(image.value);
            loading.value = false;

        })
        .catch(error => {
            console.error(error);
            ElMessage({
                message: '请重新登录',
                type: 'error',
            })
            router.push('./')
            // 可以执行其他错误处理逻辑
        });
} else {
    instance.get('/api/stores')
        .then(response => {
            const matchedUsers = [];
            data.value = response.data;
            console.log(data.value[1].id);
            for (let i = 0; i < data.value.length; i++) {
                if (data.value[i].id == localStorage.getItem('sysStoreId')) {
                    matchedUsers.push(data.value[i]);
                    console.log(matchedUsers);
                }
            }
            list.value = matchedUsers;
            loading.value = false;
        })
        .catch(error => {
            console.log(error);
            ElMessage({
                message: '请重新登录',
                type: 'error',
            })
            router.push('./')
        });
}

const Stores = () => {
    instance.get(`/api/remoteCity/getRemoteCityList`)
        .then(res => {
            console.log(res)
            stor.value = res.data.data
            store.value = toRaw(stor.value)
        }).catch(err => {
            console.log(err)
        })
}
const goToAddstore = () => {
    if (localStorage.getItem('permissionsStatus') === '0') {
        router.push('/addstore');
    } else {
        ElMessage.error('您没有权限添加门店');
    }

}
const goToUpdaStore = (id, address, indeximg, txurl, bgurl, name, longitude, latitude, storeSort) => {
    console.log(id);
    //判断权限，是否有权限添加

    // //判断账号id与获取到的id是否相同
    console.log(longitude);
    // if (id === localStorage.getItem('sysStoreId') || localStorage.getItem('permissionsStatus') === '0') {
    router.push({
        path: '/updastore',
        query: {
            msg: id,
            name: name,
            address: address,
            indeximg: indeximg,
            txurl: txurl,
            bgurl: bgurl,
            latitude: latitude,
            longitude: longitude,
            storeSort: storeSort
        }
    });
    // } else {
    //     ElMessage.error('您没有权限编辑该门店');
    // }

}


//判断权限进行删除门店
const delStore = (id) => {
    if (localStorage.getItem('permissionsStatus') === '0') {
        ElMessageBox.confirm(
            '该操作存在风险，是否继续执行',
            '提示',
            {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning',
            }
        )
            .then(() => {
                //获取到点击的门店的id
                const storeId = id;
                instance.delete(`/api/stores/${storeId}`)
                    .then(response => {
                        console.log(response.data);
                        if (response.data.code === 0) {
                            ElMessage({
                                type: 'error',
                                message: `${response.data.msg}`,
                            })
                        } else {
                            ElMessage({
                                type: 'success',
                                message: `删除成功`,

                            })
                            console.log('删除成功');
                            instance.get('/api/stores')
                                .then(response => {
                                    data.value = response.data;
                                    // var stores = toRaw(data.value)
                                    list.value = toRaw(data.value);
                                    console.log(list.value);
                                })
                                .catch(error => {
                                    console.error(error);
                                });
                        }
                        //删除成功,重新刷新数据

                    })
                    .catch(error => {
                        console.error(error);
                    });
            })
            .catch(() => {
                ElMessage({
                    type: 'info',
                    message: '已取消操作',
                })
            })
    } else {
        ElMessage.error('您没有权限删除');
    }

}
const open = (id) => {
    console.log(id);
    console.log(id);
    ElMessageBox.prompt('请输入需要添加分类名称', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消'
    })
        .then(({ value }) => {
            if (value.trim() === '') {
                ElMessage({
                    type: 'error',
                    message: `名称不能为空`,
                })
            }
            const cate = {
                "name": value,
                "storeId": id
            };
            instance.post('/api/categories', cate)
                .then(response => {
                    //处理响应数据
                    console.log(response.data);
                    ElMessage({
                        type: 'success',
                        message: `添加成功，添加的分类名称为:${value}`,
                    })
                    // this.$router.go(0);
                    // window.location.reload();
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('./')
                })
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消添加',
            })
        })
}
const verify = ref(false)
const ids = ref('')
const ida = ref('')
const ide = ref('')
const and = ref(false)
const prompt = (id) => {
    ida.value = ''
    ids.value = id
    dialogVisible.value = !dialogVisible.value
    instance.get(`/api/warmTips/selectById?storeId=${id}`)
        .then(response => {
            if (response.data.data !== undefined) {
                console.log(response.data.data)
                verify.value = true
                TipHead.value = response.data.data.title
                tiele.value = response.data.data.content
                ida.value = response.data.data.id
            }
            and.value = true
            console.log(verify.value)
            console.log(ida.value)
        }).catch(error => {
            console.log(error)
        })
}

const configurations = (id) => {
    ide.value = id
    flae.value = false
    value1.value = []
    isDropdownVisible.value = true
    toggleModals()
    Stores()
}
const Editconfigurations = (id) => {
    ide.value = id
    value1.value = []
    isDropdownVisible.value = false
    instance.get(`/api/remoteCity/getStoreRemoteCityByStoreId?storeId=${ide.value}`)
        .then(res => {
            console.log(res.data.data)
            toggleModals()
            if (res.data.data.length === 0) {
                ElMessage({
                    showClose: true,
                    message: '该门店暂无偏远地区配置，请先新增',
                    type: 'error',
                })
                return;
            } else {
                flae.value = true
                for (let i = 0; i < res.data.data.length; i++) {
                    // const element = array[i];
                    value1.value.push(res.data.data[i].cityId)
                    value.value.push(res.data.data[i])
                }
                console.log(value1.value)

                Stores()
            }
        }).catch(err => {
            console.log(err)
        })
}

const onSubmits = () => {
    if (value1.value.length === 0) {
        ElMessage({
            showClose: true,
            message: '请选择地区',
            type: 'error',
        })
        return;
    }
    console.log(ide.value)
    console.log(value1.value)

    for (let i = 0; i < value1.value.length; i++) {
        const data = {
            cityId: value1.value[i],
            storeId: ide.value
        }
        instance.post(`/api/remoteCity/insertStoreRemoteCity`, data)
            .then(res => {
                // if (res.data.code == 200) {
                console.log(res)
                // ElMessage({
                //     type: 'success',
                //     message: '添加成功',

                // })
                // return;
                // }
            }).catch(err => {
                console.log(err)
            })
        ElMessage({
            type: 'success',
            message: '添加成功',

        })
    }

    toggleModals()
}
const onSubmitss = () => {
    console.log(ida.value)
    if (and.value === false) {
        ElMessage({
            showClose: true,
            message: '请先等待加载数据',
            type: 'error',
        })
        return
    }
    if (TipHead.value == '' || tiele.value == '') {
        ElMessage({
            showClose: true,
            message: '请输入标题和内容',
            type: 'error',
        })
        return
    }
    console.log(verify.value)
    if (verify.value == true) {
        const warmTipsParam = {
            "storeId": ids.value,
            "title": TipHead.value,
            "content": tiele.value,
            "status": 1,
            "id": ida.value
        }
        instance.put(`/api/warmTips/updateWarmTips`, warmTipsParam)
            .then(res => {
                console.log(res)
                ElMessage({
                    showClose: true,
                    message: '修改成功',
                    type: 'success',
                })
                toggleModalss()
            })
            .catch(error => {
                console.log(error)
                ElMessage({
                    showClose: true,
                    message: '添加失败',
                    type: 'error',
                })
            })
    } else {
        const warmTipsParam = {
            "storeId": ids.value,
            "title": TipHead.value,
            "content": tiele.value,
            "status": 1,
            "priority": 1
        }
        instance.post(`/api/warmTips/insertWarmTips`, warmTipsParam)
            .then(res => {
                console.log(res)
                ElMessage({
                    showClose: true,
                    message: '添加成功',
                    type: 'success',
                })
                toggleModalss()
            })
            .catch(error => {
                console.log(error)
                ElMessage({
                    showClose: true,
                    message: '添加失败',
                    type: 'error',
                })
            })
    }

}

const DeletePrompt = (id) => {
    ElMessageBox.confirm(
        '你是否要删除该门店的提示?',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            instance.get(`/api/warmTips/selectById?storeId=${id}`)
                .then(response => {
                    if (response.data.data !== undefined) {
                        console.log(response.data.data)
                        ida.value = response.data.data.id
                        instance.delete(`/api/warmTips/deleteWarmTipsById?id=${ida.value}`)
                            .then(res => {
                                if (res.data.code == 0) {
                                    ElMessage({
                                        type: 'error',
                                        message: '删除失败,该门店可能不存在提示',

                                    })
                                    return;
                                }
                                ElMessage({
                                    type: 'success',
                                    message: '删除成功',
                                })
                            }).catch(error => {
                                console.log(error)
                            })
                    } else {
                        ElMessage({
                            type: 'error',
                            message: '删除失败,该门店可能不存在提示',

                        })
                        return;
                    }
                }).catch(error => {
                    console.log(error)
                })


        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消删除',
            })
        })
}
</script>

<style>
.container {
    position: fixed;
    top: 0;
    left: 15vw;
}



.main {
    position: fixed;
    top: 10vh;
    left: 15vw;
    width: 85vw;
    height: 90vh;
}

.main_item {
    position: fixed;
    left: 11vw;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.main_topnav {
    width: 80%;
    height: 7vh;
    position: fixed;
    top: 11vh;
    display: flex;
    align-items: center;
}

.main_topnav .el-button {
    margin-left: -20.5vw;
}

.topnav_input {
    width: 20%;
    margin-left: 3%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input_btn {
    margin-left: 10px;
}

.addStore {
    margin-left: 30px;
}

.table-a {
    position: fixed;
    top: -9vh;
    width: 85vw;
    height: 63vh;
    margin-top: 15%;
    /* box-shadow: 0px 0px 10px rgba(17, 16, 16, 0.5); */
}

.stores {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -11vw;
}

.button-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.button-row {
    display: flex;
    flex-wrap: wrap;
    /* 允许换行 */
    margin-bottom: 8px;
    max-width: 45vw;
}

.delete-btn {
    margin-top: 12px;
    margin-left: 0.7vw !important;
}

.nase {
    display: none;
}
</style>
