<!-- 快递 -->

<template>
  <el-container class="Wrapper">
    <el-container class="container">
      <div class="containerse">
        <div class="common-layout">
          <el-container>
            <el-header style="padding: 0; margin: 0">
            </el-header>
            <el-main style="width: 100vw; margin-left: 25vw;">
              <div class="user_input">
                <div style="position:relative; width: 70%;margin-left: 15%; margin-bottom: 30px;margin-top: 30px">
                  <el-input v-model="kuaidinum" class="w-50 m-2 selfinput" size="mini" placeholder="输入单号"
                    @keyup.enter="query" />
                  <el-button type="primary" class="self_btn" :icon="Search" @click="query">查询</el-button>
                </div>
                <div style="width: 70%;margin-left: 15%; margin-bottom: 30px">
                  <el-select v-model="kuaidicom" class="m-2" placeholder="选择快递公司">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </div>
                <div style="width: 70%;margin-left: 15%; margin-bottom: 30px;margin-top: 30px">
                  <el-input v-model="phone" placeholder="收件人电话号码（顺丰必填）后4位" />
                </div>
              </div>

              <div class="trackBox" style="width: 70%; margin-left: 5%; height: 400px; overflow: auto">
                <el-timeline>
                  <el-timeline-item v-for="(item, index) in trackData" :key="index" :icon="item.icon"
                    :color="item.color" :timestamp="item.acceptTime">
                    {{ item.remark }} {{ item.desc }}
                  </el-timeline-item>
                </el-timeline>
              </div>

            </el-main>

          </el-container>
        </div>
      </div>
    </el-container>
  </el-container>
</template>

<script setup>
import { ref } from 'vue';
import instance from "@/network/storester";
import { ElMessage } from "element-plus";

const trackData = ref([]); // 定义 trackData 变量并初始化为空数组
const kuaidinum = ref('');
const kuaidicom = ref('');
const phone = ref('');
const Search = ref('el-icon-search');

const options = [
  { value: 'zhongtong', label: '中通快递' },
  { value: 'jtexpress', label: '极兔速递' },
  { value: 'yunatong', label: '圆通速递' },
  { value: 'shunfeng', label: '顺丰速运' },
  { value: 'yunda', label: '韵达快递' },
  { value: 'shentong', label: '申通快递' },
  { value: 'youzhengguonei', label: '邮政速递' },
  { value: 'ems', label: 'EMS' },
  { value: 'jd', label: '京东物流' },
  { value: 'debangkuaidi', label: '德邦快递' },
  { value: 'debang物流', label: '德邦物流' },
  { value: 'fengwang', label: '丰网速运' },
  { value: 'sxjdfreight', label: '顺心捷达' },
  { value: 'zhaijisong', label: '宅急送' },
  { value: 'danniao', label: '丹鸟' },
  { value: 'shunfengkuaiyun', label: '顺丰快运' },
  { value: 'disifang', label: '递四方' },
  { value: 'zhuanyunsifang', label: '转运四方' },
  { value: 'ups', label: 'UPS' },
  { value: 'jinguangsudikuaijian', label: '京广速递' },
  { value: 'yimidida', label: '壹米滴答' },
];

const query = () => {
  // 实现查询逻辑，根据输入的单号、快递公司和电话号码查询快递信息
  const form = {};
  trackData.value = [];
  //判断输入是否为空
  if (kuaidinum.value === "" || kuaidinum.value == null || kuaidicom.value == null || kuaidicom.value === "") {
    ElMessage({
      message: '请输入准确的信息',
      type: 'error',
    })
    return;
  }
  //对单号进行简单检验
  const reg = /^[a-zA-Z]*[0-9]+$/;
  if (!reg.test(kuaidinum.value)) {
    ElMessage({
      message: '请输入正确的单号',
      type: 'error',
    })
    return;
  }
  form.trackingNumber = kuaidinum.value;
  // form.com = kuaidicom.value;
  let company = String(kuaidicom.value);
  if ("shunfeng" === company) {
    if (phone.value === "" || phone.value == null) {
      ElMessage({
        message: '请输入收件人后4位号码',
        type: 'error',
      })
      return;
    }
    form.phone = phone.value;
  }
  let apiEndpoint = "";
  console.log(kuaidicom.value);
  // 根据 form.com 的不同值设置不同的接口地址
  switch (company) {
    case "shunfeng":
      apiEndpoint = "/api/expressage/sfRoutes";
      break;
    // 可根据需要添加更多 case
    case "other_company":
      apiEndpoint = "/api/expressage/otherRoutes";
      break;
    case "zhongtong":
      apiEndpoint = "/api/expressage/ztRoutes";
      form.phone = "";
      break;
    default:
      // 默认的处理方式，可以选择一个通用的接口地址或者提示用户选择的快递公司暂不支持
      apiEndpoint = "/api/expressage/defaultRoute";
      break;
  }
  instance.post(apiEndpoint, form)
    .then((response) => {
      const responseData = response.data;
      console.log("查询的快递数据");
      console.log(responseData);

      let routes = [];

      // 根据快递公司的不同处理响应数据
      switch (company) {
        case "shunfeng":
          // 处理顺丰快递的路由信息
          routes = responseData.data.routes || [];
          break;
        case "other_company":
          // 处理其他快递公司的路由信息
          // 可根据具体情况从 response.data 中提取路由信息
          routes = data.data.routes || [];
          break;
        case "zhongtong":
          let datas = response.data.data;
          console.log(datas);
          let zhongtongData = JSON.parse(datas); // 解析JSON字符串
          // 处理中通快递的路由信息
          routes = zhongtongData.result || [];
          break;
        default:
          // 其他快递公司的处理方式
          routes = data.data.routes || [];
          break;
      }

      // 将处理后的路由信息赋值给 trackData.value
      console.log("data数据");
      console.log(routes);
      trackData.value = routes;
    })
    .catch((error) => {
      console.error("请求快递数据时出错：", error);
    });
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
}

.el-select {
  width: 100%;
}

.selfinput {
  width: 90% !important;
}

.self_btn {
  position: absolute;
  right: 0 !important;
}

.containerse {
  width: 90vw;
  height: 99vh;
  margin-left: -4vw;
  margin-top: -3vh;
}

::-webkit-scrollbar {
  height: 6px;
  width: 3px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 rgba(240, 240, 240, .5);
  border-radius: 2px;
  background-color: rgba(240, 240, 240, .5);
}

.user_input {
  height: 200px;
  width: 500px;
}

.common-layout {
  width: 100%;
  height: 100%;
  background-image: url("../assets/bug.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
