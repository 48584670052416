<template>
  <el-container class="container">
    <el-header class="container_top">
      <TopNav></TopNav>
    </el-header>
  </el-container>
  <el-container class="Wrapper">
    <el-aside width="200px" class="leftnavbar">
      <el-menu :default-active="$route.path" active-text-color="#ffd04b" background-color="#545c64"
        class="el-menu-vertical-demo left" default-active="2" text-color="#fff" unique-opened="true" router>
        <div class="menu_img">
          <img src="../assets/logos.png" alt="">
        </div>
        <el-menu-item index="/homepage" v-if="state === '1'">
          <el-icon><setting /></el-icon>
          <span >首页</span>
        </el-menu-item>
        <el-sub-menu index="1">
          <template #title>
            <span class="menu_font">门店管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/storemanagement" class="menu_font-item">门店管理</el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <el-sub-menu index="2">
          <template #title>
            <span class="menu_font">商品管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/classify" class="menu_font-item">分类管理</el-menu-item>
            <el-menu-item index="/commodity" class="menu_font-item">商品管理</el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <el-sub-menu index="3" v-if="state === '0'">

          <template #title>
            <span class="menu_font">用户管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/feedback" class="menu_font-item">用户反馈</el-menu-item>
            <el-menu-item index="/allusers" class="menu_font-item">所有用户</el-menu-item>
            <el-menu-item index="/comment" class="menu_font-item">用户评论</el-menu-item>
            <el-menu-item index="/manageusers" class="menu_font-item">管理用户</el-menu-item>
            <el-menu-item index="/memberusers" class="menu_font-item">会员用户</el-menu-item>
            <el-menu-item index="/searchhistory" class="menu_font-item">搜索历史</el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <el-sub-menu index="4">
          <template #title>
            <span class="menu_font">订单管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/allorder" class="menu_font-item">所有订单</el-menu-item>

          </el-menu-item-group>
        </el-sub-menu>
        <el-sub-menu index="14" v-if="state === '0'">
          <template #title>
            <span class="menu_font">商户提现</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/withdrawal" class="menu_font-item"> 提现管理</el-menu-item>
            <el-menu-item index="/Commission" class="menu_font-item"> 手续管理</el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <el-sub-menu index="5" v-if="state === '0'">
          <template #title>
            <span class="menu_font">公告管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/announcementlist" class="menu_font-item"> 公告管理</el-menu-item>
            <el-menu-item index="/addannouncement" class="menu_font-item">发布公告</el-menu-item>
            <el-menu-item index="/ad" class="menu_font-item">公告列表</el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <el-sub-menu index="6">
          <template #title>
            <span class="menu_font">系统管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/admin" class="menu_font-item" v-if="state === '0'">管理员</el-menu-item>
            <el-menu-item index="/addanaccount" class="menu_font-item">添加账号</el-menu-item>
            <!-- <el-menu-item index="/changethepassword" class="menu_font-item">修改密码</el-menu-item> -->
            <el-menu-item index="/operate" class="menu_font-item" v-if="state === '0'">宣传视频</el-menu-item>
            <el-menu-item index="/statimg" class="menu_font-item" v-if="state === '0'">轮播图管理</el-menu-item>
            <el-menu-item index="/stat" class="menu_font-item" v-if="state === '0'">静态图片管理</el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <!-- <el-sub-menu index="7">
          <template #title>
            <span class="menu_font">促销管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/discount" class="menu_font-item">优惠券</el-menu-item>
          </el-menu-item-group>
        </el-sub-menu> -->
        <el-sub-menu index="8">
          <template #title>
            <span class="menu_font">物流管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/logistics" class="menu_font-item">物流管理</el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <el-sub-menu index="9" v-if="state === '0'">
          <template #title>
            <span class="menu_font">院区设置</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/Campus" class="menu_font-item">院区设置</el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <!-- <el-sub-menu index="10">
        <template #title>
          <span class="menu_font">管理权限</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/role" class="menu_font-item" v-if="state === '0'">角色管理</el-menu-item>
          <el-menu-item index="/userrole" class="menu_font-item" v-if="state === '0'">管理员列表</el-menu-item>
          <el-menu-item index="/permissions" class="menu_font-item" v-if="state === '0'">权限管理</el-menu-item>
        </el-menu-item-group>
      </el-sub-menu> -->
        <el-sub-menu index="11">
          <template #title>
            <span class="menu_font">财务管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/capitalflow" class="menu_font-item" >资金流水</el-menu-item>
            <el-menu-item index="/commissionrecords" class="menu_font-item"  v-if="state === '0'">提现记录</el-menu-item>
            <el-menu-item index="/order" class="menu_font-item" v-if="state === '0'">佣金提现</el-menu-item>
          </el-menu-item-group>
        </el-sub-menu>
        <el-menu-item index="/lnventorymanagement" v-if="state === '0'">
          <!-- <el-icon><setting /></el-icon> -->
          <span>诊疗系统产品</span>
        </el-menu-item>
        <el-menu-item index="/remoteareas"  v-if="state === '0'">
          <!-- <el-icon><setting /></el-icon> -->
          <span>偏远配置</span>
        </el-menu-item>
        <!-- <el-sub-menu index="12" v-if="state === '0'">
          <template #title>
            <span class="menu_font">营养系统产品</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/lnventorymanagement" class="menu_font-item">营养系统产品</el-menu-item>
          </el-menu-item-group>
        </el-sub-menu> -->
      </el-menu>
    </el-aside>
  </el-container>

  <el-aside class="container">
    <router-view>
    </router-view>
  </el-aside>
</template>

<script setup>
import TopNav from '../navbar/TopNav.vue'
import { ref, toRaw, reactive, onMounted, onUnmounted, computed } from "vue";
import instance from '../network/storester.js'

const state = localStorage.getItem('permissionsStatus')

const timeUntilEndOfDay = ref('');
console.log(localStorage.getItem('authID'));
const authID = localStorage.getItem('authID')
console.log(authID);
const authIds = encodeURIComponent(authID)
console.log(authIds);
const storages = ref(null)
// 获取当天的23:59时间戳
const endOfDayTimestamp = computed(() => {
  const now = new Date();
  const endOfDay = new Date(now);
  endOfDay.setHours(23, 59, 59, 0); // 设置为当天的23:59
  // endOfDay.setHours(10, 38, 40, 0);
  return endOfDay.getTime();
});
const today = ref(new Date());
const years = today.value.getFullYear();
const months = (today.value.getMonth() + 1).toString().padStart(2, '0');
const days = today.value.getDate().toString().padStart(2, '0');
console.log(years, months, days);
// 计算距离当天的23:59还有多少时间
const calculateTimeUntilEndOfDay = () => {
  const now = new Date().getTime();
  const difference = endOfDayTimestamp.value - now;
  if (difference > 0) {
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / (1000 * 60)) % 60);
    const seconds = Math.floor((difference / 1000) % 60);
    // console.log(`距离当天的23:59还有${hours}小时 ${minutes}分钟 ${seconds}秒`);
    return `${hours}小时 ${minutes}分钟 ${seconds}秒`;

  } else {
    return '已过期';
  }
};

let timer;

onMounted(() => {
  timer = setInterval(() => {
    timeUntilEndOfDay.value = calculateTimeUntilEndOfDay();
    if (timeUntilEndOfDay.value === '已过期') {
      clearInterval(timer);
      // 在时间到达后执行你的逻辑
      // console.log('已到达当天的23:59');
      instance.get(`/api/dispatching/getDispatchingList`)
        .then(response => {
          console.log(response.data.data);
          storages.value = response.data.data;
          console.log(storages.value);
          for (let i = 0; i < storages.value.length; i++) {
            console.log(storages.value[i].ordersSystemType);
            const dateString = storages.value[i].addOrderTime
            const year = ref(dateString.substring(0, 4)); // 截取前四位获取年份
            const month = ref(dateString.substring(5, 7)); // 截取第五位到第七位获取月份
            const day = ref(dateString.substring(8, 10)); // 截取第八位到第十位获取日期
            console.log(`${year.value}${month.value}${day.value}`);
            if (`${year.value}${month.value}${day.value}` === `${years}${months}${days}`) {
              const adds = {
                "addOrderTime": storages.value[i].addOrderTime,
                "goodsName": storages.value[i].goodsName,
                "goodsNumber": storages.value[i].goodsNumber,
                "id": storages.value[i].id,
                "inviteId": storages.value[i].inviteId,
                "message": storages.value[i].message,
                "name": storages.value[i].name,
                "authId": authID,
                "goodsId": storages.value[i].goodsId,
                "nickName": storages.value[i].nickName,
                "orderPrice": storages.value[i].orderPrice,
                "storeName": storages.value[i].storeName,
                "wxUserId": storages.value[i].wxuserId
              }
              console.log(adds);
              instance.post(`/api/inventory/newBack`, adds)
                .then(response => {
                  console.log(response);
                })
                .catch(error => {
                  console.log(error);
                })
            }

          }
        }).catch(error => {
          console.log(error);
        })
    }
  }, 1000);
});

onUnmounted(() => {
  clearInterval(timer);
});
const orders = ref(null)
const ordera = ref('')
const remainingDays = ref(2);
// 初始化定时器
onMounted(() => {
  const timer = setInterval(() => {
    // remainingDays.value -= 1;
    // if (remainingDays.value <= 0) {
    // clearIntervals(timer);
    // 在这里执行你想要的操作
    instance.get(`/api/orders`)
      .then(response => {
        console.log(response.data.data);
        orders.value = response.data.data
        // order.value = toRaw(orders.value)
        // console.log(order.value);
        console.log(orders.value.length);
        for (let i = 0; i < orders.value.length; i++) {
          if (orders.value[i].trackingNumber !== undefined) {
            console.log(orders.value[i].trackingNumber);
            const order = orders.value[i].trackingNumber.substring(0, 2);
            console.log(order);
            if (order === 'SF') {
              const length = orders.value[i].mobile.length;
              const mobile = orders.value[i].mobile.substring(length - 4);
              console.log(orders.value[i].trackingNumber);
              console.log(mobile);
              let form = {
                "phone": mobile,
                "trackingNumber": orders.value[i].trackingNumber
              }
              ordera.value = orders.value[i].id
              instance.post('/api/expressage/sfRoutes', form)
                .then(response => {
                  console.log(response);
                  if (response.data.data.routes.length !== 0) {
                    const target = "本人签收";
                    const index = response.data.data.routes.length
                    console.log(index, target);
                    console.log(response.data.data.routes[index - 2]);
                    if (response.data.data.routes[index - 2].remark.indexOf(target)) {
                      console.log(ordera.value);
                      instance.put(`/api/orders/status4/${ordera.value}`)
                        .then(response => {
                          console.log('成功');
                        }).catch(error => {
                          console.log(error);
                        })
                    }
                  }
                })
                .catch(error => {
                  console.log(error);
                })
            }
          }

        }
      }).catch(error => {
        console.log(error);
      })
    console.log('定时器触发了！');
    // remainingDays.value = 2;
    // }
  }, 48 * 60 * 60 * 1000); // 每隔一天执行一次，即24小时 * 60分钟 * 60秒 * 1000毫秒
});

</script>
<style>
.left {
  z-index: 0.1rem;
}

.leftnavbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 15vw;
  height: 100vh;
  background-color: #545c64;
}

.container_top {
  width: 85vw;
  height: 10vh;
  background-color: #545c64;
}

.menu_img {
  width: 10vw;
  height: 10vh;
  display: flex;
  text-align: center;
  justify-content: center;
}

.menu_img img {
  width: 8vw;
  height: 5vh;
  margin-top: 1vh;
}

.menu_font {
  font-size: 1rem !important;
}

.menu_font-item .el-sub-menu {
  width: 1rem !important;
}

.leftnavbar {
  width: 11vw !important;
}

.menus {
  width: 90vw;
  height: 100vh;
  /* position: fixed; */
  margin-right: 85vw;
}

.container {
  position: fixed;
  top: 0;
  left: 15vw;
}

.menu {
  /* background-color: aquamarine; */
  display: inline-block;
  position: fixed;
  top: 10vh;
  left: 11vw;
  width: 10vw;
  height: 90vh;
}

.main_item {
  left: 11vw
}

.menuse {
  top: 0;
  left: 0;
  width: 200px;
  height: 100vh;
}

.container_top {
  position: fixed;
  width: 85vw;
  height: 10vh;
  left: 1vh;
}

.top_item {
  width: 15vw;
}

.containers {
  width: 10vw;
}

#app {
  width: 0;
}
</style>
