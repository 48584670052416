<!-- 编辑公告 -->

<template>
    <el-container class="Wrapper">
        <el-container class="containersa">
            <el-scrollbar class="Evideo" height="100vh" style="margin-left: 2vw; width: 100vw;">
                <el-card style="">
                    <h1 style="color: orange">编辑内容公告</h1>
                    <el-form label-width="100px">
                        <el-form-item label="公告标题">
                            <el-input v-model="c.title" placeholder="请输入公告标题" class="inputAddnn"></el-input>
                        </el-form-item>
                        <el-form-item label="公告状态">
                            <el-radio-group v-model="c.status">
                                <el-radio label='草稿箱'> 草稿箱</el-radio>
                                <el-radio label='发布'>发布</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="选择类型" class="nase">
                            <el-select placeholder="请选择" v-model="mediaType">
                                <el-option label="图片" value="1"></el-option>
                                <el-option label="视频" value="2"></el-option>
                                <el-option label="文本" value="3"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item v-if="mediaType == '图片' || mediaType == '1'" label="封面">
                            <el-upload v-model:file-list="fileList"
                                action="https://www.jingtengyiliao.com/api/announcement/upload" name="file" limit="1"
                                list-type="picture-card" :on-preview="handlePictureCardPreview"
                                :on-remove="handleRemove" :on-success="handleSuccess" :before-upload="beforeUpload"
                                :headers="headers">
                                <el-icon>
                                    <Plus />
                                </el-icon>
                            </el-upload>
                            <el-dialog v-model="dialogVisible">
                                <img w-full :src="dialogImageUrl" alt="Preview Image" />
                            </el-dialog>
                            <!--                上传文件的组件结束-->
                        </el-form-item>
                        <el-form-item v-if="mediaType == '2' || mediaType == '视频'" label="视频">
                            <el-upload class="upload-demos"
                                action="https://www.jingtengyiliao.com/api/common/uploadVideo"
                                :before-upload="beforeAvatarUploadsd" multiple :limit="3">
                                <video v-if="videoUrl !== ''" :src="videoUrl" controls autoplay></video>
                                <el-button v-else type="primary">点击上传</el-button>
                                <div v-if="uploading">正在上传...</div>
                                <div v-if="uploaded">上传完成！</div>
                            </el-upload>
                            <el-dialog v-model="dialogVisible">
                                <img w-full :src="dialogImageUrl" alt="Preview Image" />
                            </el-dialog>
                            <!--                上传文件的组件结束-->
                        </el-form-item>

                        <el-form-item v-show="mediaType != 2 && mediaType != '视频'" label="公告内容">
                            <!--                div 的ref是为绑定对应的变量-->
                            <div ref="editorContent"></div>

                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="post()">发布内容</el-button>
                            <el-button type="primary" @click="returns()">返回</el-button>
                        </el-form-item>
                    </el-form>
                </el-card>
            </el-scrollbar>
        </el-container>
    </el-container>
</template>

<script setup>
import { onMounted, ref, toRaw } from 'vue'
import { useRoute, useRouter } from "vue-router";
import { ElMessage } from 'element-plus'
import Editor from 'wangeditor';
import router from "@/router";
import instance from "@/network/storester";
import { Plus } from '@element-plus/icons-vue'


const route = useRoute();
const imageUrl = ref('')
const handleChange = () => {  //函数的方式监听当前的信息
    // 调用方法
    loadCategories();
}
/** 处理二级分类 当前先不用**/
// const categoryArr = ref([]);
const mediaType = ref('')
/** 添加的表单的数据信息js **/
const c = ref({ title: "", status: "", mediaType: "", createTime: "" });
const editors = ref(null);
const edito = ref([])
const videoUrl = ref('')
instance.get(`/api/announcement/getAnnouncementId?id=${route.query.id}`)
    .then(response => {
        editors.value = response.data.data;
        console.log(editors.value);
        edito.value = toRaw(editors.value)
        console.log(edito.value);
        c.value.title = editors.value.title;
        if (editors.value.mediaType == 1) {
            mediaType.value = "图片";
            fileList.value = [{
                name: 0,
                url: `https://www.jingtengyiliao.com/api/common/download?name=${editors.value.mediaUrl}`
            }]
            imageUrl11.value = editors.value.mediaUrl
        } else if (editors.value.mediaType == 2) {
            mediaType.value = "视频";
            console.log('ship');
            instance.get(`/api/common/downloadVideo?name=${editors.value.mediaUrl}`)
                .then(response => {
                    videoUrl.value = response.request.responseURL
                    console.log(videoUrl.value);
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                })
        } else if (editors.value.mediaType == 3) {
            console.log('文本');
            mediaType.value = "文本";
        }
        console.log(editors.value.status);
        if (editors.value.status == 1) {
            c.value.status = "草稿箱";
        } else if (editors.value.status == 2) {
            c.value.status = "发布";
        }
        console.log(editors.value.content)

    }).catch(error => {
        console.log(error);
        ElMessage({
            type: 'error',
            message: `请重新登录`,
        })
        router.push('/')
    })
const deletesa = () => {
    let url = imageUrl11.value
    console.log(url);
    instance.delete("/api/upload/remove?url=" + url)
        .then((response) => {
            if (response.data.state == 20000) {
                ElMessage.success("删除文件完成！")
            }
        })
    imageUrl11.value = ''
}
const post = () => {
    console.log('6666');
    console.log(mediaType.value);
    if (c.value.title.trim() == '') {
        ElMessage.error('标题不能为空');
        return;
    }
    // 得到当前登录的用户信息
    let user = localStorage.user ? JSON.parse(localStorage.user) : null;
    if (user == null) {
        alert("请先登录！");
        router.push('/');
        return;
    }
    console.log(user);
    c.value.author = user.id;  //设置作者id
    console.log(c.value.createBy);
    // 把富文本编辑器里面的内容取出  取出的是包含HTML标签的内容
    c.value.content = editor.txt.html();
    console.log(c.value.content);
    // 设置摘要（正文的前30个字符作为摘要） 得到的是纯文本内容的信息（不包含HTML标签）
    // c.value.brief = editor.txt.text().slice(0,30);
    // console.log(c.value.brief);
    //   let data = qs.stringify(c.value);
    if (c.value.status == "草稿箱") {
        c.value.status = 1;
    } else if (c.value.status == "发布") {
        c.value.status = 2;
    }
    if (mediaType.value == 1 ) {
        c.value.mediaType = 1;
        const regex = /(https:\/\/www.jingtengyiliao.com\/api\/common\/download\?name=([^,]+))/;
        let matches = fileList.value[0].url.match(regex);

        if (matches === null) {
            c.value.mediaUrl = fileList.value[0].response.data
        } else {
            if (matches[2] === undefined) {
                c.value.mediaUrl = matches[4]
            } else {
                c.value.mediaUrl = matches[2]
            }
        }
    } else if (mediaType.value == "视频") {
        c.value.content = ''
        c.value.mediaType = 2
    } else if (mediaType.value == "文本") {
        c.value.mediaType = 3
        c.value.mediaUrl = ''
    }
    const a = c.value
    const chineseTime = ref('');
    const date = new Date(a.createTime);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    chineseTime.value = `${year}-${month}-${day}`;
    console.log(chineseTime.value);
    let data = {
        "id": editors.value.id,
        "title": c.value.title,
        "status": c.value.status,
        "mediaType": c.value.mediaType,
        "mediaUrl": c.value.mediaUrl,
        "content": c.value.content,
    }
    console.log(data);
    instance.put('/api/announcement/modification', data)
        .then((response) => {
            if (response.data.state == 20000) {
                ElMessage.success("修改成功");
                // 跳转到公告管理页面下架或者发布
                router.push('/announcementlist');
            }

        }).catch(error => {
            console.log(error);
        })
}


/**     上传文件的js代码    **/
const fileList = ref([])

const dialogImageUrl = ref('')
const dialogVisible = ref(false)
const handleSuccess = (response, file, files) => {

    // 此处的response就是服务器响应的对象
    c.value.mediaUrl = response.data;
}

const headers = ref({ Authorization: '' });



const handlePictureCardPreview = (uploadFile) => {
    dialogImageUrl.value = uploadFile.url
    dialogVisible.value = true
}
const imageUrl11 = ref('')
const imgurl11 = ref('')

/** 富文本编辑器相关的 **/
const editorContent = ref(null);
let editor = null;

// const showEditorContent = (content) => {
//   editorContent.value.innerHTML = content;
// };
onMounted(() => {
    // 实例化富文本编辑器对象，需要用到页面中的div
    editor = new Editor(editorContent.value);
    // 设置占位文本
    editor.config.placeholder = "请输入公告内容详情...";
    // 将富文本编辑器渲染到页面中
    editor.create();

    // 发请求获取二级分类数据，,需要变化的话从数据库中拿取

    // 设置token
    const token = window.sessionStorage.getItem('token');
    if (token) {
        headers.value.Authorization = token;
    }

    // 判断地址栏中是否包含id,location.search等到地址栏中？以及后面的内容
    if (location.search.includes('id')) {
        let id = new URLSearchParams(location.search).get('id');
        instance.get(`/api/announcement/getAnnouncementId?id=` + id)
            .then(response => {
                c.value = response.data.data;
                editors.value = response.data.data;
                console.log(editors.value);
                edito.value = toRaw(editors.value)
                console.log(edito.value);
                c.value.title = editors.value.title;
                if (editors.value.mediaType == 1) {
                    c.value.mediaType = "图片";
                    fileList.value = [{
                        name: 0,
                        url: `https://www.jingtengyiliao.com/api/common/download?name=${editors.value.mediaUrl}`
                    }]
                    // imageUrl11.value = `https://199shq.com/api${editors.value.mediaUrl}`
                } else if (editors.value.mediaType == 2) {
                    c.value.mediaType = "视频";
                    instance.get(`/api/common/downloadVideo?name=${editors.value.mediaUrl}`)
                        .then(response => {
                            videoUrl.value = response.request.responseURL
                        }).catch(error => {
                            console.log(error);
                            ElMessage({
                                showClose: true,
                                message: '请登录',
                                type: 'error',
                            })
                            router.push('/')
                        })
                } else if (editors.value.mediaType == 3) {
                    c.value.mediaType = "文本";
                }
                if (editors.value.status == 1) {
                    c.value.status = "草稿箱";
                } else if (editors.value.status == 2) {
                    c.value.status = "发布";
                }

                // 设置富文本编辑器显示的内容
                editor.txt.html(c.value.content)

            })
            .catch(error => {
                console.log(error);
                ElMessage({
                    type: 'error',
                    message: `请重新登录`,
                })
                router.push('/')
            })
    }

})
// 定义一个方法
const loadCategories = () => {
    console.log("单前绑定的信息：" + c.value.type);

}
const returns = () => {
    router.push('announcementlist')
}
const uploading = ref(false);
const uploaded = ref(false);
const namec = ref('')
const beforeAvatarUploadsd = (file) => {
    console.log(file);
    if (file.type !== 'video/mp4') {
        ElMessage({
            type: 'error',
            message: '请上传mp4格式视频',
        })
        return;
    }
    const formData = new FormData();
    formData.append('file', file);
    instance.post('/api/common/uploadVideo', formData)
        .then(response => {
            console.log('上传成功');
            console.log(file);
            console.log(response);
            c.value.mediaUrl = response.data.data
            namec.value = response.data.data;
            videoUrl.value = URL.createObjectURL(file)
            console.log(namec.value);
            console.log(videoUrl.value);
        }).catch(error => {
            console.log(error);
            console.log('上传失败')
        })
}

</script>

<style>
.leftnavbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 15vw;
    height: 100vh;
    background-color: #545c64;
}

.containersa {
    position: fixed;
    top: 7vh;
    left: 10vw;
}

.container_top {
    width: 85vw;
    height: 10vh;
    background-color: #545c64;
}

.main {
    position: fixed;
    top: 10vh;
    left: 15vw;
    width: 85vw;
    height: 90vh;
}

.deletess {
    position: absolute;
    top: -1vh;
    left: 11vw;
    width: 1vw;
    height: 2.5vh;
    cursor: pointer;
}

.el-card {
    border: 0px solid #545c64;
}

.el-form-item {
    margin-bottom: 10vh;
}

/* .Evideo {
    width: 85vw;
} */
.Evideo .el-upload video {
    width: 20vw;
    height: 10vw;
}
</style>