<!-- 查询用户反馈 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <el-main class="main">
                <div class="main_item">
                    <div class="feedbackess">
                        <div class="feedbackesshead">
                            <el-input v-model="feedbackessinput" placeholder="请输入" @keyup.enter="feedbackessQuery"
                                class="feedbackessSearch"></el-input>
                            <el-button type="primary" @click="feedbackessQuery"
                                class="feedbackessSearchBtn">查询</el-button>
                        </div>
                        <div class="feedbackessbody">
                            <el-table :data="fedbakese" v-loading="loading" element-loading-text="加载中，请稍后..."
                                style="width: 100%" height="65vh" border>
                                <el-table-column prop="wxUserId" label="用户id" width="180vw" />
                                <el-table-column prop="contact" label="联系方式" width="150vw" />
                                <el-table-column prop="createAdviceTime" label="反馈时间" width="180vw" />
                                <el-table-column prop="opinion" label="用户反馈" />
                            </el-table>
                        </div>
                        <div class="feedbackessfoot">
                            <div class="demo-pagination-blocks">
                                <el-pagination  v-model:current-page="currentPage4" v-model:page-size="pageSize4" @size-change="handleSizeChanges" @current-change="handleCurrentChanges"
                                    :current-page="currentPage4" :page-sizes="[10, 20, 30, 40]" :page-size="10"
                                    layout="total, sizes, prev, pager, next, jumper" :total="tatol">
                                </el-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script setup>
import Menu from '../navbar/Menu.vue'
import LeftNav from '../navbar/LeftNav.vue'
import TopNav from '../navbar/TopNav.vue'
import { ref, reactive } from 'vue';
import { toRaw } from 'vue';
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRouter } from 'vue-router';


const fedbakese = ref([]);
const fedbakes = ref(null)
const loading = ref(true);
const feedbackessinput = ref('');
const router = useRouter();
const tatol = ref(0);

    instance.get('/api/advice/allAdvice')
        .then(response => {
            // fedbakes.value = response.data.data;
            // fedbakese.value = toRaw(fedbakes.value);
            // console.log(fedbakese.value);
            tatol.value = response.data.data.length;
            loading.value = false;
        }).catch(error => {
            console.log(error);
            ElMessage({
                message: '请重新登录',
                type: 'error',
            })
            router.push('./')
            // 可以执行其他错误处理逻辑
        });
    instance.get(`/api/advice/paginationAdvice?&pageNum=${1}&pageSize=${10}`)
        .then(response => {
            console.log(response.data);
            fedbakes.value = response.data;
            fedbakese.value = toRaw(fedbakes.value);
            console.log(fedbakese.value);
            console.log('11');
            // tatol.value = response.data.data.length;
            loading.value = false;
        }).catch(erroe => {
            ElMessage({
                message: '请重新登录',
                type: 'error',
            })
            router.push('./')
            // 可以执行其他错误处理逻辑
        });

const page = reactive({ pageNum: 1, pageSize: 10 });
const handleSizeChanges = (val) => {
    page.pageSize = val;
    console.log(val);
    sifts();
};
router
//当前页改变时触发
const handleCurrentChanges = (val) => {
    page.pageNum = val;
    console.log(val);
    sifts();
};
const sifts = () => {
    console.log(page.pageSize);
    console.log(page.pageNum);
    instance.get(`/api/advice/paginationAdvice?&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
        .then(response => {
            fedbakes.value = response.data;
            fedbakese.value = toRaw(fedbakes.value);
            console.log(fedbakese.value);
        }).catch(erroe => {
            console.log(erroe);
            ElMessage({
                message: '请重新登录',
                type: 'error',
            })
            router.push('./')
        })
}
const feedbackessQuery = () => {
    loading.value = true
    console.log(feedbackessinput.value);
    instance.get(`/api/advice/getRelationDim?relationDim=${feedbackessinput.value}`)
        .then(response => {
            fedbakes.value = response.data
            fedbakese.value = toRaw(fedbakes.value);
            console.log(fedbakese.value);
            loading.value = false;
        }).catch(error => {
            console.log(error);
            ElMessage({
                message: '请重新登录',
                type: 'error',
            })
            router.push('./')
        })
}

</script>

<style>


.container {
    position: fixed;
    top: 0;
    left: 15vw;
}


.main {
    position: fixed;
    top: 10vh;
    left: 15vw;
    width: 85vw;
    height: 90vh;
}

.feedbackess {
    position: fixed;
    width: 85vw;
    top: 10vh;
    left: 11vw;
}

.feedbackess .el-table tr.el-table__row {
    height: 40px !important;
}



.feedbackessbody {
    margin-top: 3vh;
    width: 100vw;
}

.feedbackessfoot {
    width: 100vw;
    height: 10vh;
    /* top: 10vh; */
    margin-top: 4vh;
    /* background-color: red; */
}

.feedbackessSearchBtn {
    width: 5vw;
    height: 5vh;
    border: none;
    border-radius: 5px;
    margin-left: 1vw;
}

.feedbackessSearch {
    width: 35vh;
    height: 5vh;
    left: 1vw;
}

.feedbackessfoot .demo-pagination-blocks {
    position: fixed;
    width: 100vw;
    /* top: 90vh; */
    height: 10vh;
    left: 25%;
    /* background-color: red; */
}
</style>