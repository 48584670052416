import { createApp } from 'vue'
// import Vue from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import router from './router'
import store from './store'

import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
// import 'vx-easyui/dist/themes/default/easyui.css';
// import 'vx-easyui/dist/themes/icon.css';
// import 'vx-easyui/dist/themes/vue.css';
// import EasyUI from 'vx-easyui';
// import Vue3EasyDataTable from 'vue3-easy-data-table';
// import 'vue3-easy-data-table/dist/style.css';
// import 'xe-utils'
// import VXETable from 'vxe-table'
// import 'vxe-table/lib/style.css'

// import mzlUi from "mzl-ui"
// import "../node_modules/mzl-ui/dist/style.css"

// Vue.use(VXETable)
//  // 给 vue 实例挂载内部对象
//  Vue.prototype.$XModal = VXETable.modal
//  Vue.prototype.$XPrint = VXETable.print
//  Vue.prototype.$XSaveFile = VXETable.saveFile
//  Vue.prototype.$XReadFile = VXETable.readFile

createApp(App).use(store).use(router).use(ElementPlus, {locale: zhCn,}).use(VXETable)
.mount('#app')
