<!-- 草稿箱 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <el-main class="main">
                <div class="main_item">
                    <div class="announcements">
                        <el-table :data="draft" height="75vh" style="width: 100%" v-loading="loading"
                      element-loading-text="加载中，请稍后...">
                            <el-table-column prop="title" label="标题" width="80" />
                          <el-table-column label="内容" width="300">
                            <template #default="scope">
                              <div v-if="scope.row.mediaType !== 2" v-html="scope.row.content"></div>
                            </template>
                          </el-table-column>
                            <el-table-column prop="createTime" label="创建时间" width="180" />
                            <el-table-column prop="mediaUrl" label="发布视频或图片" width="200">
                                <template #default="scope">
                                    <div style="width: 50px;height: 50px;display: flex;align-items: center;">
                                        <el-image fit="fill" lazy v-if="scope.row.mediaType === 1"
                                            :src="`https://www.jingtengyiliao.com/api/common/download?name=${scope.row.mediaUrl}`">
                                        </el-image>
                                        <video style="width: 200px ;height: 80px;" v-if="scope.row.mediaType === 2"
                                            :src="`http://jt.jingtengyiliao.com/${scope.row.mediaUrl}`"
                                            controls></video>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="createTime" label="操作" width="180">
                                <template #default="scope">
                                    <el-button type="primary" @click="handleEdit(scope.row.id)">编辑</el-button>
                                    <el-button type="danger" @click="handleDelete(scope.row.id)">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script setup>
import Menu from '../navbar/Menu.vue'
import LeftNav from '../navbar/LeftNav.vue'
import TopNav from '../navbar/TopNav.vue'
import { ref } from 'vue';
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRoute, useRouter } from "vue-router";

const router = useRouter();
const draft = ref([])
const drafts = ref(null)
const images = ref([])
const image = ref([])
const loading = ref(true)

instance.get('/api/announcement/getAnnouncementList')
    .then(res => {
        drafts.value = res.data.data
        console.log(drafts.value);
        for (let i = 0; i < drafts.value.length; i++) {
            if (drafts.value[i].status === 1) {
                console.log(drafts.value[i]);
                draft.value.push(drafts.value[i]);
                console.log(draft.value);
            }
        }
        for (let i = 0; i < draft.value.length; i++) {
                images.value.splice(i, images.value.length)
                images.value.push(draft.value[i].mediaUrl)
            }
            fetchData();
            console.log(images.value);
            console.log(image.value);
        loading.value = false
    }).catch(error => {
        console.log(error);
        ElMessage({
            showClose: true,
            message: '请登录',
            type: 'error',
        })
        router.push('/')
    })

const handleDelete = (id) => {
    console.log(id);
    ElMessageBox.confirm(
        '您确定要删除该草稿吗?',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            loading.value = true
            instance.delete(`/api/announcement/deleteAnnouncement?id=${id}`)
                .then(response => {
                    ElMessage({
                        type: 'success',
                        message: '删除成功',
                    })
                }).catch((error) => {
                    console.log(error);
                })
                loading.value = false
            window.location.reload()
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '取消删除',
                    })
                })
        })
}
const handleEdit = (id) => {
    router.push({
        path: '/editannouncement',
        query: {
            id: id
        }
    })
}
async function fetchData() {
console.log(image.value);
image.value.splice(0, image.value.length);
// image.value = []
for (let j = 0; j < images.value.length; j++) {
    console.log('111');
    try {
        const response = await instance.get(`/api${images.value[j]}`);
        console.log('333');
        image.value.splice(j, image.value.length);
        image.value.push(response.request.responseURL);
    } catch (error) {
        console.log(error);
    }
}
console.log(image.value);
}
</script>

<style>


.container {
    position: fixed;
    top: 0;
    left: 15vw;
}



.main {
    position: fixed;
    top: 10vh;
    left: 15vw;
    width: 85vw;
    height: 90vh;
}
</style>