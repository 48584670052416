<!-- 编辑门店 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <el-card class="card">
                <el-row :gutter="100">
                    <el-col :span="7">
                        编辑门店
                    </el-col>
                </el-row>
                <el-row :gutter="100">
                    <el-col :span="7">
                        <!-- ref绑定到上面的属性,用于校验验证表单是否正确输入,model绑定的是整个表单数据,rules绑定的是最外层的总体的那个规则对象 -->
                        <el-from ref="baseForm" :model="loginForm" :rules="loginrules">
                            <!--label为显示的内容,prop为规则对象内的一个属性,v-model与表单数据双向绑定-->
                            <el-form-item label="门店名称" prop="storename">
                                <el-input v-model="name" />
                            </el-form-item>
                            <!-- <div id="r-result"><span class="men">门店地址</span><span class="meninput"><input type="text"
                                        id="suggestId" size="20" v-model="storaddress" /></span>
                            </div>
                            <div id="searchResultPanel"
                                style="border:1px solid #C0C0C0;width:15vw;height:auto; display:none;">
                            </div>
                            <div id="l-map"></div> -->
                            <el-form-item label="门店地址" prop="longitude">
                                <el-input v-model="storaddress" />
                            </el-form-item>
                            <el-form-item label="门店经度" prop="longitude">
                                <el-input v-model="longitude" @input="getAdd"/>
                            </el-form-item>
                            <el-form-item label="门店纬度" prop="latitude">
                                <el-input v-model="latitude" />
                            </el-form-item>
                            <el-form-item label="门店排序" prop="storename">
                                            <el-input v-model="storeSort" />
                                        </el-form-item>
                        </el-from>

                        <!-- 上传一张图片用来作为商店首页图片 -->
                        <p>
                            请先择您要修改的首页图片：(建议上传背景图片比例为175:117)
                        </p>
                        <el-upload class="avatar-uploader" action="" :http-request="upload_1" :show-file-list="false"
                            :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                            <img v-if="imageUrl_1" :src="imageUrl_1" class="avatar">
                            <el-icon v-else class="avatar-uploader-icon">
                                <Plus />
                            </el-icon>
                        </el-upload>

                        <!-- 上传一张图片用来作为门店头像图片 -->
                        <p>
                            请先择您要修改的门店背景：(建议上传背景图片比例为39：10,当前是390×844机型)
                        </p>
                        <el-upload class="avatar-uploader" action="" :http-request="upload_2" :show-file-list="false"
                            :on-success="handleAvatarSuccess_1" :before-upload="beforeAvatarUpload">
                            <img v-if="imageUrl_2" :src="imageUrl_2" class="avatar">
                            <el-icon v-else class="avatar-uploader-icon">
                                <Plus />
                            </el-icon>
                        </el-upload>

                        <!-- 上传一张图片用来作为门店背景 -->
                        <p>
                            请先择您要修改的门店头像图片：(建议上传头像图片比例为1:1)
                        </p>
                        <el-upload class="avatar-uploader" action="" :http-request="upload_3" :show-file-list="false"
                            :on-success="handleAvatarSuccess_2" :before-upload="beforeAvatarUpload">
                            <img v-if="imageUrl_3" :src="imageUrl_3" class="avatar">
                            <el-icon v-else class="avatar-uploader-icon">
                                <Plus />
                            </el-icon>
                        </el-upload>
                        <p class="buttonse">
                            <el-button type="primary" @click="submit">提交</el-button>
                            <el-button type="danger" @click="cancels">取消</el-button>
                        </p>
                    </el-col>
                </el-row>
            </el-card>
        </el-container>

    </el-container>
</template>

<script setup>
import Menu from '../navbar/Menu.vue'
import LeftNav from '../navbar/LeftNav.vue'
import TopNav from '../navbar/TopNav.vue'
import { reactive, ref, onMounted, onBeforeUnmount } from 'vue'
import { FromInstance, FormRules } from 'element-plus'
import { ElMessage, ElMessageBox } from 'element-plus'
import { Plus } from '@element-plus/icons-vue'
import { useRouter, useRoute } from 'vue-router'
import { watch } from 'vue';
import instance from '../network/storester.js'

const router = useRouter();
const route = useRoute();
const storeId = route.query.msg;
console.log(storeId);
console.log(route.query);
const longitude = ref('')
longitude.value = route.query.longitude
const latitude = ref('')
latitude.value = route.query.latitude
const storaddress = ref('')
storaddress.value = route.query.address
const storeSort = ref('')
storeSort.value = route.query.storeSort
// function G(id) {
//     return document.getElementById(id);
// }

// let map;
// let ac;
// let BMap = null;
// onMounted(() => {
//     BMap = window.BMap;
//     console.log(BMap);
//     map = new BMap.Map("l-map");
//     map.centerAndZoom("北京", 12);

//     ac = new BMap.Autocomplete({
//         "input": "suggestId",
//         "location": map
//     });

//     ac.addEventListener("onhighlight", onHighlight);
//     ac.addEventListener("onconfirm", onConfirm);
//     var point = new BMap.Point(route.query.longitude, route.query.latitude); // 创建点坐标
//     map.centerAndZoom(point, 15); // 初始化地图，设置中心点坐标和地图级别
//     map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放

//     // 创建标注点
//     var marker = new BMap.Marker(point); // 创建标注
//     map.addOverlay(marker); // 将标注添加到地图中

//     // 定位当前浏览器位置
//     var geolocation = new BMap.Geolocation(); // 创建定位实例
//     console.log(geolocation);
//     geolocation.getCurrentPosition(function (r) {
//         if (this.getStatus() == BMAP_STATUS_SUCCESS) {
//             var mk = new BMap.Marker(r.point); // 根据定位结果创建标注
//             map.addOverlay(mk); // 将标注添加到地图中
//             map.panTo(r.point); // 平移地图到定位结果的位置
//             // alert('您的位置：' + r.point.lng + ',' + r.point.lat); // 弹出定位结果的经纬度信息
//             storaddress.value = route.query.address
//             console.log(storaddress.value);
//         } else {
//             // alert('failed' + this.getStatus()); // 定位失败时弹出错误状态
//         }
//     });

//     // 点击地图获取经度和维度
//     map.addEventListener('click', handleClick);
//     function handleClick(e) {
//         // alert('点击的经纬度：' + e.point.lng + ', ' + e.point.lat);
//         longitude.value = e.point.lng
//         latitude.value = e.point.lat
//         console.log(e);
//         var pt = e.point;
//         console.log(pt);
//         console.log(longitude.value);
//         console.log(latitude.value)
//         var geoc = new BMap.Geocoder();
//         const marker = new BMap.Marker(new BMap.Point(longitude.value, latitude.value));
//         map.addOverlay(marker);
//         console.log(map.addOverlay(marker));
//         geoc.getLocation(pt, (rs) => {
//             const addComp = rs.addressComponents;
//             storaddress.value = addComp.province + addComp.city + addComp.district + addComp.street + addComp.streetNumber;
//             console.log(storaddress.value + '牛');
//         });
//     }


// });

// onBeforeUnmount(() => {
//     if (ac) {
//         ac.removeEventListener("onhighlight", onHighlight);
//         ac.removeEventListener("onconfirm", onConfirm);
//     }
// });

// function onHighlight(e) {
//     let str = "";
//     let _value = e.fromitem.value;
//     let value = "";
//     if (e.fromitem.index > -1) {
//         value = _value.province + _value.city + _value.district + _value.street + _value.business;
//     }
//     str = "FromItem<br />index = " + e.fromitem.index + "<br />value = " + value;

//     value = "";
//     if (e.toitem.index > -1) {
//         _value = e.toitem.value;
//         value = _value.province + _value.city + _value.district + _value.street + _value.business;
//     }
//     str += "<br />ToItem<br />index = " + e.toitem.index + "<br />value = " + value;
//     G("searchResultPanel").innerHTML = str;
// }

// function onConfirm(e) {
//     let _value = e.item.value;
//     let myValue = _value.province + _value.city + _value.district + _value.street + _value.business;
//     G("searchResultPanel").innerHTML = "onconfirm<br />index = " + e.item.index + "<br />myValue = " + myValue;

//     setPlace(myValue);
//     console.log(e.item.value);
//     storaddress.value = e.item.value.province + e.item.value.city + e.item.value.district + e.item.value.business + e.item.value.streetNumber;
//     console.log(storaddress.value);
// }

// function setPlace(myValue) {
//     if (map) {
//         map.clearOverlays();
//         function myFun() {
//             var pp = local.getResults().getPoi(0).point;
//             map.centerAndZoom(pp, 18);
//             map.addOverlay(new BMap.Marker(pp));
//             longitude.value = pp.lng
//             latitude.value = pp.lat
//         }

//         var local = new BMap.LocalSearch(map, {
//             onSearchComplete: myFun,

//         })
//         local.search(myValue);
//         console.log(storaddress.value)
//     }
// }
const loginForm = ref({
    storename: route.query.name,
});

const data = ref(null);
const list = ref([]);

const getStore = () => {
    instance.get(`/api/common/download?name=${route.query.indeximg}`)
        .then(response => {
            console.log(response);
            data.value = response.data;
            // var stores = toRaw(data.value)
            list.value = data.value;
        })
        .catch(error => {
            console.error(error);
            ElMessage({
                message: '请重新登录',
                type: 'error',
            })
            router.push('./')
            // 可以执行其他错误处理逻辑
        });
};
getStore();



// const loginrules = ref({
//     username: [
//         { required: true, message: "信息不能为空", trigger: "blur" },
//         { min: 3, max: 5, message: '请输入长度为三到五', trigger: 'blur' },
//     ],
//     name: [
//         { required: true, message: "信息不能为空", trigger: "blur" },
//         { min: 3, max: 5, message: '请输入长度为三到五', trigger: 'blur' },
//     ],
// })
//这个baseForm为获取表单的对象ref值,用于对表单规则是否通过进行校验,baseForm绑定到form标签上
const baseForm = ref(null);
const submitForm = () => {
    //对表单元素进行校验
    baseForm.value.validate((valid) => {
        if (!valid) {
            //校验失败
            console.log("请调整数据后再请求");
            return false;
        }
        // 校验成功
        console.log("123123", 123123);
    });
};

//获取到图片的url地址
//用来储存门店首页图片
const imageUrl_1 = ref('');
instance.get(`/api/common/download?name=${route.query.indeximg}`)
    .then(response => {
        imageUrl_1.value = response.request.responseURL
        console.log(imageUrl_1.value);
    }).catch(error => {
        console.log(error);
        ElMessage({
            showClose: true,
            message: '请登录',
            type: 'error',
        })
        router.push('/')
    });
// console.log(imageUrl);
//图片在本地地址
const imgurl1 = ref('');
const upload_1 = (param) => {
    const formData = new FormData();
    formData.append('file', param.file);
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            //本地
            imgurl1.value = response.data.data;
            //请求
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl_1.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                });
        }).catch(response => {
            console.log('图片上传失败')
        })
}
//用来储存门店头像图片
const imageUrl_2 = ref('');
instance.get(`/api/common/download?name=${route.query.bgurl}`)
    .then(response => {
        imageUrl_2.value = response.request.responseURL
    }).catch(error => {
        console.log(error);
        ElMessage({
            showClose: true,
            message: '请登录',
            type: 'error',
        })
        router.push('/')
    });
//图片在本地地址
const imgurl2 = ref('');
const upload_2 = (param) => {
    const formData = new FormData();
    formData.append('file', param.file);
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            imgurl2.value = response.data.data;
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl_2.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                });
        }).catch(response => {
            console.log('图片上传失败')
        })
}
//用来储存门店背景图片
const imageUrl_3 = ref('');
instance.get(`/api/common/download?name=${route.query.txurl}`)
    .then(response => {
        imageUrl_3.value = response.request.responseURL
    }).catch(error => {
        console.log(error);
        ElMessage({
            showClose: true,
            message: '请登录',
            type: 'error',
        })
        router.push('/')
    });
console.log(imageUrl_2);
//图片在本地地址
const imgurl3 = ref('');
const upload_3 = (param) => {
    const formData = new FormData();
    formData.append('file', param.file);
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            imgurl3.value = response.data.data;
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl_3.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                });
        }).catch(error => {
            console.log('图片上传失败')
        })
}
console.log(imageUrl_2.value);
// const beforeAvatarUpload = (file) => {
//     // const isJPG = file.type === 'image/jpeg';
//     const isLt2M = file.size / 1024 / 1024 < 2;
//     const timer = ref(null);
//     timer.value = setTimeout(() => {
//         // 执行需要停止的操作
//         alert('响应时间超过规定时间');
//     }, 1000);
//     clearTimeout(timer.value);
//     if (!isLt2M) {
//         alert('上传头像图片大小不能超过 2MB!');
//     }
//     return isJPG && isLt2M;
// };


const name = ref(route.query.name)
// watch(name, (newVal) => {
//     if (newVal.length > 9) {
//         name.value = '';
//         ElMessage({
//             message: '门店名称不能大于9个字，请简写',
//             type: 'warning',
//         })
//     }
// });



//提交并且将数据写入数据库
const submit = () => {
    console.log(latitude.value);
    console.log(longitude.value);
    console.log(storaddress.value);
    ElMessageBox.confirm(
        '是否确认修改该门店',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: '提示',
        }
    )
        .then(() => {
            const value = storeSort.value;
            const isValid = /^([1-9]\d*|0)$/.test(value);
            if (!isValid) {
                ElMessage({
                    message: '请输入非负整数',
                    type: 'error',
                })
                return;
            }
            if (!isValid) {
                ElMessage({
                    message: '请输入非负整数',
                    type: 'error',
                })
                return;
            }
            if (name.value == '') {
                ElMessage({
                    message: '门店名字不能为空',
                    type: 'error',
                })
                return;
            }
            if (imgurl1.value == '') {
                imgurl1.value = route.query.indeximg;
            }

            if (imgurl2.value == '') {
                imgurl2.value = route.query.bgurl;
            }

            if (imgurl3.value == '') {
                imgurl3.value = route.query.txurl;
            }
            if (name.value == '') {
                name.value = route.query.name;
            }
            if (latitude.value == '') {
                latitude.value = route.query.latitude;
            }
            if (longitude.value == '') {
                longitude.value = route.query.longitude;
            }
            const stores = {
                "id": storeId,
                "name": name.value,
                "indexImgUrl": imgurl1.value,
                "storeBgimgUrl": imgurl2.value,
                "storeTximgUrl": imgurl3.value,
                "address": storaddress.value,
                "longitude": longitude.value,
                "latitude": latitude.value,
                "storeSort":storeSort.value
            };
            console.log(stores);
            instance.put('/api/stores', stores)
                .then(response => {
                    console.log(response.data);
                    console.log('修改成功');
                    ElMessage({
                        message: '门店修改成功',
                        type: 'success',
                    })
                    router.push('/storemanagement');
                })
                .catch(error => {
                    console.error(error);
                });
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '已取消修改',
            })
        })
};
const cancels = () => {
    router.push('/storemanagement');
}
</script>

<style>
.card {
    width: 80vw;
    display: flex;
    height: 80vh;
    flex-direction: column;
    overflow-y: auto;
    font-size: 14px;
    margin-top: 10vh;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.card .buttonse {
    width: 20vw;
}

.card .avatar {
    width: 175px;
    height: 117px;
    /*width: 20vw !important;*/
    /*height: 10vh !important;*/
    display: block;
}

#l-map {
    height: 360px;
    width: 30vw !important;
}

#r-result {
    width: 30vw;
}
</style>
