<!-- 查询用户搜索历史 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <el-main class="main">
                <div class="main_item">
                    <div class="feedbackesswa">
                        <div class="feedbackesswahead">
                            <el-input v-model="feedbackesswainput" placeholder="请输入关键词"
                                @keyup.enter="feedbackesswaQuery" class="feedbackesswaSearch"></el-input>
                            <el-button type="primary" @click="feedbackesswaQuery"
                                class="feedbackesswaSearchBtn">查询</el-button>
                        </div>
                        <div class="feedbackesswabody">
                            <el-table :data="history" v-loading="loading" element-loading-text="加载中，请稍后..."
                                style="width: 100vw" height="70vh" border>
                                <el-table-column prop="userId" label="用户id" width="180" />
                                <!-- <el-table-column prop="userPhone" label="用户电话" width="150" /> -->
                                <el-table-column prop="userName" label="用户名" width="150" />
                                <el-table-column prop="keyword" label="搜索关键词" width="150" />
                                <el-table-column prop="productType" label="搜索类型" width="150" />
                                <el-table-column prop="searchCount" label="搜索次数" width="150" />
                                <!-- <el-table-column prop="userEmail" label="用户邮箱" width="180" /> -->
                                <el-table-column prop="createTime" label="开始查询时间" width="180" />
                                <el-table-column prop="updateTime" label="最后查询时间" />
                            </el-table>
                        </div>
                        <div class="feedbackesswafoot">
                            <div class="demo-pagination-blocks">
                                <el-pagination  v-model:current-page="currentPage4" v-model:page-size="pageSize4" @size-change="handleSizeChanges" @current-change="handleCurrentChanges"
                                    :current-page="currentPage4" :page-sizes="[10, 20, 30, 40]" :page-size="10"
                                    layout="total, sizes, prev, pager, next, jumper" :total="tatol">
                                </el-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script setup>
import Menu from '../navbar/Menu.vue'
import LeftNav from '../navbar/LeftNav.vue'
import TopNav from '../navbar/TopNav.vue'
import { ref, toRaw, reactive } from 'vue'
import instance from '../network/storester.js'
import { useRouter } from 'vue-router'

const feedbackesswainput = ref('')
const history = ref([])
const historys = ref(null)
const tatol = ref(0);
const loading = ref(true)
const router = useRouter();
instance.get('/api/searchKeywords')
    .then(response => {
        tatol.value = response.data.data.length
        console.log(tatol.value);
        loading.value = false
    }).catch(error => {
        console.log(error);
        ElMessage({
            message: '请重新登录',
            type: 'error',
        })
        router.push('./')
    })
instance.get(`/api/searchKeywords/paginationSearch?&pageNum=${1}&pageSize=${10}`)
    .then(response => {
        historys.value = response.data
        history.value = toRaw(historys.value)
        console.log(history.value);
        loading.value = false
    }).catch(error => {
        console.log(error);
        ElMessage({
            message: '请重新登录',
            type: 'error',
        })
        router.push('./')
    })
const feedbackesswaQuery = () => {
    console.log(feedbackesswainput.value);
    loading.value = true
    instance.get(`/api/searchKeywords/getKeyword?pivotal=${feedbackesswainput.value}`)
        .then(response => {
            historys.value = response.data
            history.value = toRaw(historys.value)
            console.log(history.value);
            loading.value = false
        }).catch(error => {
            console.log(error);
            ElMessage({
                message: '请重新登录',
                type: 'error',
            })
            router.push('./')
        })
}
const page = reactive({ pageNum: 1, pageSize: 10 });
const handleSizeChanges = (val) => {
    page.pageSize = val;
    console.log(val);
    siftse();
};
//当前页改变时触发
const handleCurrentChanges = (val) => {
    page.pageNum = val;
    console.log(val);
    siftse();
};
const siftse = () => {
    loading.value = true
    console.log(page.pageSize);
    console.log(page.pageNum);
    instance.get(`/api/wxuser/paginationWxUser?&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
        .then(response => {
            historys.value = response.data
            history.value = toRaw(historys.value)
            console.log(history.value);
            loading.value = false
        }).catch(error => {
            console.log(error);
            ElMessage({
                message: '请重新登录',
                type: 'error',
            })
            router.push('./')
        })
}
</script>

<style>


.container {
    position: fixed;
    top: 0;
    left: 15vw;
}



.main {
    position: fixed;
    top: 10vh;
    left: 15vw;
    width: 85vw;
    height: 90vh;
}

.feedbackesswa {
    position: fixed;
    width: 85vw;
    top: 10vh;
    left: 11vw;
}

/* .feedbackesswahead{

} */
.feedbackesswaSearch {
    width: 15vw;
    left: 1vw;
}

.feedbackesswaSearchBtn {
    width: 5vw;
    height: 5vh;
    border: none;
    border-radius: 5px;
    margin-left: 1vw;
}

.feedbackesswafoot {
    width: 100vw;
    height: 50vh;
}

.feedbackesswafoot .demo-pagination-blocks {
    position: fixed;
    width: 100vw;
    top: 94vh;
    height: 10vh;
    left: 25%;
    /* background-color: red; */
}
</style>