<!-- 添加门店 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <el-main class="main">
                <div class="main_item">
                    <div class="add_store">
                        <el-card class="cards">
                            <el-row :gutter="100">
                                <el-col :span="7">
                                    新增门店
                                </el-col>
                            </el-row>
                            <el-row :gutter="100">
                                <el-col :span="7">
                                    <!-- ref绑定到上面的属性,用于校验验证表单是否正确输入,model绑定的是整个表单数据,rules绑定的是最外层的总体的那个规则对象 -->
                                    <el-from ref="baseForm" :model="loginForm" :rules="loginrules">
                                        <!--label为显示的内容,prop为规则对象内的一个属性,v-model与表单数据双向绑定-->
                                        <el-form-item label="门店名称" prop="storename">
                                            <el-input v-model="name" type="text" :disabled="name.length > 8" />
                                        </el-form-item>
                                        <!-- <div id="r-result"><span class="men">门店地址</span><span class="meninput"><input
                                                    type="text" id="suggestId" size="20" v-model="storaddress" /></span>
                                        </div>
                                        <div id="searchResultPanel"
                                            style="border:1px solid #C0C0C0;width:15vw;height:auto; display:none;">
                                        </div>
                                        <div id="l-map"></div> -->
                                        <el-form-item label="门店地址" prop="storename">
                                            <el-input v-model="storaddress" />
                                        </el-form-item>
                                        <el-form-item label="门店经度" prop="storename">
                                            <el-input v-model="longitude" />
                                        </el-form-item>
                                        <el-form-item label="门店纬度" prop="storename">
                                            <el-input v-model="latitude" />
                                        </el-form-item>
                                        <el-form-item label="门店排序" prop="storename">
                                            <el-input v-model="storeSort" />
                                        </el-form-item>

                                    </el-from>

                                    <!-- 上传一张图片用来作为商店首页图片 -->
                                    <p>
                                        上传一张图片用来作为首页图片：(建议上传背景图片比例为175:117)
                                    </p>
                                    <el-upload class="avatar-uploader" action="" :http-request="upload_1"
                                        :show-file-list="false" :on-success="handleAvatarSuccess"
                                        :before-upload="beforeAvatarUpload">
                                        <img v-if="imageUrl" :src="imageUrl" class="avatar">
                                        <el-icon v-else class="avatar-uploader-icon">
                                            <Plus />
                                        </el-icon>
                                    </el-upload>
                                    <!-- 上传一张图片用来作为门店头像 -->
                                    <p>
                                        上传一张图片用来作为门店头像：(建议上传背景图片比例为39：10)
                                    </p>
                                    <el-upload class="avatar-uploader" action="" :http-request="upload_2"
                                        :show-file-list="false" :on-success="handleAvatarSuccess_1"
                                        :before-upload="beforeAvatarUpload">
                                        <img v-if="imageUrl_1" :src="imageUrl_1" class="avatar">
                                        <el-icon v-else class="avatar-uploader-icon">
                                            <Plus />

                                        </el-icon>
                                    </el-upload>

                                    <!-- 上传一张图片用来作为门店背景图片 -->
                                    <p>
                                        上传一张图片用来作为门店背景图片：(建议上传头像图片比例为1:1)
                                    </p>
                                    <el-upload class="avatar-uploader" action="" :http-request="upload_3"
                                        :show-file-list="false" :on-success="handleAvatarSuccess_2"
                                        :before-upload="beforeAvatarUpload">
                                        <img v-if="imageUrl_2" :src="imageUrl_2" class="avatar">
                                        <el-icon v-else class="avatar-uploader-icon">
                                            <Plus />
                                        </el-icon>
                                    </el-upload>

                                    <el-button type="primary" @click="submit">提交</el-button>
                                    <el-button type="danger" @click="goBack">取消</el-button>
                                </el-col>
                            </el-row>
                        </el-card>
                    </div>
                </div>
            </el-main>
        </el-container>
    </el-container>

</template>

<script setup type="text/javascript">
import Menu from '../navbar/Menu.vue'
import LeftNav from '../navbar/LeftNav.vue'
import TopNav from '../navbar/TopNav.vue'
import { reactive, ref, onMounted, onBeforeUnmount } from 'vue'
import { FromInstance, FormRules } from 'element-plus'
import { ElMessage, ElMessageBox } from 'element-plus'
import { Plus } from '@element-plus/icons-vue'
import { UploadProps } from 'element-plus'
import axios from 'axios'
import { useRouter } from 'vue-router'
import { watch } from 'vue';
import instance from '../network/storester.js'

const router = useRouter();
const storaddress = ref('');
const longitude = ref('');
const latitude = ref('');
const storeSort = ref('')

// function G(id) {
//     return document.getElementById(id);
// }

// let map;
// let ac;
// let BMap = null;
// onMounted(() => {
//     BMap = window.BMap;
//     console.log(BMap);
//     map = new BMap.Map("l-map");
//     map.centerAndZoom("北京", 12);

//     ac = new BMap.Autocomplete({
//         "input": "suggestId",
//         "location": map
//     });

//     ac.addEventListener("onhighlight", onHighlight);
//     ac.addEventListener("onconfirm", onConfirm);
//     var point = new BMap.Point(116.407, 39.915); // 创建点坐标
//     map.centerAndZoom(point, 15); // 初始化地图，设置中心点坐标和地图级别
//     map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放

//     // 创建标注点
//     var marker = new BMap.Marker(point); // 创建标注
//     map.addOverlay(marker); // 将标注添加到地图中

//     // 定位当前浏览器位置
//     var geolocation = new BMap.Geolocation(); // 创建定位实例
//     console.log(geolocation);
//     geolocation.getCurrentPosition(function (r) {
//         if (this.getStatus() == BMAP_STATUS_SUCCESS) {
//             var mk = new BMap.Marker(r.point); // 根据定位结果创建标注
//             map.addOverlay(mk); // 将标注添加到地图中
//             map.panTo(r.point); // 平移地图到定位结果的位置
//             // alert('您的位置：' + r.point.lng + ',' + r.point.lat); // 弹出定位结果的经纬度信息
//         } else {
//             // alert('failed' + this.getStatus()); // 定位失败时弹出错误状态
//         }
//     });

//     // 点击地图获取经度和维度
//     map.addEventListener('click', handleClick);
//     function handleClick(e) {
//         // alert('点击的经纬度：' + e.point.lng + ', ' + e.point.lat);
//         longitude.value = e.point.lng
//         latitude.value = e.point.lat
//         console.log(e);
//         var pt = e.point;
//         console.log(longitude.value);
//         console.log(latitude.value)
//         var geoc = new BMap.Geocoder();
//         const marker = new BMap.Marker(new BMap.Point(longitude.value, latitude.value));
//         map.addOverlay(marker);
//         console.log(map.addOverlay(marker));
//         geoc.getLocation(pt, (rs) => {
//             const addComp = rs.addressComponents;
//             storaddress.value = addComp.province + addComp.city + addComp.district + addComp.street + addComp.streetNumber;
//             console.log(storaddress.value);
//         });
//     }


// });

// onBeforeUnmount(() => {
//     if (ac) {
//         ac.removeEventListener("onhighlight", onHighlight);
//         ac.removeEventListener("onconfirm", onConfirm);
//     }
// });

// function onHighlight(e) {
//     let str = "";
//     let _value = e.fromitem.value;
//     let value = "";
//     if (e.fromitem.index > -1) {
//         value = _value.province + _value.city + _value.district + _value.street + _value.business;
//     }
//     str = "FromItem<br />index = " + e.fromitem.index + "<br />value = " + value;

//     value = "";
//     if (e.toitem.index > -1) {
//         _value = e.toitem.value;
//         value = _value.province + _value.city + _value.district + _value.street + _value.business;
//     }
//     str += "<br />ToItem<br />index = " + e.toitem.index + "<br />value = " + value;
//     G("searchResultPanel").innerHTML = str;
// }

// function onConfirm(e) {
//     let _value = e.item.value;
//     let myValue = _value.province + _value.city + _value.district + _value.street + _value.business;
//     G("searchResultPanel").innerHTML = "onconfirm<br />index = " + e.item.index + "<br />myValue = " + myValue;

//     setPlace(myValue);
//     storaddress.value = e.item.value.province + e.item.value.city + e.item.value.district + e.item.value.business + e.item.value.streetNumber;
//     console.log(storaddress.value);
// }

// function setPlace(myValue) {
//     if (map) {
//         map.clearOverlays();
//         function myFun() {
//             var pp = local.getResults().getPoi(0).point;
//             map.centerAndZoom(pp, 18);
//             map.addOverlay(new BMap.Marker(pp));
//             console.log(pp);
//             longitude.value = pp.lng
//             latitude.value = pp.lat
//         }

//         var local = new BMap.LocalSearch(map, {
//             onSearchComplete: myFun

//         });
//         local.search(myValue);

//         console.log(storaddress.value)
//     }
// }


const name = ref('');

console.log(name);
console.log(storaddress);
watch(name, (newVal) => {
    if (newVal.length > 9) {
        name.value = '';
        ElMessage({
            message: '门店名称不能大于9个字，请简写',
            type: 'warning',
        })
    }
});
const loginrules = ref({
    username: [
        { required: true, message: "信息不能为空", trigger: "blur" },
        { min: 3, max: 5, message: '请输入长度为三到五', trigger: 'blur' },
    ],
    storename: [
        { required: true, message: "信息不能为空", trigger: "blur" },
        { min: 3, max: 5, message: '请输入长度为三到五', trigger: 'blur' },
    ],
})
//这个baseForm为获取表单的对象ref值,用于对表单规则是否通过进行校验,baseForm绑定到form标签上
const baseForm = ref(null);
const submitForm = () => {
    //对表单元素进行校验
    baseForm.value.validate((valid) => {
        if (!valid) {
            //校验失败
            console.log("请调整数据后再请求");
            return false;
        }
        // 校验成功
        console.log("123123", 123123);
    });
};

//获取到图片的url地址
//用来储存门店首页图片
const imageUrl = ref('');
//图片在本地地址
const imgurl1 = ref('');
const upload_1 = (param) => {
    const formData = new FormData();
    formData.append('file', param.file);
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            //本地
            imgurl1.value = response.data.data;
            //请求
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                });
        }).catch(response => {
            console.log('图片上传失败')
        })
}
//用来储存门店头像图片
const imageUrl_1 = ref('');
//图片在本地地址
const imgurl2 = ref('');
const upload_2 = (param) => {
    const formData = new FormData();
    formData.append('file', param.file);
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            imgurl2.value = response.data.data;
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl_1.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                });
        }).catch(response => {
            console.log('图片上传失败')
        })
}
//用来储存门店背景图片
const imageUrl_2 = ref('');
//图片在本地地址
const imgurl3 = ref('');
const upload_3 = (param) => {
    const formData = new FormData();
    formData.append('file', param.file);
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            imgurl3.value = response.data.data;
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl_2.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                });
        }).catch(response => {
            console.log('图片上传失败')
        })
}
const beforeAvatarUpload = (file) => {
    const isJPG = file.type === 'image/jpeg';
    const isLt2M = file.size / 1024 / 1024 < 2;
    const timer = ref(null);
    timer.value = setTimeout(() => {
        // 执行需要停止的操作
        alert('响应时间超过规定时间');
    }, 1000);
    clearTimeout(timer.value);

    if (!isJPG) {
        alert('上传头像图片只能是 JPG 格式!');
    }
    if (!isLt2M) {
        alert('上传头像图片大小不能超过 2MB!');
    }
    return isJPG && isLt2M;
};

//提交并且将数据写入数据库
const submit = () => {
    ElMessageBox.confirm(
        '是否确认添加该门店',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: '提示',
        }
    )
        .then(() => {
            // console.log(loginForm.value.storename);
            const value = storeSort.value;
            const isValid = /^([1-9]\d*|0)$/.test(value);
            if (!isValid) {
                ElMessage({
                    message: '请输入非负整数',
                    type: 'error',
                })
                return;
            }
            if (name.value == '') {
                ElMessage({
                    message: '门店名字不能为空',
                    type: 'error',
                })
                return;
            }
            if (imgurl1.value == '') {
                ElMessage({
                    message: '门店首页图片不能为空',
                    type: 'error',
                })
                return;
            }
            if (imgurl2.value == '') {
                ElMessage({
                    message: '门店背景图片不能为空',
                    type: 'error',
                })
                return;
            }
            if (imgurl3.value == '') {
                ElMessage({
                    message: '门店头像图片不能为空',
                    type: 'error',
                })
                return;
            }
            const url = "E:/uploadImg";
            const stores = {
                "name": name.value,
                "indexImgUrl": imgurl1.value,
                "storeBgimgUrl": imgurl2.value,
                "storeTximgUrl": imgurl3.value,
                "address": storaddress.value,
                "latitude": latitude.value,
                "longitude": longitude.value,
                "storeSort":storeSort.value

                // "name": "惠普",
                // "indexImgUrl": "images/imges/07.jpg",
                // "storeBgimgUrl": "images/imges/07.jpg",
                // "storeTximgUrl": "images/imges/07.jpg",
                // "address": "广东省 广州市珠江新城"
            };
            console.log(stores);
            // 用axios将数据传到后端
            // http://localhost:8081/api/stores
            instance.post('/api/stores', JSON.stringify(stores), { headers: { 'Content-Type': 'application/json' } })
                .then(response => {
                    console.log(response.data);
                    console.log('添加成功');
                    ElMessage({
                        message: '门店添加成功',
                        type: 'success',
                    })
                    router.push('/storemanagement');
                })
                .catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('./')
                })
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '已取消添加',
            })
        })
};
const goBack = () => {
    router.push('/storemanagement');
}

</script>

<style>
/* 这个样式可以去除地图底部logo*/
.anchorBL {
    display: none;
}

/*地图宽高*/
.mapp {
    width: 600px;
    height: 500px;

}

.container {
    position: fixed;
    top: 0;
    left: 15vw;
}

.el-row {
    /* width: 30vw; */
}

.main {
    position: fixed;
    top: 10vh;
    left: 15vw;
    width: 85vw;
    height: 90vh;
}

.cards {
    width: 90%;
    display: flex;
    height: 80vh;
    flex-direction: column;
    overflow-y: auto;
    font-size: 14px;
    margin-top: -20vh;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    /*width: 178px;*/
    /*height: 178px;*/
    width: 175px;
    height: 117px;
    display: block;
}

.add_store {
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -11vw;
}

#l-map {
    height: 360px;
    width: 30vw !important;
}

#r-result {
    width: 30vw;
}

.BMap_mask {
    width: 50vw !important;
    height: 20vh !important;
}

.men {
    display: inline-flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex: 0 0 auto;
    font-size: var(--el-form-label-font-size);
    color: var(--el-text-color-regular);
    height: 32px;
    line-height: 32px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
}

.meninput {
    display: inline-flex;
    flex-grow: 1;
    width: 10vw;
    align-items: center;
    justify-content: center;
    padding: 1px 11px;
    background-color: var(--el-input-bg-color, var(--el-fill-color-blank));
    background-image: none;
    border-radius: var(--el-input-border-radius, var(--el-border-radius-base));
    cursor: text;
    transition: var(--el-transition-box-shadow);
    transform: translate3d(0, 0, 0);
    box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color)) inset;
}

.meninput input {
    --el-input-inner-height: calc(var(--el-input-height, 32px) - 2px);
    width: 100%;
    flex-grow: 1;
    -webkit-appearance: none;
    color: var(--el-input-text-color, var(--el-text-color-regular));
    font-size: inherit;
    height: var(--el-input-inner-height);
    line-height: var(--el-input-inner-height);
    padding: 0;
    outline: 0;
    border: none;
    background: 0 0;
    box-sizing: border-box;
}
</style>
