<template>
    <el-container class="Wrapper">
        <el-container class="containere">
            <el-main>
                <div class="addition">
                    <el-form :label-position="label - postion">
                        <p>
                            <span class="name"><i style="color: red;">*</i>商品名称：</span>
                            <span class="input"><el-input v-model="input" placeholder="请输入商品名称" clearable /></span>
                        </p>
                        <p v-if="storeLV">
                            <span class="name"><i style="color: red;">*</i>商品门店：</span>
                            <span>
                                <!-- <el-select v-model="value" clearable placeholder="请选择商品的门店" @change="addsd">
                                    <el-option v-for="item in store" :key="item.value" :label="item.name"
                                        :value="item.id" />
                                </el-select> -->
                                <el-select v-model="value1" multiple placeholder="请选择商品的门店" @change="addsd">
                                    <el-option v-for="item in store" :key="item.value" :label="item.name"
                                        :value="item.id" />
                                </el-select>
                            </span>
                        </p>
                        <p v-for="(item, index) in value1" :key="index" v-if="states === '0'">
                            <span class="name" v-if="value1 != ''"><i style="color: red;">*</i>商品分类：</span>
                            <span>
                                <el-select v-if="value1 != ''" v-model="value_1[index]" clearable placeholder="请选择商品的分类"
                                    @change="">
                                    <el-option v-for="item in managementes[index]" :key="item.value" :label="item.name"
                                        :value="item.id" />
                                </el-select>
                            </span>
                        </p>
                        <p v-else>
                            <span class="name"><i style="color: red;">*</i>商品分类：</span>
                            <span>
                                <el-select v-model="value_2" clearable placeholder="请选择商品的分类" @change="adds">
                                    <el-option v-for="item in managementes" :key="item.value" :label="item.name"
                                        :value="item.id" />
                                </el-select>
                            </span>
                        </p>
                        <p>
                            <span class="name"><i style="color: red;">*</i>商品状态：</span>
                            <span> <el-select v-model="state" clearable placeholder="请选择商品的状态" @change="addsde">
                                    <el-option v-for="item in managementesa" :key="item.value" :label="item.label"
                                        :value="item.value" />
                                </el-select></span>
                        </p>
                        <p>
                            <span class="name"><i style="color: red;">*</i>商品库存：</span>
                            <span class="inputs"><el-input v-model="stock" placeholder="请输入商品库存" clearable
                                    v-on:keyup="checkNus" /></span>
                            <span style="color: red; font-size: 12px;" v-if="!isNus">请输入数字</span>
                        </p>
                        <p>
                            <span class="name"><i style="color: red;">*</i>商品价格:</span>
                            <span class="inputs"><el-input v-model="input_2" placeholder="请输入商品价格" clearable
                                    v-on:keyup="checkNum" /></span>
                            <span style="color: red; font-size: 12px;" v-if="!isNum">请输入数字</span>
                        </p>
                        <!-- <p>
                            <span class="name"><i style="color: red;">*</i>商品佣金:</span>
                            <span class="input"><el-input v-model="input_3" placeholder="请输入商品佣金" clearable
                                    v-on:keyup="checkNun" /></span>
                            <span style="color: red; font-size: 12px;" v-if="!isNun">请输入数字</span>
                        </p> -->
                        <!-- <p>
                            <span class="name">商品简介:</span>
                            <span class="input"> <el-input v-model="textarea" maxlength="100" placeholder="请输入商品的简介"
                                    show-word-limit type="textarea" /></span>
                        </p> -->
                        <p>
                            <span class="name"><i style="color: red;">*</i>商品封面图</span>
                            <span class="avatar1">
                                <el-upload class="avatar-uploader" action="" :http-request="upload_21"
                                    :show-file-list="false" :on-success="handleAvatarSuccess2"
                                    :before-upload="beforeAvatarUpload" list-type="picture-card">
                                    <img :key="imageUrl21" v-if="imageUrl21 != '' && imageUrl21 != undefined"
                                        :src="imageUrl21" class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon">+</i>
                                </el-upload>
                                <img v-if="imageUrl21 != '' && imageUrl21 != undefined" @click="deletes21"
                                    class="delete" src="../assets/delete.png">
                            </span>
                            <span class="avatar1">
                                <el-upload v-if="imageUrl21 != '' && imageUrl21 != undefined" class="avatar-uploader"
                                    action="" :http-request="upload_22" :show-file-list="false"
                                    :on-success="handleAvatarSuccess2" :before-upload="beforeAvatarUpload"
                                    list-type="picture-card">
                                    <img :key="imageUrl22" v-if="imageUrl22 != '' && imageUrl22 != undefined"
                                        :src="imageUrl22" class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon">+</i>
                                </el-upload>
                                <img v-if="imageUrl22 != '' && imageUrl22 != undefined" @click="deletes22"
                                    class="delete" src="../assets/delete.png">
                            </span>
                            <span class="avatar1">
                                <el-upload v-if="imageUrl22 != '' && imageUrl22 != undefined" class="avatar-uploader"
                                    action="" :http-request="upload_23" :show-file-list="false"
                                    :on-success="handleAvatarSuccess2" :before-upload="beforeAvatarUpload"
                                    list-type="picture-card">
                                    <img :key="imageUrl23" v-if="imageUrl23 != '' && imageUrl23 != undefined"
                                        :src="imageUrl23" class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon">+</i>
                                </el-upload>
                                <img v-if="imageUrl23 != '' && imageUrl23 != undefined" @click="deletes23"
                                    class="delete" src="../assets/delete.png">
                            </span>
                            <span class="avatar1">
                                <el-upload v-if="imageUrl23 != '' && imageUrl23 != undefined" class="avatar-uploader"
                                    action="" :http-request="upload_24" :show-file-list="false"
                                    :on-success="handleAvatarSuccess2" :before-upload="beforeAvatarUpload"
                                    list-type="picture-card">
                                    <img :key="imageUrl24" v-if="imageUrl24 != '' && imageUrl24 != undefined"
                                        :src="imageUrl24" class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon">+</i>
                                </el-upload>
                                <img v-if="imageUrl24 != '' && imageUrl24 != undefined" @click="deletes24"
                                    class="delete" src="../assets/delete.png">
                            </span>
                            <span class="avatar1">
                                <el-upload v-if="imageUrl24 != '' && imageUrl24 != undefined" class="avatar-uploader"
                                    action="" :http-request="upload_25" :show-file-list="false"
                                    :on-success="handleAvatarSuccess2" :before-upload="beforeAvatarUpload"
                                    list-type="picture-card">
                                    <img :key="imageUrl25" v-if="imageUrl25 != '' && imageUrl25 != undefined"
                                        :src="imageUrl25" class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon">+</i>
                                </el-upload>
                                <img v-if="imageUrl25 != '' && imageUrl25 != undefined" @click="deletes25"
                                    class="delete" src="../assets/delete.png">
                            </span>
                        </p>
                        <p>
                            <span class="name"><i style="color: red;">*</i>商品详情图</span>
                        <div class="xian">
                            <span class="yi avatar1">
                                <el-upload class="avatar-uploader" action="" :http-request="upload_31"
                                    :show-file-list="false" :on-success="handleAvatarSuccess"
                                    :before-upload="beforeAvatarUpload" list-type="picture-card">
                                    <img :key="imageUrl31" v-if="imageUrl31 != '' && imageUrl31 != undefined"
                                        :src="imageUrl31" class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon">+</i>
                                </el-upload>
                                <img v-if="imageUrl31 != '' && imageUrl31 != undefined" @click="deletes31"
                                    class="delete" src="../assets/delete.png">
                            </span>
                            <span class="avatar1">
                                <el-upload v-if="imageUrl31 != '' && imageUrl31 != undefined" class="avatar-uploader"
                                    action="" :http-request="upload_32" :show-file-list="false"
                                    :on-success="handleAvatarSuccess1" :before-upload="beforeAvatarUpload"
                                    list-type="picture-card">
                                    <img :key="imageUrl32" v-if="imageUrl32 != '' && imageUrl32 != undefined"
                                        :src="imageUrl32" class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon">+</i>
                                </el-upload>
                                <img v-if="imageUrl32 != '' && imageUrl32 != undefined" @click="deletes32"
                                    class="delete" src="../assets/delete.png">
                            </span>
                            <span class="avatar1">
                                <el-upload v-if="imageUrl32 != '' && imageUrl32 != undefined" class="avatar-uploader"
                                    action="" :http-request="upload_33" :show-file-list="false"
                                    :on-success="handleAvatarSuccess2" :before-upload="beforeAvatarUpload"
                                    list-type="picture-card">
                                    <img :key="imageUrl33" v-if="imageUrl33 != '' && imageUrl33 != undefined"
                                        :src="imageUrl33" class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon">+</i>
                                </el-upload>
                                <img v-if="imageUrl33 != '' && imageUrl33 != undefined" @click="deletes33"
                                    class="delete" src="../assets/delete.png">
                            </span>
                            <span class="avatar1">
                                <el-upload v-if="imageUrl33 != '' && imageUrl33 != undefined" class="avatar-uploader"
                                    action="" :http-request="upload_34" :show-file-list="false"
                                    :on-success="handleAvatarSuccess3" :before-upload="beforeAvatarUpload"
                                    list-type="picture-card">
                                    <img :key="imageUrl34" v-if="imageUrl34 != '' && imageUrl34 != undefined"
                                        :src="imageUrl34" class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon">+</i>
                                </el-upload>
                                <img v-if="imageUrl34 != '' && imageUrl34 != undefined" @click="deletes34"
                                    class="delete" src="../assets/delete.png">
                            </span>
                            <span class="avatar1">
                                <el-upload v-if="imageUrl34 != '' && imageUrl34 != undefined" class="avatar-uploader"
                                    action="" :http-request="upload_35" :show-file-list="false"
                                    :on-success="handleAvatarSuccess4" :before-upload="beforeAvatarUpload"
                                    list-type="picture-card">
                                    <img v-if="imageUrl35 != '' && imageUrl35 != undefined" :src="imageUrl35"
                                        class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon">+</i>
                                </el-upload>
                                <img v-if="imageUrl35 != '' && imageUrl35 != undefined" @click="deletes35"
                                    class="delete" src="../assets/delete.png">
                            </span>
                            <span class="avatar1">
                                <el-upload v-if="imageUrl35 != '' && imageUrl35 != undefined" class="avatar-uploader"
                                    action="" :http-request="upload_36" :show-file-list="false"
                                    :on-success="handleAvatarSuccess5" :before-upload="beforeAvatarUpload"
                                    list-type="picture-card">
                                    <img v-if="imageUrl36 != '' && imageUrl36 != undefined" :src="imageUrl36"
                                        class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon">+</i>
                                </el-upload>
                                <img v-if="imageUrl36 != '' && imageUrl36 != undefined" @click="deletes36"
                                    class="delete" src="../assets/delete.png">
                            </span>
                            <span class="avatar1">
                                <el-upload v-if="imageUrl36 != '' && imageUrl36 != undefined" class="avatar-uploader"
                                    action="" :http-request="upload_37" :show-file-list="false"
                                    :on-success="handleAvatarSuccess6" :before-upload="beforeAvatarUpload"
                                    list-type="picture-card">
                                    <img v-if="imageUrl37 != '' && imageUrl37 != undefined" :src="imageUrl37"
                                        class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon">+</i>
                                </el-upload>
                                <img v-if="imageUrl37 != '' && imageUrl37 != undefined" @click="deletes37"
                                    class="delete" src="../assets/delete.png">
                            </span>
                            <span class="avatar1">
                                <el-upload v-if="imageUrl37 != '' && imageUrl37 != undefined" class="avatar-uploader"
                                    action="" :http-request="upload_38" :show-file-list="false"
                                    :on-success="handleAvatarSuccess7" :before-upload="beforeAvatarUpload"
                                    list-type="picture-card">
                                    <img v-if="imageUrl38 !== '' && imageUrl38 != undefined" :src="imageUrl38"
                                        class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon">+</i>
                                </el-upload>
                                <img v-if="imageUrl38 != '' && imageUrl38 != undefined" @click="deletes38"
                                    class="delete" src="../assets/delete.png">
                            </span>
                            <span class="avatar1">
                                <el-upload v-if="imageUrl38 !== '' && imageUrl38 != undefined" class="avatar-uploader"
                                    action="" :http-request="upload_39" :show-file-list="false"
                                    :on-success="handleAvatarSuccess8" :before-upload="beforeAvatarUpload"
                                    list-type="picture-card">
                                    <img v-if="imageUrl39 !== '' && imageUrl39 != undefined" :src="imageUrl39"
                                        class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon">+</i>
                                </el-upload>
                                <img v-if="imageUrl39 != '' && imageUrl39 != undefined" @click="deletes39"
                                    class="delete" src="../assets/delete.png">
                            </span>
                            <span class="avatar1">
                                <el-upload v-if="imageUrl39 !== '' && imageUrl39 != undefined" class="avatar-uploader"
                                    action="" :http-request="upload_40" :show-file-list="false"
                                    :on-success="handleAvatarSuccess9" :before-upload="beforeAvatarUpload"
                                    list-type="picture-card">
                                    <img v-if="imageUrl40 != '' && imageUrl40 != undefined" :src="imageUrl40"
                                        class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon">+</i>
                                </el-upload>
                                <img v-if="imageUrl40 != '' && imageUrl40 != undefined" @click="deletes40"
                                    class="delete" src="../assets/delete.png">
                            </span>
                        </div>
                        </p>
                        <p>
                            <span class="name">商品详情视频</span>
                            <span class="avatar1">
                                <el-upload class="upload-demo" action="" :http-request="upload_41"
                                    :before-upload="beforeAvatarUploads" multiple :limit="3">
                                    <video v-if="videoUrl !== '' && videoUrl != undefined" :src="videoUrl" controls
                                        autoplay></video>
                                    <el-button v-else type="primary">点击上传</el-button>
                                </el-upload>
                                <img v-if="videoUrl != '' && videoUrl != undefined" @click="videoUrl1" class="delete"
                                    src="../assets/delete.png">
                            </span>
                        </p>
                        <!-- <p>
                            <span class="name"><i style="color: red;"></i>运费模板:</span>
                            <span> <el-select v-model="value_2" clearable placeholder="请选择" @change="">
                                    <el-option v-for="item in express" :key="item.value" :label="item.label"
                                        :value="item.name" />
                                </el-select></span>
                        </p>
                        <p>
                            <span class="name"><i style="color: red;"></i>关联用户标签:</span>
                            <span> <el-select v-model="value_3" clearable placeholder="请选择" @change="">
                                    <el-option v-for="item in Alabel" :key="item.value" :label="item.label"
                                        :value="item.name" />
                                </el-select></span>
                        </p> -->
                        <p class="button">
                            <el-button type="primary" @click="add" @change="addads">保存</el-button>
                            <el-button @click="cancel">取消</el-button>
                        </p>
                    </el-form>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script setup>
import LeftNav from '../navbar/LeftNav.vue'
import TopNav from '../navbar/TopNav.vue'
import { ref, toRaw } from 'vue';
import { Plus } from 'element-plus';
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { Edit } from 'vxe-table';
import { useRouter, useRoute } from 'vue-router';

const value1 = ref([])
const router = useRouter();
const state = ref('')
const input = ref('')
const stock = ref('')
const sourcea = ref('')
const input_2 = ref('')
const value = ref('')
const value_1 = ref([])
const value_2 = ref('')
const value_3 = ref('')
const input_3 = ref('')
const store = ref([])
const express = ref([])
const Alabel = ref([])
const storeLV = ref(false);
const textarea = ref('')
const managementes = ref([]);
const searches = ref('')
const imageUrl21 = ref('')
const imageUrl22 = ref('')
const imageUrl23 = ref('')
const imageUrl24 = ref('')
const imageUrl25 = ref('')
const imageUrl31 = ref('')
const imageUrl32 = ref('')
const imageUrl33 = ref('')
const imageUrl34 = ref('')
const imageUrl35 = ref('')
const imageUrl36 = ref('')
const imageUrl37 = ref('')
const imageUrl38 = ref('')
const imageUrl39 = ref('')
const imageUrl40 = ref('')
const imageUrl41 = ref('')
const imgurl21 = ref('')
const imgurl22 = ref('')
const imgurl23 = ref('')
const imgurl24 = ref('')
const imgurl25 = ref('')
const imgurl31 = ref('')
const imgurl32 = ref('')
const imgurl33 = ref('')
const imgurl34 = ref('')
const imgurl35 = ref('')
const imgurl36 = ref('')
const imgurl37 = ref('')
const imgurl38 = ref('')
const imgurl39 = ref('')
const imgurl40 = ref('')
const imgurl41 = ref('')
const namea = ref('')
const videoUrl = ref('')
const nulls = ref(undefined)
const isNum = ref(true)
const isNun = ref(true)
const isNus = ref(true)
const reg = /^\d{0,9}(\.\d{0,9})?$/
const checkNum = () => {
    // 匹配数字的正则表达式
    if (!reg.test(input_2.value)) {
        isNum.value = false
        console.log('niu');
    } else {
        isNum.value = true
        console.log('ni');
    }
}
const states = localStorage.getItem('permissionsStatus')
const managementesa = [
    {
        value: '1',
        label: '上架',
    },
    {
        value: '0',
        label: '下架',
    }
]

const addsde = (value) => {
    state.value = value
    console.log(state.value);
}
const adds = () => {

}
const checkNus = () => {
    // 匹配数字的正则表达式
    if (!reg.test(stock.value)) {
        isNus.value = false
        console.log('niu');
    } else {
        isNus.value = true
        console.log('ni');
    }
}
const checkNun = () => {
    // 匹配数字的正则表达式
    if (!reg.test(input_3.value)) {
        isNun.value = false
    } else {
        isNun.value = true
    }
}


const deletes21 = () => {
    imageUrl21.value = imageUrl22.value
    imageUrl22.value = imageUrl23.value
    imageUrl23.value = imageUrl24.value
    imageUrl24.value = imageUrl25.value
    imageUrl25.value = nulls.value
    imgurl21.value = imgurl22.value
    imgurl22.value = imgurl23.value
    imgurl23.value = imgurl34.value
    imgurl25.value = nulls.value
    imgurl24.value = imgurl25.value
    console.log(imageUrl25.value);
}
const deletes22 = () => {
    imageUrl22.value = imageUrl23.value
    imageUrl23.value = imageUrl24.value
    imageUrl24.value = imageUrl25.value
    imageUrl25.value = nulls.value
    imgurl22.value = imgurl23.value
    imgurl23.value = imgurl34.value
    imgurl25.value = nulls.value
    imgurl24.value = imgurl25.value
    console.log(imageUrl25.value);
}
const deletes23 = () => {
    imageUrl23.value = imageUrl24.value
    imageUrl24.value = imageUrl25.value
    imageUrl25.value = nulls.value
    imgurl23.value = imgurl34.value
    imgurl25.value = nulls.value
    imgurl24.value = imgurl25.value
    console.log(imageUrl25.value);

}
const deletes24 = () => {
    imageUrl24.value = imageUrl25.value
    imageUrl25.value = nulls.value
    imgurl25.value = nulls.value
    imgurl24.value = imgurl25.value
    console.log(imageUrl25.value);
}
const deletes25 = () => {
    imageUrl25.value = nulls.value
    imgurl25.value = nulls.value
    console.log(imageUrl25.value);
}
const deletes31 = () => {
    imageUrl31.value = imageUrl32.value
    imageUrl32.value = imageUrl33.value
    imageUrl33.value = imageUrl34.value
    imageUrl34.value = imageUrl35.value
    imageUrl35.value = imageUrl36.value
    imageUrl36.value = imageUrl37.value
    imageUrl37.value = imageUrl38.value
    imageUrl38.value = imageUrl39.value
    imageUrl39.value = imageUrl40.value
    imageUrl40.value = nulls.value
    imgurl31.value = imgurl32.value
    imgurl32.value = imgurl33.value
    imgurl33.value = imgurl34.value
    imgurl34.value = imgurl35.value
    imgurl35.value = imgurl36.value
    imgurl36.value = imgurl37.value
    imgurl37.value = imgurl38.value
    imgurl38.value = imgurl39.value
    imgurl39.value = imgurl40.value
    imgurl40.value = nulls.value
}
const deletes32 = () => {
    imageUrl32.value = imageUrl33.value
    imageUrl33.value = imageUrl34.value
    imageUrl34.value = imageUrl35.value
    imageUrl35.value = imageUrl36.value
    imageUrl36.value = imageUrl37.value
    imageUrl37.value = imageUrl38.value
    imageUrl38.value = imageUrl39.value
    imageUrl39.value = imageUrl40.value
    imageUrl40.value = nulls.value
    imgurl32.value = imgurl33.value
    imgurl33.value = imgurl34.value
    imgurl34.value = imgurl35.value
    imgurl35.value = imgurl36.value
    imgurl36.value = imgurl37.value
    imgurl37.value = imgurl38.value
    imgurl38.value = imgurl39.value
    imgurl39.value = imgurl40.value
    imgurl40.value = nulls.value
}
const deletes33 = () => {
    imageUrl33.value = imageUrl34.value
    imageUrl34.value = imageUrl35.value
    imageUrl35.value = imageUrl36.value
    imageUrl36.value = imageUrl37.value
    imageUrl37.value = imageUrl38.value
    imageUrl38.value = imageUrl39.value
    imageUrl39.value = imageUrl40.value
    imageUrl40.value = nulls.value
    imgurl33.value = imgurl34.value
    imgurl34.value = imgurl35.value
    imgurl35.value = imgurl36.value
    imgurl36.value = imgurl37.value
    imgurl37.value = imgurl38.value
    imgurl38.value = imgurl39.value
    imgurl39.value = imgurl40.value
    imgurl40.value = nulls.value
}
const deletes34 = () => {
    imageUrl34.value = imageUrl35.value
    imageUrl35.value = imageUrl36.value
    imageUrl36.value = imageUrl37.value
    imageUrl37.value = imageUrl38.value
    imageUrl38.value = imageUrl39.value
    imageUrl39.value = imageUrl40.value
    imageUrl40.value = nulls.value
    imgurl34.value = imgurl35.value
    imgurl35.value = imgurl36.value
    imgurl36.value = imgurl37.value
    imgurl37.value = imgurl38.value
    imgurl38.value = imgurl39.value
    imgurl39.value = imgurl40.value
    imgurl40.value = nulls.value
}
const deletes35 = () => {
    imageUrl35.value = imageUrl36.value
    imageUrl36.value = imageUrl37.value
    imageUrl37.value = imageUrl38.value
    imageUrl38.value = imageUrl39.value
    imageUrl39.value = imageUrl40.value
    imageUrl40.value = nulls.value
    imgurl35.value = imgurl36.value
    imgurl36.value = imgurl37.value
    imgurl37.value = imgurl38.value
    imgurl38.value = imgurl39.value
    imgurl39.value = imgurl40.value
    imgurl40.value = nulls.value
}
const deletes36 = () => {
    imageUrl36.value = imageUrl37.value
    imageUrl37.value = imageUrl38.value
    imageUrl38.value = imageUrl39.value
    imageUrl39.value = imageUrl40.value
    imageUrl40.value = nulls.value
    imgurl36.value = imgurl37.value
    imgurl37.value = imgurl38.value
    imgurl38.value = imgurl39.value
    imgurl39.value = imgurl40.value
    imgurl40.value = nulls.value
}
const deletes37 = () => {
    imageUrl37.value = imageUrl38.value
    imageUrl38.value = imageUrl39.value
    imageUrl39.value = imageUrl40.value
    imageUrl40.value = nulls.value
    imgurl37.value = imgurl38.value
    imgurl38.value = imgurl39.value
    imgurl39.value = imgurl40.value
    imgurl40.value = nulls.value
}
const deletes38 = () => {
    imageUrl38.value = imageUrl39.value
    imageUrl39.value = imageUrl40.value
    imageUrl40.value = nulls.value
    imgurl38.value = imgurl39.value
    imgurl39.value = imgurl40.value
    imgurl40.value = nulls.value
}
const deletes39 = () => {
    imageUrl39.value = imageUrl40.value
    imageUrl40.value = nulls.value
    imgurl39.value = imgurl40.value
    imgurl40.value = nulls.value
}
const deletes40 = () => {
    imageUrl40.value = nulls.value
    imgurl40.value = nulls.value
}
const videoUrl1 = () => {
    namea.value = ''
    videoUrl.value = ''
}
const cancel = () => {
    router.push('./commodity')
}
//判断账号是否是管理员权限
if (localStorage.getItem('permissionsStatus') === '0') { //账号属于管理员
    storeLV.value = true;
    instance.get(`/api/stores`)
        .then(response => {
            console.log(response.data);
            store.value = response.data;
            console.log(store.value);
        })
        .catch(error => {
            console.log(error);
            ElMessage({
                showClose: true,
                message: '请登录',
                type: 'error',
            })
            router.push('./')
        })
} else {
    storeLV.value = false;
    value.value = "非管理员";
    const id = localStorage.getItem('sysStoreId');
    console.log(id);
    ///api/stores/cate/${b}
    instance.get(`/api/stores/cate/${id}`)
        .then(response => {
            console.log(id);
            searches.value = response.data.data;
            managementes.value = toRaw(searches.value);
            console.log(managementes.value);
        })
        .catch(error => {
            console.log(error);
            ElMessage({
                showClose: true,
                message: '请登录',
                type: 'error',
            })
            router.push('./')
        })
}

const beforeAvatarUpload = (file) => {
    const isJPG = file.type === 'image/jpeg';
    const isLt2M = file.size / 1024 / 1024 < 2;
    const timer = ref(null);
    timer.value = setTimeout(() => {
        // 执行需要停止的操作
        alert('响应时间超过规定时间');
    }, 1000);
    clearTimeout(timer.value);

    if (!isJPG) {
        alert('上传头像图片只能是 JPG 格式!');
    }
    if (!isLt2M) {
        alert('上传头像图片大小不能超过 2MB!');
    }
    return isJPG && isLt2M;
};
const upload_21 = (param) => {
    console.log(param);
    const formData = new FormData();
    formData.append('file', param.file);
    console.log(formData);
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            //本地
            imgurl21.value = response.data.data;
            //请求
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl21.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                })
            console.log(imageUrl21.value);
        }).catch(response => {
            console.log('图片上传失败')
        })
};
const upload_22 = (param) => {
    console.log(param);
    const formData = new FormData();
    formData.append('file', param.file);
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            //本地
            imgurl22.value = response.data.data;
            //请求
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl22.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                })
        }).catch(response => {
            console.log('图片上传失败')
        })
};
const upload_23 = (param) => {
    const formData = new FormData();
    formData.append('file', param.file);
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            //本地
            imgurl23.value = response.data.data;
            //请求
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl23.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                })
        }).catch(response => {
            console.log('图片上传失败')
        })
};
const upload_24 = (param) => {
    const formData = new FormData();
    formData.append('file', param.file);
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            //本地
            imgurl24.value = response.data.data;
            //请求
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl24.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                })
        }).catch(response => {
            console.log('图片上传失败')
        })
};
const upload_25 = (param) => {
    const formData = new FormData();
    formData.append('file', param.file);
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            //本地
            imgurl25.value = response.data.data;
            //请求
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl25.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                })
        }).catch(response => {
            console.log('图片上传失败')
        })
};
const upload_31 = (param) => {
    const formData = new FormData();
    formData.append('file', param.file);
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            //本地
            imgurl31.value = response.data.data;
            //请求
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl31.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                })
        }).catch(response => {
            console.log('图片上传失败')
        })
};
const upload_32 = (param) => {
    const formData = new FormData();
    formData.append('file', param.file);
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            //本地
            imgurl32.value = response.data.data;
            //请求
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl32.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                })
        }).catch(response => {
            console.log('图片上传失败')
        })
};
const upload_33 = (param) => {
    const formData = new FormData();
    formData.append('file', param.file);
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            //本地
            imgurl33.value = response.data.data;
            //请求
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl33.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                })
        }).catch(response => {
            console.log('图片上传失败')
        })
};
const upload_34 = (param) => {
    const formData = new FormData();
    formData.append('file', param.file);
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            //本地
            imgurl34.value = response.data.data;
            //请求
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl34.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                })
        }).catch(response => {
            console.log('图片上传失败')
        })
};
const upload_35 = (param) => {
    const formData = new FormData();
    formData.append('file', param.file);
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            //本地
            imgurl35.value = response.data.data;
            //请求
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl35.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                })
        }).catch(response => {
            console.log('图片上传失败')
        })
};
const upload_36 = (param) => {
    const formData = new FormData();
    formData.append('file', param.file);
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            //本地
            imgurl36.value = response.data.data;
            //请求
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl36.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                })
        }).catch(response => {
            console.log('图片上传失败')
        })
};
const upload_37 = (param) => {
    const formData = new FormData();
    formData.append('file', param.file);
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            //本地
            imgurl37.value = response.data.data;
            //请求
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl37.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                })
        }).catch(response => {
            console.log('图片上传失败')
        })
};
const upload_38 = (param) => {
    const formData = new FormData();
    formData.append('file', param.file);
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            //本地
            imgurl38.value = response.data.data;
            //请求
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl38.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                })
        }).catch(response => {
            console.log('图片上传失败')
        })
};
const upload_39 = (param) => {
    const formData = new FormData();
    formData.append('file', param.file);
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            //本地
            imgurl39.value = response.data.data;
            //请求
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl39.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                })
        }).catch(response => {
            console.log('图片上传失败')
        })
};
const upload_40 = (param) => {
    const formData = new FormData();
    formData.append('file', param.file);
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            //本地
            imgurl40.value = response.data.data;
            //请求
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl40.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                })
        }).catch(response => {
            console.log('图片上传失败')
        })
};
const upload_41 = (param) => {
    console.log(param);
    const formData = new FormData();
    formData.append('file', param.file);
    console.log("2222");
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            //本地
            imgurl41.value = response.data.data;
            //请求
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl41.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                })
        }).catch(response => {
            console.log('图片上传失败')
        })
}
const beforeAvatarUploads = (file) => {
    console.log(file);
    if (file.type !== 'video/mp4') {
        ElMessage({
            type: 'error',
            message: '请上传mp4格式视频',
        })
        return;
    }
    const formData = new FormData();
    formData.append('file', file);
    instance.post('/api/common/uploadVideo', formData)
        .then(response => {
            console.log('上传成功');
            console.log(response.data.data);
            namea.value = response.data.data;
            videoUrl.value = URL.createObjectURL(file)
            console.log(namea.value);
        }).catch(response => {
            console.log(response);
            console.log('上传失败')
        })

    console.log(formData);
    // const isJPG = file.type === '.mp4';
    // const isLt2M = file.size / 1024 / 1024 < 200;
    const timer = ref(null);
    timer.value = setTimeout(() => {
        // 执行需要停止的操作
        alert('响应时间超过规定时间');
    }, 10000);
    clearTimeout(timer.value);

    // if (!isJPG) {
    //     alert('上传头像图片只能是 JPG 格式!');
    // }
    if (!isLt2M) {
        alert('上传头像图片大小不能超过 2MB!');
    }
    console.log("1111");
    return isJPG && isLt2M;
};
const addsd = (id) => {
    console.log(id);
    console.log(value1.value.length);
    if (value1.value !== '') {
        for (let i = 0; i < value1.value.length; i++) {
            console.log(id[i]);
            instance.get(`/api/stores/cate/${id[i]}`)
                .then(response => {
                    console.log(id);
                    managementes.value[i] = response.data.data;
                    // managementes.value = toRaw(searches.value);
                    console.log(managementes.value[i]);
                })
        }
    }
}

const addid = ref();

const addads = (id) => {
    console.log(id);
    addid.value = id;
}

const add = () => {
    console.log(imgurl21.value);
    console.log(addid.value);
    console.log(value1.value.length);
    console.log(value_1.value);
    ElMessageBox.confirm(
        '确定添加该商品吗?',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            if (input.value === '') {
                ElMessage({
                    type: 'error',
                    message: '添加失败，商品名称未填写',
                })
                return;
            }
            // if (input_3.value === '') {
            //     ElMessage({
            //         type: 'error',
            //         message: '添加失败，商品佣金未填写',
            //     })
            //     return;
            // }
            // if (input_2.value / 10 <= input_3.value) {
            //     ElMessage({
            //         type: 'error',
            //         message: '添加失败，商品佣金大于价格10%',
            //     })
            //     return;
            // }
            for (let i = 0; i < value_1.value.length; i++) {
                if (value_1.value[i] === '') {
                    ElMessage({
                        type: 'error',
                        message: '添加失败，商品分类未填写',
                    })
                    return;
                }
            }

            if (value1.value === '') {
                ElMessage({
                    type: 'error',
                    message: '添加失败，商品门店未填写',
                })
                return;
            }
            if (input_2.value === '') {
                ElMessage({
                    type: 'error',
                    message: '添加失败，商品价格未填写',
                })
                return;
            }
            if (stock.value === '') {
                ElMessage({
                    type: 'error',
                    message: '添加失败，商品库存未填写',
                })
                return;
            }
            if (isNum.value === false) {
                ElMessage({
                    type: 'error',
                    message: '添加失败，商品价格只能填写数字',
                })
                return;
            }
            // if (isNun.value === false) {
            //     ElMessage({
            //         type: 'error',
            //         message: '添加失败，商品佣金只能填写数字',
            //     })
            //     return;
            // }
            if (state.value === '') {
                ElMessage({
                    type: 'error',
                    message: '添加失败，请添加商品状态',
                })
                return;
            }
            if (isNus.value === false) {
                ElMessage({
                    type: 'error',
                    message: '添加失败，商品库存只能填写数字',
                })
                return;
            }
            if (imgurl21.value === '' || imgurl21.value === undefined) {
                ElMessage({
                    type: 'error',
                    message: '添加失败，商品展示图未添加',
                })
                return;
            }
            if (imgurl31.value === '' || imgurl31.value === undefined) {
                ElMessage({
                    type: 'error',
                    message: '添加失败，商品详情图未添加',
                })
                return;
            }

            if (imgurl21.value === undefined) {
                imgurl21.value = ''
            }
            if (imgurl22.value === undefined) {
                imgurl22.value = ''
            }
            if (imgurl23.value === undefined) {
                imgurl23.value = ''
            }
            if (imgurl24.value === undefined) {
                imgurl24.value = ''
            }
            if (imgurl25.value === undefined) {
                imgurl25.value = ''
            }
            if (imgurl32.value === undefined) {
                imgurl32.value = ''
            }
            if (imgurl33.value === undefined) {
                imgurl33.value = ''
            }
            if (imgurl34.value === undefined) {
                imgurl34.value = ''
            }
            if (imgurl35.value === undefined) {
                imgurl35.value = ''
            }
            if (imgurl36.value === undefined) {
                imgurl36.value = ''
            }
            if (imgurl37.value === undefined) {
                imgurl37.value = ''
            }
            if (imgurl38.value === undefined) {
                imgurl38.value = ''
            }
            if (imgurl39.value === undefined) {
                imgurl39.value = ''
            }
            if (imgurl40.value === undefined) {
                imgurl40.value = ''
            }
            if (localStorage.getItem('permissionsStatus') === '0') {
                for (let i = 0; i < value_1.value.length; i++) {
                    const edit = {
                        "name": input.value,
                        "price": input_2.value,
                        "commission": 0,
                        "systemType" :0,
                        // "categoriesName" : value_1.value ,
                        "categoriesId": value_1.value[i],
                        "coverUrl": imgurl21.value,
                        "coverUrl2": imgurl22.value,
                        "coverUrl3": imgurl23.value,
                        "coverUrl4": imgurl24.value,
                        "coverUrl5": imgurl25.value,
                        "stock": stock.value,
                        "productStatus": state.value,
                    }
                    console.log(edit);
                    instance.post('/api/tproduct', edit)
                        .then(response => {
                            console.log(response.data.data);
                            console.log('上传成功');
                            const edit2 = {
                                "productId": response.data.data,
                                "img1": imgurl31.value,
                                "img2": imgurl32.value,
                                "img3": imgurl33.value,
                                "img4": imgurl34.value,
                                "img5": imgurl35.value,
                                "img6": imgurl36.value,
                                "img7": imgurl37.value,
                                "img8": imgurl38.value,
                                "img9": imgurl39.value,
                                "img10": imgurl40.value
                            }
                            console.log(edit2);
                            instance.post('/api/details', edit2)
                                .then(response => {
                                    console.log(response.data.data);
                                    console.log('上传成功');
                                }).catch(error => {
                                    console.log(error);
                                    console.log('上传失败');
                                })
                            if (namea.value !== '') {
                                const edit3 = {
                                    "productId": response.data.data,
                                    "videoName1": namea.value
                                }
                                instance.post('/api/productVideo', edit3)
                                    .then(response => {
                                        console.log(response);
                                        console.log('上传成功');
                                    }).catch(error => {
                                        console.log(error);
                                        console.log('上传失败');
                                    })
                            }
                        }).catch(error => {
                            console.log(error);
                            console.log('上传失败');
                        })
                }
            } else {
                const edit = {
                    "name": input.value,
                    "price": input_2.value,
                    "commission": 0,
                    "systemType" :0,
                    // "categoriesName" : value_1.value ,
                    "categoriesId": value_2.value,
                    "coverUrl": imgurl21.value,
                    "coverUrl2": imgurl22.value,
                    "coverUrl3": imgurl23.value,
                    "coverUrl4": imgurl24.value,
                    "coverUrl5": imgurl25.value,
                    "stock": stock.value,
                    "productStatus": state.value,
                }
                console.log(edit);
                instance.post('/api/tproduct', edit)
                    .then(response => {
                        console.log(response.data.data);
                        console.log('上传成功');
                        const edit2 = {
                            "productId": response.data.data,
                            "img1": imgurl31.value,
                            "img2": imgurl32.value,
                            "img3": imgurl33.value,
                            "img4": imgurl34.value,
                            "img5": imgurl35.value,
                            "img6": imgurl36.value,
                            "img7": imgurl37.value,
                            "img8": imgurl38.value,
                            "img9": imgurl39.value,
                            "img10": imgurl40.value
                        }
                        console.log(edit2);
                        instance.post('/api/details', edit2)
                            .then(response => {
                                console.log(response.data.data);
                                console.log('上传成功');
                            }).catch(error => {
                                console.log(error);
                                console.log('上传失败');
                            })
                        if (namea.value !== '') {
                            const edit3 = {
                                "productId": response.data.data,
                                "videoName1": namea.value
                            }
                            instance.post('/api/productVideo', edit3)
                                .then(response => {
                                    console.log(response);
                                    console.log('上传成功');
                                }).catch(error => {
                                    console.log(error);
                                    console.log('上传失败');
                                })
                        }
                    }).catch(error => {
                        console.log(error);
                        console.log('上传失败');
                    })
            }
            ElMessage({
                type: 'success',
                message: '添加成功',
            })
            router.push('./commodity')
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消添加',
            })
        })
};

</script>

<style>


.containere {
    position: fixed;
    top: 10vh;
    left: 15vw;
}


.addition {
    width: 100vw;
    height: 80vh;
}

.addition .el-input {
    width: 25vw;
}

.addition p {
    padding-top: 5vh;
    display: flex;
}

.addition .el-textarea {
    width: 25vw;
}

.name {
    width: 9%;
}

.addition .input {
    display: flex;
}

.addition .button {
    display: flex;
    margin-left: 12vw;
}


.addition .xian .avatar-uploader {
    display: inline-block;
}

.addition .xian {
    width: 59vw;
}

.addition video {
    width: 20vw;
    height: 10vw;
}

.el-select-dropdown {
    width: 25vw !important;
}
</style>