import router from '@/router';
import axios from 'axios';

const instance = axios.create({
  baseURL: '/api', // 设置请求的基础URL
});

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 从localStorage中获取用户信息
    // let user = JSON.parse(localStorage.getItem('honey-user') || '{}');

    var token = window.localStorage.getItem("token")
    // 检查用户是否已登录，并且token存在
    if (!token) {
     router.push('/')
    }
     // 在请求头中添加token
     config.headers.Authorization = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
