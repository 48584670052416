<!-- 添加账号 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <el-main class="main">
                <div class="main_item">
                    <div class="adds">
                        <div class="add" @keyup.enter.native="addUser">
                            <el-input v-if="stata === '0'" v-model="stores" placeholder="请输入门店id"></el-input>
                            <el-input v-model="usernames" placeholder="请输入账号"></el-input>
                            <el-input v-model="username" placeholder="请输入账号名"></el-input>
                            <el-input v-model="password" placeholder="请输入密码"></el-input>
                            <el-input v-model="password2" placeholder="请再次输入密码"></el-input>
                            <el-button type="primary" :plain="true" @click="addUser">注册</el-button>
                        </div>
                    </div>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script setup>
import { ref } from 'vue';
import { ElMessage } from 'element-plus'
import instance from '../network/storester.js';
import { useRouter } from 'vue-router';

const router = useRouter();
const username = ref('');
const password = ref('');
const password2 = ref('');
const stores = ref('');
const usernames = ref('');
const stata = localStorage.getItem('permissionsStatus')
//判断是否有权限添加账号
const addUser = () => {
    console.log(stores.value);
    console.log(localStorage.getItem('sysStoreId'));
    if (localStorage.getItem('permissionsStatus') !== '0') {
        if (username.value == '' || password.value == '' || password2.value == '') {
            ElMessage({
                showClose: true,
                message: '请输入账号密码',
                type: 'error',
            })
            return;
        }
        //判断账号是否小于6位
        if (password.value.length < 8) {
            ElMessage({
                showClose: true,
                message: '密码长度不能小于8位',
                type: 'error',
            })
            return;
        }
        //判断账号是否大于16位
        if (password.value.length > 16) {
            ElMessage({
                showClose: true,
                message: '密码长度不能大于16位',
                type: 'error',
            })
            return;
        }
        //判断密码输入是否一致
        if (password.value != password2.value) {
            ElMessage({
                showClose: true,
                message: '两次密码输入不一致',
                type: 'error',
            })
            return;
        }
        var acc = {
            "account": usernames.value,
            "name": username.value,
            "password": password.value,
            "sysStoreId": localStorage.getItem('sysStoreId')
        }
        instance.post(`/api/domain/register`, JSON.stringify(acc), { headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                console.log(response);
                //判断账号是否存在
                if (response.data.msg === '账号存在') {
                    ElMessage({
                        showClose: true,
                        message: '账号已存在',
                        type: 'error',
                    })
                } else {
                    if (response.data.code === 0) {
                        console.log('11');
                        ElMessage({
                            showClose: true,
                            message: `${response.data.msg}`,
                            type: 'error',
                        })
                        return;
                    }
                    ElMessage({
                        showClose: true,
                        message: '账号添加成功',
                        type: 'success',
                    })
                    //刷新页面
                    // window.location.reload();
                }

            })
            .catch(error => {
                //token失效跳转登录页面
                console.log(error);
                ElMessage({
                    showClose: true,
                    message: '请登录',
                    type: 'error',
                })
                // router.push('./')
            })
    } else {
        if (stores.value === '' && localStorage.getItem('permissionsStatus') === '0') {
            stores.value = null
        }
        console.log(stores.value);
        //判断两次密码是否一致
        if (username.value == '' || password.value == '' || password2.value == '') {
            ElMessage({
                showClose: true,
                message: '请输入账号密码',
                type: 'error',
            })
            return;
        }
        //判断账号是否小于6位
        if (password.value.length < 8) {
            ElMessage({
                showClose: true,
                message: '密码长度不能小于8位',
                type: 'error',
            })
            return;
        }
        //判断账号是否大于16位
        if (password.value.length > 16) {
            ElMessage({
                showClose: true,
                message: '密码长度不能大于16位',
                type: 'error',
            })
            return;
        }
        //判断密码输入是否一致
        if (password.value != password2.value) {
            ElMessage({
                showClose: true,
                message: '两次密码输入不一致',
                type: 'error',
            })
            return;
        }
        //保存门店id

        //保存需要修改的数据
        if (localStorage.getItem('permissionsStatus') === '0' && stores.value === null) {
            var acc = {
                "account": usernames.value,
                "name": username.value,
                "password": password.value,
                "sysStoreId": stores.value,
                "permissionsStatus": 0
            }
        } else {
            var acc = {
                "account": usernames.value,
                "name": username.value,
                "password": password.value,
                "sysStoreId": stores.value,
            }
        }


        console.log(acc);

        console.log(stores.value);
        console.log(password.value);
        console.log(usernames.value);
        console.log(username.value);
        console.log(acc);
        //通过api修改数据
        instance.post(`/api/domain/register`, JSON.stringify(acc), { headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                console.log(response);
                //判断账号是否存在
                if (response.data.msg === '账号存在') {
                    ElMessage({
                        showClose: true,
                        message: '账号已存在',
                        type: 'error',
                    })
                } else {
                    if (response.data.code === 0) {
                        console.log('11');
                        ElMessage({
                            showClose: true,
                            message: `${response.data.msg}`,
                            type: 'error',
                        })
                        return;
                    }
                    ElMessage({
                        showClose: true,
                        message: '账号添加成功',
                        type: 'success',
                    })
                    //刷新页面
                    // window.location.reload();
                }

            })
            .catch(error => {
                //token失效跳转登录页面
                console.log(error);
                ElMessage({
                    showClose: true,
                    message: '请登录',
                    type: 'error',
                })
                // router.push('./')
            })
    }
}
</script>

<style>
.container {
    position: fixed;
    top: 0;
    left: 15vw;
}



.main {
    position: fixed;
    top: 10vh;
    left: 15vw;
    width: 85vw;
    height: 90vh;
}

.adds {
    position: fixed;
    top: 7vh !important;
    width: 92vw;
    height: 100vh;
    background-image: url('../assets/register.jpg');
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    margin-left: -8vw;
}

.add {
    top: 20vh !important;
    position: fixed;
    width: 20vw;
    height: 70vh;
    left: 40vw;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
    padding: 0 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

}

.add p {
    display: flex;
    margin-top: 0vh !important;
}

.add input,
.add button {
    display: block;
    margin: 0 auto;
    width: 50%;
    height: 5vh;
}
</style>