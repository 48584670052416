<!-- 管理员列表 -->

<template>
    <el-container class="Wrapper">
        <el-aside width="200px" class="leftnavbar">
            <LeftNav></LeftNav>
        </el-aside>
        <el-container class="container">
            <el-header class="container_top">
                <TopNav></TopNav>
            </el-header>
            <el-main class="main">
                <div class="main_item">
                    <div class="role">
                        <el-button type="primary" @click="NewRole" class="newrole">新增角色</el-button>
                        <el-table :data="userrole" style="width: 100%" height="400" v-loading="loading"
                            element-loading-text="加载中，请稍后...">
                            <el-table-column  prop="name" label="账号名" width="150" />
                            <el-table-column  prop="account" label="账号" width="150" />
                            <el-table-column prop="roleName" label="角色" width="150" />
                            <el-table-column prop="updateTime" label="最后一次登录时间" width="150" />
                            <el-table-column label="开启" width="120">
                                <template #default="scope">
                                    <el-switch size="large" active-text="" inactive-text=""
                                        v-model="scope.row.roleState"
                                        @change="admin(scope.row.id, scope.row.roleState)" />
                                </template>
                            </el-table-column>
                            <el-table-column  label="操作" width="120">
                                <template #default="scope">
                                    <el-button type="primary" size="small" @click="edit(scope.row.id)">编辑</el-button>
                                    <el-button type="danger" size="small" @click="del(scope.row.id)">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div v-if="showModals" class="modal">
                        <div class="modals-content">
                            <span class="close" @click="toggleModals">&times;</span>
                            <span class="edadmin">修改管理员账号</span>
                            <el-form :model="form" label-width="auto" style="max-width: 300px">
                                <el-form-item label="账号名">
                                    <el-input v-model="form.name" />
                                </el-form-item>
                                <el-form-item label="账号">
                                    <el-input v-model="form.account" />
                                </el-form-item>
                                <el-form-item label="密码">
                                    <el-input v-model="form.password" />
                                </el-form-item>
                                <el-form-item label="所属门店">
                                    <el-select v-model="form.sysStoreId" placeholder="商品所属门店" @change="changeStore">
                                        <el-option v-for="item in stores" :key="item.id" :label="item.name"
                                            :value="item.id" />
                                        <el-option label="超管" value="0" />
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="confirm">确认</el-button>
                                    <el-button @click="cancellation">取消</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                    <div v-if="showModalse" class="modal">
                        <div class="modals-content">
                            <span class="close" @click="toggleModalse">&times;</span>
                            <span class="edadmin">新增角色账号</span>
                            <el-form :model="form" label-width="auto" style="max-width: 300px">
                                <el-form-item label="账号名">
                                    <el-input v-model="form.name" />
                                </el-form-item>
                                <el-form-item label="账号">
                                    <el-input v-model="form.account" />
                                </el-form-item>
                                <el-form-item label="密码">
                                    <el-input v-model="form.password" />
                                </el-form-item>
                                <el-form-item label="所属门店">
                                    <el-select v-model="form.sysStoreId" placeholder="商品所属门店" @change="changeStore">
                                        <el-option v-for="item in stores" :key="item.id" :label="item.name"
                                            :value="item.id" />
                                        <el-option label="超管" value="0" />
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="confirm">确认</el-button>
                                    <el-button @click="cancellation">取消</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script setup>
import Menu from '../navbar/Menu.vue'
import LeftNav from '../navbar/LeftNav.vue'
import TopNav from '../navbar/TopNav.vue'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import instance from '../network/storester.js'
import { ref, toRaw } from 'vue'

const userrole = ref([])
const userroles = ref(null)
const router = useRouter()
const loading = ref(true)
const showModals = ref(false)
const showModalse = ref(false)

instance.get('/api/adminUserRole/getUserRole')
    .then(response => {
        console.log(response.data.data.id);
        userroles.value = response.data.data
        console.log(userroles.value);
        for (let i = 0; i < userroles.value.length; i++) {
            if (userroles.value[i].roleState === 0) {
                userroles.value[i].roleState = true
            } else {
                userroles.value[i].roleState = false
            }
        }


        console.log(userroles.value);
        userrole.value = toRaw(userroles.value)
        loading.value = false

    }).catch(error => {
        console.log(error);
        ElMessage({
            showClose: true,
            message: '请登录',
            type: 'error',
        })
        router.push('./')
    })
const admin = (id, roleState) => {
    if (roleState === true) {
        roleState = 0
    } else {
        roleState = 1
    }
    instance.put(`/api/adminUserRole/startAndClose?id=${id}&roleState=${roleState}`)
        .then(response => {
            if (roleState = 1) {
                ElMessage({
                    type: 'success',
                    message: '已开启',

                })
            } else {
                ElMessage({
                    type: 'error',
                    message: '已关闭',

                })
            }
            console.log(response);
        }).catch(error => {
            console.log(error);
            // router.push('./')
        })
}

const form = ref({
    name: '',
    AccountNumber: '',
    password: '',
    sysStoreId: '',
})

const EditUser = ref([])
const EditUsers = ref(null)
const stores = ref([])
const storess = ref(null)
const edit = (id) => {
    showModals.value = !showModals.value;
    instance.get(``)
        .then(response => {
            console.log(stores.value);
        })
        .catch(error => {
            ElMessage({
                showClose: true,
                message: '请重新登录',
                type: 'error',
            })
            router.push('./')
        })
}

const changeStore = () => {

}
console.log(form.value);
const toggleModals = () => {
    showModals.value = !showModals.value;
};
const toggleModalse = () => {
    showModalse.value = !showModalse.value;
};
const confirm = () => {
    console.log(form.value);
    instance.put(``)
        .then(response => {
            ElMessage({
                type: 'success',
                message: '编辑成功',

            })
            // window.location.reload()
        }).catch(error => {
            ElMessage({
                showClose: true,
                message: '编辑失败',
                type: 'error',
            })
        })
}
const cancellation = () => {
    showModals.value = false;
}
const del = (id) => {
    ElMessageBox.confirm(
        '您确定要删除角色吗?',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            instance.delete(`/api/adminRole/deleteRole?id=${id}`)
                .then(response => {
                    ElMessage({
                        type: 'success',
                        message: '删除成功',

                    })
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        type: 'error',
                        message: '删除失败',

                    })
                    router.push('/')
                })
            window.location.reload()
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消删除',
            })
        })

}
const NewRole = () => {
    showModalse.value = !showModalse.value;
}
</script>

<style>


.container {
    position: fixed;
    top: 0;
    left: 15vw;
}



.main {
    position: fixed;
    top: 10vh;
    left: 15vw;
    width: 85vw;
    height: 90vh;
}

.role {
    width: 85vw;
    height: 100vh;
    /* margin-top: 5vh; */
}


.modals-content {
    background-color: #fefefe;
    margin: 8% 15% 15% 35%;
    padding: 20px;
    border: 1px solid #888;
    width: 400px;
    left: 50vw;
}

.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.modal .edadmin {
    font-size: 16px;
}


.newrole {
    margin-left: 70vw;
}
</style>