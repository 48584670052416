<!-- 佣金记录 -->
<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <el-main class="main">
                <div class="main_item">
                    <div class="flowtop">
                        <span>用户ID：</span>
                        <span> <el-input v-model="input" style="width: 240px" placeholder="用户ID" /></span>
                        <span class="Separateds">账号区间：</span>
                        <span><el-input v-model="minimum" style="width: 5vw" placeholder="最小值" /></span>
                        <span>——</span>
                        <span><el-input v-model="maximum" style="width: 5vw" placeholder="最大值" /></span>
                        <span class="Separateds">用户名：</span>
                        <span> <el-input v-model="userName" style="width: 240px" placeholder="用户名" /></span>
                        <span><el-button type="primary" class="Separateds" @click="query">查询</el-button></span>
                        <span><el-button type="primary" class="Separateds" @click="exports">导出</el-button></span>
                    </div>
                    <div class="flowbottom">
                        <el-table :data="commission" style="width: 85%" height="70vh" v-loading="loading"
                            element-loading-text="加载中，请稍后...">
                            <el-table-column fixed prop="wxUserId" label="用户ID" width="200" />
                            <el-table-column prop="realName" label="用户名" width="200" />
                            <el-table-column prop="balanceOfAccount" label="账户金额" width="200" />
                            <el-table-column prop="money" label="提现金额" width="200" />
                            <el-table-column prop="payoutType" label="提现方式" width="200" />
                            <el-table-column prop="" label="提现状态" width="200">
                                <template #default="scope">
                                    <el-tag v-if="scope.row.payoutStatus == 2" type="success">提现成功</el-tag>
                                    <el-tag v-if="scope.row.payoutStatus == 1" type="success">申请提现</el-tag>
                                    <el-tag v-if="scope.row.payoutStatus == 3" type="error"
                                        style="color: red;">异常</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column prop="phone" label="联系方式" width="200" />
                            <el-table-column prop="createTime" label="提现时间" width="200" />
                        </el-table>
                    </div>
                    <div class="flowfoot">
                        <div class="demo-pagination-blocks">
                            <el-pagination v-model:current-page="currentPage4" v-model:page-size="pageSize4"
                                @size-change="handleSizeChangese" @current-change="handleCurrentChangese"
                                :current-page="currentPage4" :page-sizes="[10, 20, 30, 40]" :page-size="10"
                                layout="total, sizes, prev, pager, next, jumper" :total="tatol">
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { ref, toRaw, reactive } from "vue";
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import Axios from "axios"

const input = ref('');
const minimum = ref('');
const maximum = ref('');
const userName = ref('');
const commission = ref([])
const commissions = ref(null)
const loading = ref(true)
const tatol = ref(0);
const router = useRouter();
const page = reactive({ pageNum: 1, pageSize: 10 });
const handleSizeChangese = (val) => {
    page.pageSize = val;
    console.log(val);
    query();
};
//当前页改变时触发
const handleCurrentChangese = (val) => {
    page.pageNum = val;
    console.log(val);
    query();
};

instance.get(`/api/dispatching/commissionPaging?&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
    .then(response => {
        console.log(response);
        commissions.value = response.data.data.commissionList
        tatol.value = response.data.data.totalCount
        console.log(commissions.value);
        commission.value = toRaw(commissions.value)
        console.log(commission.value);
        loading.value = false
    }).catch(error => {
        console.log(error);
        ElMessage({
            message: '请重新登录',
            type: 'error',
        })
        router.push('./')
    })
const query = () => {
    loading.value = true
    console.log(minimum.value);
    console.log(maximum.value)
    if (input.value == '' && minimum.value == '' && maximum.value == '' && userName.value == '') {
        instance.get(`/api/dispatching/commissionPaging?&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
            .then(response => {
                console.log(response);
                commissions.value = response.data.data.commissionList
                tatol.value = response.data.data.totalCount
                console.log(commissions.value);
                commission.value = toRaw(commissions.value)
                console.log(commission.value);
                loading.value = false
            }).catch(error => {
                console.log(error);
                ElMessage({
                    message: '请重新登录',
                    type: 'error',
                })
                router.push('./')
            })
    }
    if (input.value != '' && minimum.value == '' && maximum.value == '' && userName.value == '') {
        instance.get(`/api/dispatching/searchCommission?wxUserId=${input.value}&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
            .then(response => {
                commissions.value = response.data.data.commissionList
                tatol.value = response.data.data.totalCount
                console.log(response);
                console.log(commissions.value);
                commission.value = toRaw(commissions.value)
                console.log(commission.value);
                loading.value = false;
                console.log('用户id');
            }).catch(error => {
                console.log(error);
            })
    }
    if (input.value == '' && minimum.value != '' && maximum.value != '' && userName.value == '') {
        instance.get(`/api/dispatching/searchCommission?smallCommission=${minimum.value}&largeCommission=${maximum.value}&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
            .then(response => {
                commissions.value = response.data.data.commissionList
                tatol.value = response.data.data.totalCount
                console.log(commissions.value);
                console.log(response);
                commission.value = toRaw(commissions.value)
                console.log(commission.value);
                loading.value = false
                console.log('区间');
            }).catch(error => {
                console.log(error);
            })
    }
    if (input.value == '' && minimum.value == '' && maximum.value == '' && userName.value != '') {
        instance.get(`/api/dispatching/searchCommission?realName=${userName.value}&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
            .then(response => {
                commissions.value = response.data.data.commissionList
                tatol.value = response.data.data.totalCount
                console.log(commissions.value);
                console.log(response);
                commission.value = toRaw(commissions.value)
                console.log(commission.value);
                loading.value = false
                console.log('用户名');
            }).catch(error => {
                console.log(error);
            })
    }
    if (input.value != '' && minimum.value != '' && maximum.value != '' && userName.value == '') {
        instance.get(`/api/dispatching/searchCommission?smallCommission=${minimum.value}&largeCommission=${maximum.value}&wxUserId=${input.value}&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
            .then(response => {
                commissions.value = response.data.data.commissionList
                tatol.value = response.data.data.totalCount
                console.log(commissions.value);
                console.log(response);
                commission.value = toRaw(commissions.value)
                console.log(commission.value);
                loading.value = false
                console.log('区间和用户id');
            }).catch(error => {
                console.log(error);
            })
    }
    if (input.value == '' && minimum.value != '' && maximum.value != '' && userName.value != '') {
        instance.get(`/api/dispatching/searchCommission?smallCommission=${minimum.value}&largeCommission=${maximum.value}&realName=${userName.value}&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
            .then(response => {
                commissions.value = response.data.data.commissionList
                tatol.value = response.data.data.totalCount
                console.log(commissions.value);
                console.log(response);
                commission.value = toRaw(commissions.value)
                console.log(commission.value);
                loading.value = false
                console.log('区间和用户id');
            }).catch(error => {
                console.log(error);
            })
    }
    if (input.value != '' && minimum.value == '' && maximum.value == '' && userName.value != '') {
        instance.get(`/api/dispatching/searchCommission?realName=${userName.value}&wxUserId=${input.value}&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
            .then(response => {
                commissions.value = response.data.data.commissionList
                tatol.value = response.data.data.totalCount
                console.log(commissions.value);
                console.log(response);
                commission.value = toRaw(commissions.value)
                console.log(commission.value);
                loading.value = false
                console.log('用户名');
            }).catch(error => {
                console.log(error);
            })
    }
    if (input.value != '' && minimum.value != '' && maximum.value != '' && userName.value != '') {
        instance.get(`/api/dispatching/searchCommission?smallCommission=${minimum.value}&largeCommission=${maximum.value}&realName=${userName.value}&wxUserId=${input.value}&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
            .then(response => {
                commissions.value = response.data.data.commissionList
                tatol.value = response.data.data.totalCount
                console.log(commissions.value);
                console.log(response);
                commission.value = toRaw(commissions.value)
                console.log(commission.value);
                loading.value = false
                console.log('区间和用户id');
            }).catch(error => {
                console.log(error);
            })
    }
    loading.value = false

}

const exports = () => {
    if (input.value == '' && minimum.value == '' && maximum.value == '' && userName.value == '') {
        Axios.get('https://www.jingtengyiliao.com/api/dispatching/commissionDerived', { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '佣金记录.xlsx'); // 设置要下载的文件名
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url); // 释放URL对象
                ElMessage({
                    message: '导出成功',
                    type: 'success',
                });
            })
            .catch(error => {
                console.error(error);
                ElMessage({
                    message: '请重新登录',
                    type: 'error',
                })
                // router.push('./')
            })
        return;
    }
    if (input.value != '' && minimum.value == '' && maximum.value == '' && userName.value == '') {
        Axios.get(`https://www.jingtengyiliao.com/api/dispatching/commissionDerived?wxUserId=${input.value}`, { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '佣金记录.xlsx'); // 设置要下载的文件名
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url); // 释放URL对象
                ElMessage({
                    message: '导出成功',
                    type: 'success',
                });
            })
            .catch(error => {
                console.error(error);
                ElMessage({
                    message: '请重新登录',
                    type: 'error',
                })
                // router.push('./')
            })
        return;
    }
    if (input.value == '' && minimum.value != '' && maximum.value != '' && userName.value == '') {
        Axios.get(`https://www.jingtengyiliao.com/api/dispatching/commissionDerived?smallCommission=${minimum.value}&largeCommission=${maximum.value}`, { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '佣金记录.xlsx'); // 设置要下载的文件名
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url); // 释放URL对象
                ElMessage({
                    message: '导出成功',
                    type: 'success',
                });
            })
            .catch(error => {
                console.error(error);
                ElMessage({
                    message: '请重新登录',
                    type: 'error',
                })
                // router.push('./')
            })
        return;
    }
    if (input.value == '' && minimum.value == '' && maximum.value == '' && userName.value != '') {
        Axios.get(`https://www.jingtengyiliao.com/api/dispatching/commissionDerived?realName=${userName.value}`, { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '佣金记录.xlsx'); // 设置要下载的文件名
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url); // 释放URL对象
                ElMessage({
                    message: '导出成功',
                    type: 'success',
                });
            })
            .catch(error => {
                console.error(error);
                ElMessage({
                    message: '请重新登录',
                    type: 'error',
                })
                // router.push('./')
            })
        return;
    }
    if (input.value != '' && minimum.value != '' && maximum.value != '' && userName.value == '') {
        Axios.get(`https://www.jingtengyiliao.com/api/dispatching/commissionDerived?smallCommission=${minimum.value}&largeCommission=${maximum.value}&wxUserId=${input.value}`, { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '佣金记录.xlsx'); // 设置要下载的文件名
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url); // 释放URL对象
                ElMessage({
                    message: '导出成功',
                    type: 'success',
                });
            })
            .catch(error => {
                console.error(error);
                ElMessage({
                    message: '请重新登录',
                    type: 'error',
                })
                // router.push('./')
            })
        return;
    }
    if (input.value == '' && minimum.value != '' && maximum.value != '' && userName.value != '') {
        Axios.get(`https://www.jingtengyiliao.com/api/dispatching/commissionDerived?smallCommission=${minimum.value}&largeCommission=${maximum.value}&realName=${userName.value}`, { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '佣金记录.xlsx'); // 设置要下载的文件名
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url); // 释放URL对象
                ElMessage({
                    message: '导出成功',
                    type: 'success',
                });
            })
            .catch(error => {
                console.error(error);
                ElMessage({
                    message: '请重新登录',
                    type: 'error',
                })
                // router.push('./')
            })
        return;
    }
    if (input.value != '' && minimum.value == '' && maximum.value == '' && userName.value != '') {
        Axios.get(`https://www.jingtengyiliao.com/api/dispatching/commissionDerived?realName=${userName.value}&wxUserId=${input.value}`, { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '佣金记录.xlsx'); // 设置要下载的文件名
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url); // 释放URL对象
                ElMessage({
                    message: '导出成功',
                    type: 'success',
                });
            })
            .catch(error => {
                console.error(error);
                ElMessage({
                    message: '请重新登录',
                    type: 'error',
                })
                // router.push('./')
            })
        return;
    }
    if (input.value != '' && minimum.value != '' && maximum.value != '' && userName.value != '') {
        Axios.get(`https://www.jingtengyiliao.com/api/dispatching/commissionDerived?smallCommission=${minimum.value}&largeCommission=${maximum.value}&realName=${userName.value}&wxUserId=${input.value}`, { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '佣金记录.xlsx'); // 设置要下载的文件名
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url); // 释放URL对象
                ElMessage({
                    message: '导出成功',
                    type: 'success',
                });
            })
            .catch(error => {
                console.error(error);
                ElMessage({
                    message: '请重新登录',
                    type: 'error',
                })
                // router.push('./')
            })
        return;
    }
}

</script>

<style>
.container {
    position: fixed;
    top: 0;
    left: 15vw;
}


.main {
    position: fixed;
    top: 10vh;
    left: 15vw;
    width: 85vw;
    height: 90vh;
}

.main_item {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
}

.flowtop {
    width: 90vw;
    height: 20vh;
    top: 10vh;
    line-height: 10vh;
    font-size: 1vw;
    position: fixed;
    align-items: center;
    left: 12vw;
}

.Separateds {
    margin-left: 2vw;
}

.select {
    width: 20vw !important;
}

.querys {
    margin-top: -1.5vh;
}

.flowmiddle {
    width: 90vw;
    height: 10vh;
    top: 25vh;
    left: 16vw;
    font-size: 1vw;
    position: fixed;
}

.flowbottom {
    width: 100vw;
    height: 70vh;
    /* margin-left: 20vw; */
    margin-top: -15vh;
}

.flowbottom .el-table .cell {
    width: 200px !important;
}
</style>