<!-- add by Liwh time:2024-09-01 description:轮播图管理页面 -->


<template xmlns="http://www.w3.org/1999/html">

  <el-container class="Wrapper">
    <el-container class="container">
      <div class="shang">
        <div class="comm">
          <div class="fen">


          </div>
          <el-button class="addStores" type="primary" :icon="Edit" @click="goToAddsp">新增轮播图</el-button>
          <div class="alluserbody">
            <el-table v-loading="loading" element-loading-text="加载中，请稍后..."
                      style="width: 100%; margin-left: 10%;" height="85%" :data="statImgInfos" class="orders">
              <el-table-column label="序号" width="100vw">
                <template v-slot="{ $index }">
                  {{ $index + 1 }}
                </template>
              </el-table-column>
              <el-table-column prop="img1" label="轮播图" width="150vw">
                <template #default="scope">
                  <div style="width: 2000px;height: 707px;display: flex; align-items:center;">
                    <el-image fit="fill" lazy :src="`/api/common/download?name=${scope.row.img1}`" error>
                      <el-icon><icon-picture /></el-icon>
                    </el-image>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="创建时间" width="180vw">
              </el-table-column>

              <el-table-column prop="typeNumber" :formatter="(row,column) => dictChange(row,column,'typeNumber')" label="关联内容" width="150vw">
              </el-table-column>
              <el-table-column prop="details" label="文章内容/商品" :formatter="(row,column) => showContent(row,column)" width="250vw">
              </el-table-column>
              <el-table-column prop="" label="操作">
                <template #default="scope">
                  <el-button type="primary" class="edit"
                             @click="goToEdit(scope.row)">编辑</el-button>
                  <el-button type="danger"
                             @click="deleteVideo(scope.row.id)">删除</el-button>
                  <!-- <el-button type="danger" >删除</el-button> -->
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="alluserfoots">
            <div class="demo-pagination-blocks">
              <el-pagination v-model:current-page="currentPage4" v-model:page-size="pageSize4"
                             @size-change="handleSizeChangese" @current-change="handleCurrentChangese"
                             :current-page="currentPage4" :page-sizes="[10, 20, 30, 40]" :page-size="10"
                             layout="total, sizes, prev, pager, next, jumper" :total="tatol">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </el-container>
  </el-container>


</template>

<script setup>
import {reactive, ref} from 'vue'
import {Edit} from '@element-plus/icons-vue'
import {toRaw} from '@vue/reactivity'
import {useRouter} from 'vue-router'
import {ElMessage, ElMessageBox} from 'element-plus'
import instance from '../network/storester.js'

const loading = ref(true)
const images = ref([])
const image = ref([])
const router = useRouter()
const statImgInfo = ref(null)
const statImgInfos = ref([])
const page = reactive({ pageNum: 1, pageSize: 10 });
const tatol = ref(0);


const handleSizeChangese = (val) => {
  page.pageSize = val;
  console.log(val);
  // if (value2.value === true) {
  //     offshelf()
  //     return
  // }
  loading.value = true;
  siftsea();
};
//当前页改变时触发
const handleCurrentChangese = (val) => {
  page.pageNum = val;
  console.log(val);
  // if (value2.value === true) {
  //     offshelf()
  //     return
  // }
  loading.value = true;
  siftsea();
};

const dictChange=function (data,element,dictName){
  const col = element.property;
  if(data[col] == 1){
    return "文章";
  }else if(data[col] == 2){
    return '商品'
  }else{
    return ""
  }
}

const showContent =function (data,element,dictName){
  if(data['typeNumber'] == 1){
    return data['contentArticle'];
  }else if(data['typeNumber'] == 2){
    return data['productNames']
  }else{
    return ""
  }
}


const siftsea = () => {
  instance.get(`/api/carousels/getCarouselAndRelativeProductNames?pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
      .then(response => {
        console.log(response.data.data);
        statImgInfo.value = response.data.data.carouselVOList;
        statImgInfos.value = toRaw(statImgInfo.value);
        tatol.value = response.data.data.totalCount
        console.log(statImgInfos.value);
        images.value.splice(0, images.value.length);
        for (let i = 0; i < statImgInfos.value.length; i++) {
          images.value.splice(i, images.value.length)
          images.value.push(statImgInfos.value[i].img1)
        }
        loading.value = false
        console.log(images.value);
        fetchData();
      })
      .catch(error => {
        console.error(error);
        ElMessage({
          message: '请重新登录',
          type: 'error',
        })
        router.push('./')
      });
}

instance.get(`/api/carousels/getCarouselAndRelativeProductNames?pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
    .then(response => {
      console.log(response.data.data);
      statImgInfo.value = response.data.data.carouselVOList;
      statImgInfos.value = toRaw(statImgInfo.value);
      tatol.value = response.data.data.totalCount
      console.log(statImgInfos.value);
      images.value.splice(0, images.value.length);
      for (let i = 0; i < statImgInfos.value.length; i++) {
        images.value.splice(i, images.value.length)
        images.value.push(statImgInfos.value[i].img1)
      }
      loading.value = false
      console.log(images.value);
      fetchData();
    })
    .catch(error => {
        console.error(error);
        ElMessage({
            message: '请重新登录',
            type: 'error',
        })
        router.push('./')
    });
const goToAddsp = () => {
    router.push('./editStatimg')
}
//点击跳转，路由传参到editvideo页面
const goToEdit = (score) => {
  console.log(score)
    router.push({
        path: './editStatimg',
        query: {
            id: score.id,
            productNames: score.productNames,
            typeNumber:score.typeNumber,
            contentArticle:score.contentArticle,
            productIds:score.productIds,
            img1:score.img1

        }
    })
}
//删除视频
const deleteVideo = (id) => {
    console.log(id)
    ElMessageBox.confirm(
        '是否删除该轮播图马?',
        '提示',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            //传入id给api
            instance.delete('/api/carousels/' + id)
                .then(response => {
                    ElMessage({
                        message: '删除成功',
                        type: 'success',
                    })
                    window.location.reload()
                })
                .catch(error => {
                    console.error(error);
                })
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消删除',
            })
        })

}
async function fetchData() {

    console.log(image.value);
    image.value.splice(0, image.value.length);
    // image.value = []
    for (let j = 0; j < images.value.length; j++) {
        try {
            const response = await instance.get(`/api/common/download?name=${images.value[j]}`);
            image.value.splice(j, image.value.length);
            image.value.push(response.request.responseURL);
        } catch (error) {
            console.log(error);
        }
    }
    console.log(image.value);
}
</script>

<style>

.addStores {
  position: fixed;
  top: 14vh;
  right: 82vw;
}
.container {
  position: fixed;
  top: 0;
  left: 15vw;
}



.main {
  position: fixed;
  top: 10vh;
  left: 11vw;
  width: 85vw;
  height: 90vh;
}

.OrderNumber {
  /* top: -46.5vh !important; */
  left: -3vw !important;
}

.orders {
  position: fixed;
  margin-top: 15vh;
  left: 8vw;
}

.fen .el-select {
  position: fixed;
  top: 14vh;
  left: 28vw;
  width: 14vw;
  font-size: 12px;
}

.comm .el-table {
  position: fixed;
  top: 6%;
  left: 1%;
}

.comm .el-table tr.el-table__row {
  height: 65px !important;
  font-size: 12px !important;
}

.comm .el-button {
  height: 24px !important;
  font-size: 10px !important;
}

.shang {
  /* width: 85%; */
  font-size: 12px !important;
}

.el-table {
  width: 100%;
}

.el-table__header-wrapper table,
.el-table__body-wrapper table {
  width: 100% !important;
}

.el-table__body,
.el-table__footer,
.el-table__header {
  table-layout: auto;
}

.addproduct {
  position: fixed;
  top: 14vh;
  right: 20vw;
}

.addproducts {
  position: fixed;
  top: 14vh;
  right: 15vw;
}

.fen {
  position: fixed;
  top: 13vh;
  z-index: 1;
}

/* .fen .el-switch {
    top: -46.5vh !important;
    left: -5vw !important;
} */

.fen .el-input {
  width: 15vw;
}

.fen .switchs {
  left: 30vw;
}

.alluserfoots .demo-pagination-blocks {
  position: fixed;
  width: 100vw;
  top: 94vh;
  height: 10vh;
  left: 30vw;
  /* background-color: red; */
}
</style>
