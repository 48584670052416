<!-- 手续管理 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <el-main class="main">
                <div class="main_item">
                    <div class="Commission">
                        <el-button type="primary" @click="commission" class="Commissiondraftse">添加手续</el-button>
                        <el-table :data="Commission" height="75vh" style="width: 100%" v-loading="loading"
                            element-loading-text="加载中，请稍后...">
                            <el-table-column prop="feeId" label="ID" width="200px" />
                            <el-table-column label="门店ID" width="200px">
                                <template #default="scope">
                                    <div v-if="scope.row.merchantId !== 0">{{ scope.row.merchantId }}</div>
                                    <div v-if="scope.row.merchantId === 0">全局配置 </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="feeAmount" label="手续费" width="200px">
                                <template #default="scope">
                                    <div v-if="scope.row.feeType === 0">固定金额</div>
                                    <div v-if="scope.row.feeType === 'percentage'">{{ scope.row.feeAmount * 100 }}%
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="maxAmount" label="最大提现金额" width="200px" />
                            <el-table-column prop="minAmount" label="最小提现金额" width="200px" />
                            <el-table-column prop="status" label="发布状态" width="200px">
                                <template #default="scope">
                                    <el-switch v-model="scope.row.active" class="ml-2"
                                        @change="handleStatusChange(scope.row.merchantId, scope.row.active, scope.row.feeId)"
                                        style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
                                </template>
                            </el-table-column>
                            <el-table-column prop="withdrawalMethod" label="提现方式" width="200px" />
                            <el-table-column fixed="right" style="z-index: 1;" prop="createTime" label="操作"
                                width="200px">
                                <template #default="scope">
                                    <el-button type="primary" @click="handleEdit(scope.row.feeId)">编辑</el-button>
                                    <el-button v-if="scope.row.merchantId !== 0" type="danger"
                                        @click="handleDelete(scope.row.feeId)">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div v-if="showModals" class="modal">
                            <div class="modal-content">
                                <span class="close" @click="toggleModals">&times;</span>
                                <span style="font-size: 1.5rem;">手续费</span>
                                <el-form :model="forms" label-width="120px">
                                    <el-form-item label="手续账户" style="width: 30vw;">
                                        <el-select v-model="forms.withdrawalMethod" placeholder="请选择账户类型"
                                            @change="selec">
                                            <el-option label="支付宝" value="支付宝" />
                                            <el-option label="微信" value="微信" />
                                            <el-option label="银行卡" value="银行卡" />
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="选择门店" style="width: 30vw;">
                                        <el-select v-model="value1" placeholder="请选择门店" @change="addsd">
                                            <el-option v-for="item in store" :key="item.value" :label="item.name"
                                                :value="item.id" />
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="最大提现金额" style="width: 30vw;">
                                        <el-input maxlength="11" v-model="forms.maxAmount"
                                            oninput="if(value.length>11)value=value.slice(0,11)"
                                            onkeyup="this.value = this.value.replace(/[^\d]/g,'');" size="large"
                                            clearable />
                                    </el-form-item>
                                    <el-form-item label="最小提现金额" style="width: 30vw;">
                                        <el-input maxlength="11" v-model="forms.minAmount"
                                            oninput="if(value.length>11)value=value.slice(0,11)"
                                            onkeyup="this.value = this.value.replace(/[^\d]/g,'');" size="large"
                                            clearable />
                                    </el-form-item>
                                    <el-form-item label="手续费" style="width: 30vw;">
                                        <el-input maxlength="11" v-model="forms.feeAmount"
                                            oninput="if(value.length>11)value=value.slice(0,11)"
                                            onkeyup="this.value = this.value.replace(/[^\d]/g,'');" size="large"
                                            clearable />
                                    </el-form-item>
                                    <el-form-item label="单位" style="width: 30vw;">
                                        <el-select v-model="forms.feeType" placeholder="请选择单位">
                                            <el-option label="百分比" value="1" />
                                            <el-option label="金额" value="2" />
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="是否启用" style="width: 30vw;">
                                        <el-switch v-model="active" class="ml-2"
                                            style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" @click="onSubmits">确定</el-button>
                                        <el-button @click="toggleModals">取消</el-button>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                        <div v-if="showModalss" class="modal">
                            <div class="modal-content">
                                <span class="close" @click="toggleModalss">&times;</span>
                                <span style="font-size: 1.5rem;">编辑手续费</span>
                                <el-form :model="form" label-width="120px">
                                    <el-form-item label="手续账户" style="width: 30vw;">
                                        <el-select v-model="form.withdrawalMethod" placeholder="请选择账户类型" disabled
                                            @change="selec">
                                            <el-option label="支付宝" value="支付宝" />
                                            <el-option label="微信" value="微信" />
                                            <el-option label="银行卡" value="银行卡" />
                                        </el-select>
                                    </el-form-item>
                                    <!-- <el-form-item label="选择门店" style="width: 30vw;">
                                        <el-select v-model="value1" placeholder="请选择门店" @change="addsd">
                                            <el-option v-for="item in store" :key="item.value" :label="item.name"
                                                :value="item.id" />
                                        </el-select>
                                    </el-form-item> -->
                                    <el-form-item label="最大提现金额" style="width: 30vw;">
                                        <el-input maxlength="11" v-model="form.maxAmount"
                                            oninput="if(value.length>11)value=value.slice(0,11)"
                                            onkeyup="this.value = this.value.replace(/[^\d]/g,'');" size="large"
                                            clearable />
                                    </el-form-item>
                                    <el-form-item label="最小提现金额" style="width: 30vw;">
                                        <el-input maxlength="11" v-model="form.minAmount"
                                            oninput="if(value.length>11)value=value.slice(0,11)"
                                            onkeyup="this.value = this.value.replace(/[^\d]/g,'');" size="large"
                                            clearable />
                                    </el-form-item>
                                    <el-form-item label="手续费" style="width: 30vw;">
                                        <el-input maxlength="11" v-model="form.feeAmount"
                                            oninput="if(value.length>11)value=value.slice(0,11)"
                                            onkeyup="this.value = this.value.replace(/[^\d]/g,'');" size="large"
                                            clearable />
                                    </el-form-item>
                                    <el-form-item label="单位" style="width: 30vw;">
                                        <el-select v-model="form.feeType" placeholder="请选择单位">
                                            <el-option label="百分比" value="1" />
                                            <el-option label="金额" value="2" />
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="是否启用" style="width: 30vw;">
                                        <el-switch v-model="actives" class="ml-2"
                                            style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" />
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" @click="onSubmitss">确定</el-button>
                                        <el-button @click="toggleModalss">取消</el-button>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                    </div>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script setup>
import Menu from '../navbar/Menu.vue'
import { ref, toRaw } from 'vue'
import { useRouter } from 'vue-router'
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox } from 'element-plus'

const showModals = ref(false);
const showModalss = ref(false);
const router = useRouter()
const value = ref('')
const value1 = ref('')
const loading = ref(true)
const form = ref({
    feeAmount: '',
    feeType: '',
    maxAmount: '',
    minAmount: '',
    withdrawalMethod: '',
    feeId: ''
})
const forms = ref({
    feeAmount: '',
    feeType: '',
    maxAmount: '',
    minAmount: '',
    withdrawalMethod: '',
    feeId: ''
})
const handleStatusChange = (merchantId, active, feeId) => {
    console.log(merchantId, active);
    instance.get(`/api/withdrawal/fees/getByIdWithdrawal?feeId=${feeId}`)
        .then(response => {
            console.log(response.data.data.feeType);
            if (active == true) {
                response.data.data.active = 1
            } else {
                response.data.data.active = 0
            }
            if (response.data.data.feeType === 'fixed') {
                response.data.data.feeType = '2'
            } else {
                response.data.data.feeType = '1'
            }
            const withdrawalFeesParam = {
                active: response.data.data.active,
                feeAmount: response.data.data.feeAmount,
                feeType: response.data.data.feeType,
                maxAmount: response.data.data.maxAmount,
                minAmount: response.data.data.minAmount,
                withdrawalMethod: response.data.data.withdrawalMethod,
                merchantId: merchantId,
                feeId: feeId
            }
            console.log(withdrawalFeesParam);
            instance.put('/api/withdrawal/fees/updateWithdrawal', withdrawalFeesParam)
                .then(response => {
                    console.log(response);
                    ElMessage.success("修改成功！");
                }).catch(error => {
                    console.log(error);
                })
        }).catch(err0r => {
            console.log(error);
        })

}
const actives = ref(true)
const active = ref('')
const Commission = ref([])
const store = ref([])
const Commissions = ref(null)
instance.get(`/api/stores`)
    .then(response => {
        console.log(response.data);
        store.value = response.data;
        console.log(store.value);

    })
    .catch(error => {
        console.log(error);
        ElMessage({
            showClose: true,
            message: '请登录',
            type: 'error',
        })
        router.push('./')
    })
instance.get(`/api/withdrawal/fees/getWithdrawalFeesList`)
    .then(response => {
        Commissions.value = response.data.data
        Commission.value = toRaw(Commissions.value)
        console.log(response);
        for (let i = 0; i < Commission.value.length; i++) {
            if (Commission.value[i].active == '0') {
                Commission.value[i].active = false
            }
            if (Commission.value[i].active == '1') {
                Commission.value[i].active = true
            }
        }
        loading.value = false
    }).catch(error => {
        console.log(error);
    })
const commission = (id) => {
    showModals.value = true
}
// const selec = () => {
//     console.log(forms.value);
//     if (forms.value.withdrawalMethod == '1') {
//         console.log('支付宝');
//         instance.get(``)
//     }
//     if (forms.value.withdrawalMethod == '2') {
//         console.log('微信');
//     }
//     if (forms.value.withdrawalMethod == '3') {
//         console.log('银行卡');
//     }
// }
const toggleModalss = () => {
    showModalss.value = false
    form.value = ref({
        feeAmount: '',
        feeType: '',
        maxAmount: '',
        minAmount: '',
        withdrawalMethod: ''
    })
}
const toggleModals = () => {
    showModals.value = false
    forms.value = ref({
        feeAmount: '',
        feeType: '',
        maxAmount: '',
        minAmount: '',
        withdrawalMethod: ''
    })
}
const feeAmount = ref('')
const feeType = ref('')
const activew = ref('')
const onSubmits = () => {
    console.log('submit!');
    console.log(feeAmount.value)
    console.log(forms.value);
    console.log(value1.value);
    console.log(actives.value);
    console.log(forms.value.feeType);
    if (forms.value.feeAmount === '' || forms.value.feeType === '' || forms.value.maxAmount === '' || forms.value.minAmount === '' || forms.value.withdrawalMethod === '' || value1.value === '') {
        ElMessage({
            showClose: true,
            message: '请填写完整',
            type: 'error',
        })
        return
    }
    if (actives.value === true) {
        // console.log('666');
        activew.value = 1
    } else {
        activew.value = 0
    }
    if (forms.value.feeType === '1') {
        // console.log('666'); feeAmount
        feeAmount.value = forms.value.feeAmount / 100
        feeType.value = 'percentage'
    } else {
        feeAmount.value = forms.value.feeAmount
        feeType.value = 'fixed'
    }

    console.log(forms.value);
    const withdrawalFeesParam = {
        active: activew.value,
        feeAmount: feeAmount.value,
        feeType: feeType.value,
        maxAmount: forms.value.maxAmount,
        minAmount: forms.value.minAmount,
        withdrawalMethod: forms.value.withdrawalMethod,
        merchantId: value1.value
    }
    console.log(withdrawalFeesParam);
    instance.post(`/api/withdrawal/fees/insertWithdrawalFees`, withdrawalFeesParam)
        .then(response => {
            console.log(response.data);
            if (response.data.code === 0) {
                ElMessage({
                    message: `${response.data.msg}`,
                    type: 'error',
                })
                return;
            }
            ElMessage({
                showClose: true,
                message: '添加成功',
                type: 'success',
            })
            loading.value = true
            instance.get(`/api/withdrawal/fees/getWithdrawalFeesList`)
                .then(response => {
                    Commissions.value = response.data.data
                    Commission.value = toRaw(Commissions.value)
                    console.log(Commission.value);
                    for (let i = 0; i < Commission.value.length; i++) {
                        if (Commission.value[i].active === 0) {
                            Commission.value[i].active = false
                        } else {
                            Commission.value[i].active = true
                        }
                    }
                    loading.value = false
                    toggleModals()
                }).catch(error => {
                    console.log(error);
                })

        }).catch(error => {
            console.log(error);
        })
}

const handleEdit = (feeId) => {
    console.log(feeId);
    showModalss.value = true
    // feeId.value = feeId
    instance.get(`/api/withdrawal/fees/getByIdWithdrawal?feeId=${feeId}`)
        .then(response => {
            console.log(response.data.data.active);
            if (response.data.data.active == '1') {
                actives.value = true
            } else {
                actives.value = false
            }
            if (response.data.data.feeType === 'fixed') {
                form.value.feeType = '2'
                form.value.feeAmount = response.data.data.feeAmount
            } else {
                form.value.feeType = '1'
                form.value.feeAmount = response.data.data.feeAmount * 100
            }
            value.value = response.data.data.merchantId


            form.value.maxAmount = response.data.data.maxAmount
            form.value.minAmount = response.data.data.minAmount
            form.value.withdrawalMethod = response.data.data.withdrawalMethod
            form.value.feeId = response.data.data.feeId
        })
}
const activews = ref('')
const feeAmounts = ref('')
const feeTypes = ref('')
const onSubmitss = () => {
console.log(form.value.feeType);
    if (form.value.feeAmount === '' || form.value.feeType === '' || form.value.maxAmount === '' || form.value.minAmount === '' || form.value.withdrawalMethod === '') {
        ElMessage({
            showClose: true,
            message: '请填写完整',
            type: 'error',
        })
        return
    }
    if (actives.value === true) {
        // console.log('666');
        activews.value = 1
    } else {
        activews.value = 0
    }
    if (form.value.feeType === '1') {
        console.log('666');
        console.log(form.value.feeAmount / 100);
        feeAmounts.value = form.value.feeAmount / 100
        feeTypes.value = 'percentage'
    } else {
        feeAmounts.value = form.value.feeAmount
        feeTypes.value = 'fixed'
    }
    // if (form.value.feeType === '1') {
    //     // console.log('666'); feeAmount
    //     feeAmounts.value = forms.value.feeAmount / 100
    //     feeTypes.value = 'percentage'
    //     console.log(feeAmounts.value);
    // } else {
    //     feeAmounts.value = form.value.feeAmount
    //     feeTypes.value = 'fixed'
    // }

    const withdrawalFeesParam = {
        active: activews.value,
        feeAmount: feeAmounts.value,
        feeType: feeTypes.value,
        maxAmount: form.value.maxAmount,
        minAmount: form.value.minAmount,
        withdrawalMethod: form.value.withdrawalMethod,
        merchantId: value.value,
        feeId: form.value.feeId
    }
    instance.put('/api/withdrawal/fees/updateWithdrawal', withdrawalFeesParam)
        .then(response => {
            if (response.data.code === 0) {
                ElMessage({
                    message: `${response.data.msg}`,
                    type: 'error',
                })
                return;
            }
            ElMessage({
                showClose: true,
                message: '编辑成功',
                type: 'success',
            })
            loading.value = true
            instance.get(`/api/withdrawal/fees/getWithdrawalFeesList`)
                .then(response => {
                    Commissions.value = response.data.data
                    Commission.value = toRaw(Commissions.value)
                    console.log(Commission.value);
                    for (let i = 0; i < Commission.value.length; i++) {
                        if (Commission.value[i].active === 0) {
                            Commission.value[i].active = false
                        } else {
                            Commission.value[i].active = true
                        }
                    }
                    loading.value = false
                    toggleModalss()
                }).catch(error => {
                    console.log(error);
                })
        })
}
const handleDelete = (feeId) => {
    ElMessageBox.confirm(
        '确认删除该手续配置?',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            instance.delete(`/api/withdrawal/fees/deleteWithdrawalByID?feeId=${feeId}`)
                .then(response => {
                    ElMessage({
                        type: 'success',
                        message: '删除成功',
                    })
                    loading.value = true
                    instance.get(`/api/withdrawal/fees/getWithdrawalFeesList`)
                        .then(response => {
                            Commissions.value = response.data.data
                            Commission.value = toRaw(Commissions.value)
                            console.log(Commission.value);
                            for (let i = 0; i < Commission.value.length; i++) {
                                if (Commission.value[i].active === 0) {
                                    Commission.value[i].active = false
                                } else {
                                    Commission.value[i].active = true
                                }
                            }
                            loading.value = false
                        }).catch(error => {
                            console.log(error);
                        })
                }).catch(error => {
                    console.log(error);
                })
        })

        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消删除',
            })
        })
}
</script>

<style>
.container {
    position: fixed;
    top: 0;
    left: 15vw;
}



.Commission {
    width: 85vw;
    height: 90vh;
    margin-top: -15vh;
    margin-left: -6vw;
}

.main {
    position: fixed;
    top: 10vh;
    left: 15vw;
    width: 85vw;
    height: 90vh;
}

.Commissiondraftse {
    margin-left: 65vw;
}

.Commission .modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
}

.Commission .modal-content {
    background-color: #fefefe;
    margin: 8% 15% 15% 30%;
    padding: 20px;
    border: 1px solid #888;
    width: 40%;
    left: 15vw;
}

.Commission .el-table .cell {
    width: 200px !important;
}

.Commission .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.Commission .close:hover,
.Commission .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.Commission .el-table--border .el-table__inner-wrapper::after,
.el-table--border::after,
.el-table--border::before,
.el-table__inner-wrapper::before {
    z-index: 1;
}

.Commission .el-table__border-left-patch {
    z-index: 1;
}

.Commission .el-table__header-wrapper tr th.el-table-fixed-column--left,
.el-table__header-wrapper tr th.el-table-fixed-column--right {
    z-index: 1 !important;
}

.Commission .el-table:not(.el-table--border) .el-table__cell {
    z-index: 1;
}
</style>
