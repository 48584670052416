<!-- 首页 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <div class="homepage">
                <div class="homepageheader">
                    <el-row>
                        <el-col :span="4">
                            <el-card shadow="hover" style="width: 15vw; box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5);">
                                <div slot="header" class="clearfix">
                                    <span style="font-size: 1.5rem;">总销售金额 </span>
                                </div>
                                <div style="font-size: 18px;text-align: center; margin-top: 35px;">
                                    {{ totalSales }} 元
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="4">
                            <el-card shadow="hover"
                                style="width: 20vw; box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5); margin-left: 15vw;">
                                <div slot="header" class="clearfix">
                                    <span style="font-size: 1.5rem;">余额</span>
                                    <span style="margin-left: 2vw;"><el-button type="primary"
                                            @click="Withdrawal">提现</el-button>
                                    </span>
                                </div>
                                <div style="font-size: 18px;text-align: center; margin-top: 35px;">
                                    {{ balance }}元
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="4">
                            <el-card shadow="hover"
                                style="width: 15vw; box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5); margin-left: 36vw;">
                                <div slot="header" class="clearfix">
                                    <span style="font-size: 1.5rem;">已提现金额</span>
                                </div>
                                <div style="font-size: 18px;text-align: center; margin-top: 35px;">
                                    {{ totalCompletedWithdrawals }}元
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="4">
                            <el-card shadow="hover"
                                style="width: 15vw; box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5); margin-left: 54vw;">
                                <div slot="header" class="clearfix">
                                    <span style="font-size: 1.5rem;">提现中</span>
                                </div>
                                <div style="font-size: 18px;text-align: center; margin-top: 35px;">
                                    {{ totalPendingWithdrawals }}元
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                </div>
                <!-- <div><el-button>提现</el-button></div> -->
                <div class="homepagefooter">
                    <!--  -->
                    <el-table :data="records" style="width: 90vw;  height: 52vh; left: -1vw;" v-loading="loading">
                        <el-table-column prop="id" label="id" width="100" />
                        <el-table-column prop="recipientName" label="提现人" width="120" />
                        <el-table-column prop="amount" label="提现金额" width="120" />
                        <el-table-column prop="status" label="提现状态" width="120">
                            <template #default="scope">
                                <el-tag v-if="scope.row.status === 'pending'" type="info">待处理</el-tag>
                                <el-tag v-else-if="scope.row.status === 'completed'" type="success">已完成</el-tag>
                                <el-tag v-else-if="scope.row.status === 'failed'" type="error">已驳回</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="withdrawalMethod" label="提现方式" width="120" />
                        <el-table-column prop="createTime" label="提现时间" width="220" />
                        <el-table-column prop="remarks" label="提现备注" width="120" />
                        <el-table-column prop="failedReason" label="驳回原因">
                        </el-table-column>
                    </el-table>
                </div>
                <div class="homepagefoot">
                    <div class="demo-pagination-blocks" style="margin-left: 15vw;">
                        <el-pagination v-model:current-page="currentPage4" v-model:page-size="pageSize4"
                            @size-change="handleSizeChangese" @current-change="handleCurrentChangese"
                            :current-page="currentPage4" :page-sizes="[10, 20, 30, 40]" :page-size="10"
                            layout="total, sizes, prev, pager, next, jumper" :total="tatol">
                        </el-pagination>
                    </div>
                </div>
                <div v-if="showModals" class="modal">
                    <div class="modal-content">
                        <span class="close" @click="toggleModals">&times;</span>
                        <span style="font-size: 1.5rem;">提现</span>
                        <el-form :model="forms" label-width="120px" style="margin-top: 5vh;">
                            <el-form-item label="提现方式" style="width: 30vw;">
                                <el-select v-model="forms.withdrawalMethod" @change="calculateFee"
                                    placeholder="请选择提现方式">
                                    <el-option label="支付宝" value="1" />
                                    <el-option label="微信" value="2" />
                                    <el-option label="银行卡" value="3" />
                                </el-select>
                            </el-form-item>
                            <el-form-item label="信息存储" style="width: 30vw;" v-if="forms.withdrawalMethod === '3'">
                                <el-select v-model="forms.storage" placeholder="请选择提现方式" clearable @change="storage">
                                    <el-option label="新增" value="1" v-if="New === true" />
                                    <el-option label="修改" value="2" />
                                    <el-option label="获取" value="3" />
                                </el-select>
                            </el-form-item>
                            <el-form-item label="提现人" style="width: 30vw;">
                                <el-input v-model="forms.recipientName" />
                            </el-form-item>
                            <el-form-item label="提现金额" style="width: 30vw;" @change="calculateFee">
                                <el-input v-model="forms.amount" onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
                                    size="large" clearable /> <el-button type="primary"
                                    @click="Payouts">全部提现</el-button>
                                <p v-if="forms.amountHand !== '' && forms.amountHand !== 0"
                                    style="color: red;font-size: 12px;"><span v-if="forms.fixed === 'fixed'">手续{{
                            actualAmount }}元</span> </p>
                                <p v-if="forms.amountHand !== '' && forms.amountHand !== '0' && forms.fixed === 'percent'"
                                    style="color: red;font-size: 12px;"> 手续{{
                            actualAmount * 100 }}%</p>
                            </el-form-item>
                            <el-form-item label="实际到账金额" style="width: 30vw;" v-if="forms.amount !== ''">
                                <el-input-number v-model="forms.amountHand" disabled />
                            </el-form-item>

                            <el-form-item label="收款账户类型" style="width: 30vw;">
                                <el-select v-model="forms.accountType" placeholder="请选择账户类型">
                                    <el-option label="个人账户" value="1" />
                                    <el-option label="企业账户" value="2" />
                                </el-select>
                            </el-form-item>
                            <el-form-item label="收款人开户行" v-if="forms.withdrawalMethod === '3'" style="width: 30vw;">
                                <el-input v-model="forms.bank" />
                            </el-form-item>
                            <el-form-item label="收款人账户" v-if="forms.withdrawalMethod === '3'" style="width: 30vw;">
                                <el-input v-model="forms.account" />
                            </el-form-item>
                            <el-form-item label="收款人电话" v-if="forms.withdrawalMethod === '3'" style="width: 30vw;">
                                <el-input @change="checkPhones" maxlength="11" placeholder="请输入电话号码"
                                    v-model="forms.bankAccount" oninput="if(value.length>11)value=value.slice(0,11)"
                                    onkeyup="this.value = this.value.replace(/[^\d]/g,'');" size="large" clearable />
                            </el-form-item>
                            <el-form-item label="收款码"
                                v-if="forms.withdrawalMethod === '1' || forms.withdrawalMethod === '2'"
                                style="width: 50vw;">
                                <el-upload v-model:file-list="fileList"
                                    action="https://www.jingtengyiliao.com/api/announcement/upload" name="file"
                                    limit="1" list-type="picture-card" :on-preview="handlePictureCardPreview"
                                    :on-remove="handleRemove" :on-success="handleSuccess" :before-upload="beforeUpload"
                                    :headers="headers">
                                    <el-icon>
                                        <Plus />
                                    </el-icon>
                                </el-upload>

                                <el-dialog v-model="dialogVisible">
                                    <img w-full :src="dialogImageUrl" alt="Preview Image" />
                                </el-dialog>
                            </el-form-item>
                            <el-form-item label="提现备注" style="width: 30vw;">
                                <el-input v-model="forms.remarks" type="textarea" />
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="onSubmits">确定</el-button>
                                <el-button @click="toggleModals">取消</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </el-container>
    </el-container>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { ref, toRaw, reactive, onMounted, onUnmounted } from "vue";
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { Plus } from '@element-plus/icons-vue'

// const checkPhone = () => {
//     console.log(forms.amount);
// }
const tatol = ref('')
const checkPhones = () => {
    console.log(forms.bankAccount);
    if (forms.bankAccount != '') {
        if (!reg.test(forms.bankAccount)) {
            ElMessage({
                type: 'error',
                message: '请输入正确的手机号',
            })
            forms.bankAccount = ''
        }
    }
}
const loading = ref(true)
const dialogData = ref('')
const reg = /^1[3456789]\d{9}$/
const totalIncomea = ref(null)
const showModals = ref(false);
const totalIncomes = ref([])
const totalSales = ref('')
const totalIncome = ref('')
const totalCompletedWithdrawals = ref('')
const totalPendingSales = ref('')
const balance = ref('')
const totalPendingWithdrawals = ref('')
const totalWithdrawn = ref('')
const fileList = ref([])
const withdrawalMethod = ref('')
const actualAmount = ref('')
const page = reactive({ pageNum: 1, pageSize: 10 });
const forms = ref({
    recipientName: '',
    amount: '',
    withdrawalMethod: '',
    account: '',
    remarks: '',
    bank: '',
    accountType: '',
    fee: '',
    actualAmount: '',
    amountHand: '',
    feeAmount: '',
    storage: '',
    fixed: '',
    bankAccount: ''
})
const Payouts = () => {
    instance.get(`/api/merchant/balance/selectById/merchantBalance?merchantId=${localStorage.getItem('sysStoreId')}`)
        .then(response => {
            console.log(response.data.data.balance);
            forms.value.amount = response.data.data.balance
            calculateFee()
        }).catch(error => {
            console.log(error);
        })
}
instance.get(`/api/merchant/balance/selectById/merchantBalance?merchantId=${localStorage.getItem('sysStoreId')}`)
    .then(response => {
        totalIncomea.value = response.data.data
        totalIncomes.value = toRaw(totalIncomea.value)
        totalIncome.value = totalIncomes.value.totalIncome
        totalCompletedWithdrawals.value = totalIncomes.value.totalCompletedWithdrawals
        totalPendingSales.value = totalIncomes.value.totalPendingSales
        totalPendingWithdrawals.value = totalIncomes.value.totalPendingWithdrawals
        balance.value = totalIncomes.value.balance
        totalWithdrawn.value = totalIncomes.value.totalWithdrawn
        totalSales.value = totalIncomes.value.totalSales
        console.log(totalSales.value);
    }).catch(error => {
        console.log(error);
    })
const record = ref(null)
const records = ref([])
instance.get(`/api/withdrawal/records/getWithdrawalRecordsById?merchantId=${localStorage.getItem('sysStoreId')}&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
    .then(response => {
        console.log(response);
        record.value = response.data.data.records
        records.value = toRaw(record.value)
        tatol.value = response.data.data.totalCount
        loading.value = false
        // console.log(records.value);
    }).catch(error => {
        console.log(error);
    })
const calculateFee = () => {
    console.log('jinr');
    console.log(forms.withdrawalMethod);
    if (forms.value.withdrawalMethod === '') {
        ElMessage({
            message: `请先选择提现方式`,
            type: 'error'
        });
        return;
    }
    if (forms.value.amount === '') {
        return;
    }
    if (forms.value.withdrawalMethod === '3') {
        instance.get(`/api/withdrawal/fees/getAmountInhHand?merchantId=${localStorage.getItem('sysStoreId')}&amount=${forms.value.amount}`)
            .then(response => {
                console.log(response.data);
                if (response.data.code === 0) {
                    ElMessage({
                        message: `${response.data.msg}`,
                        type: 'error'
                    });
                    return;
                }
                forms.value.fixed = response.data.data.feeType
                forms.value.amountHand = response.data.data.amountHand
                actualAmount.value = response.data.data.feeAmount
                console.log(actualAmount.value);

                console.log(forms.value.fixed);
                return;
            }).catch(error => {
                console.log(error);
            })
    }
    if (forms.value.withdrawalMethod === '2') {
        instance.get(`/api/withdrawal/fees/getWeChatHand?merchantId=${localStorage.getItem('sysStoreId')}&amount=${forms.value.amount}`)
            .then(response => {
                console.log(response.data);
                if (response.data.code === 0) {
                    ElMessage({
                        message: `${response.data.msg}`,
                        type: 'error'
                    });
                    return;
                }
                forms.value.amountHand = response.data.data.amountHand
                actualAmount.value = response.data.data.feeAmount
                console.log(actualAmount.value);
                forms.value.fixed = response.data.data.feeType
                console.log(forms.value.amountHand);
                return;
            }).catch(error => {
                console.log(error);
            })
    }
    if (forms.value.withdrawalMethod === '1') {
        instance.get(`/api/withdrawal/fees/getAlipayHand?merchantId=${localStorage.getItem('sysStoreId')}&amount=${forms.value.amount}`)
            .then(response => {
                console.log(response.data);
                if (response.data.code === 0) {
                    ElMessage({
                        message: `${response.data.msg}`,
                        type: 'error'
                    });
                    return;
                }
                forms.value.amountHand = response.data.data.amountHand
                actualAmount.value = response.data.data.feeAmount
                console.log(actualAmount.value);
                forms.value.fixed = response.data.data.feeType
                console.log(forms.value.amountHand);
                return;
            }).catch(error => {
                console.log(error);
            })
    }
}
const New = ref(true)
instance.get(`/api/merchant/bankAccount/getMerchant/BankAccountById?merchantId=${localStorage.getItem('sysStoreId')}`)
    .then(response => {
        if (response.data.data.length !== 0) {
            New.value = false
            console.log(New.value);
         }
    })
const storage = () => {
    instance.get(`/api/merchant/bankAccount/getMerchant/BankAccountById?merchantId=${localStorage.getItem('sysStoreId')}`)
        .then(response => {
            console.log(forms.value.storage);
            if (response.data.data.length === 0) {
                if (forms.value.storage === '3' || forms.value.storage === '2') {
                    ElMessage({
                        message: `暂无银行卡信息，请选新增`,
                        type: 'error'
                    });
                    return;
                }
            }
            console.log(response);
            if (forms.value.storage === '3' || forms.value.storage === '2') {
                forms.value.recipientName = response.data.data[0].accountHolderName
                forms.value.remarks = response.data.data[0].remarks
                forms.value.bankAccount = response.data.data[0].phoneNumber
                forms.value.account = response.data.data[0].bankAccountNumber
                forms.value.bank = response.data.data[0].bankName
                console.log(forms.value.recipientName);
            }

        }).catch(error => {
            console.log(error);
        })
}
const Withdrawal = () => {
    showModals.value = true

}
const toggleModals = () => {
    fileList.value = []
    forms.value = ({
        recipientName: '',
        amount: '',
        withdrawalMethod: '',
        account: '',
        remarks: '',
        bank: '',
        accountType: '',
        fee: '',
        actualAmount: '',
        amountHand: '',
        feeAmount: '',
        storage: '',
        fixed: '',
        bankAccount: ''
    })
    showModals.value = false
}
const dialogImageUrl = ref(null);
const dialogVisible = ref(false);

// onRemove 事件处理函数
const handleRemove = (uploadFile, uploadFiles) => {
    console.log(uploadFile, uploadFiles);
};

// onPreview 事件处理函数
const handlePictureCardPreview = (uploadFile) => {
    dialogImageUrl.value = uploadFile.url;
    dialogVisible.value = true;
};
const add = ref(true)
const onSubmits = () => {
    add.value = true
    // console.log(forms.value.withdrawalMethod);
    // console.log(fileList.value);
    instance.get(`/api/merchant/bankAccount/getMerchant/BankAccountById?merchantId=${localStorage.getItem('sysStoreId')}`)
        .then(response => {
            // console.log(forms.value.storage);
            console.log(response.data.data);
            if (response.data.data.length === 0) {
                console.log('jinr');

                if (forms.value.storage === '3' || forms.value.storage === '2') {
                    ElMessage({
                        message: `暂无银行卡信息，请选新增`,
                        type: 'error'
                    });
                    add.value = false
                    return;
                }
                console.log(add.value + 2);
            }
            if (response.data.data.length !== 0) {
                if (forms.value.storage === '1') {

                    ElMessage({
                        message: `已有银行卡信息,不要再次新增`,
                        type: 'error'
                    });
                    add.value = false
                    console.log(add.value);
                    // return;
                }
                console.log(add.value + '1');
                // forms.value
            }
            if (forms.value.amount > balance.value) {
                ElMessage({
                    message: `金额大于可提现余额`,
                    type: 'error'
                });
                return;
            }
            if (forms.value.amountHand === 0 || forms.value.amountHand === '') {
                ElMessage({
                    message: `手续费不能为空`,
                    type: 'error'
                });
                return;
            }
            // if (forms.value.withdrawalMethod = '') {
            //     ElMessage({
            //         message: '请填写完整信息',
            //         type: 'error'
            //     });
            //     return;
            // }

            console.log(add.value);
            if (add.value === true) {
                if (forms.value.withdrawalMethod === '3') {
                    if (forms.value.account === '' || forms.value.accountType === '' || forms.value.bank === '' || forms.value.recipientName === '' || forms.value.amount === '') {
                        ElMessage({
                            message: '请填写完整信息',
                            type: 'error'
                        });
                        return;
                    }
                    if (forms.value.storage == '1') {
                        // console.log('新增');
                        const merchantBankAccountParam = {
                            accountHolderName: forms.value.recipientName,
                            bankAccountNumber: forms.value.account,
                            bankName: forms.value.bank,
                            merchantId: localStorage.getItem('sysStoreId'),
                            phoneNumber: forms.value.bankAccount,
                            remarks: forms.value.remarks
                        }
                        console.log(merchantBankAccountParam);
                        instance.post(`/api/merchant/bankAccount/addNew/MerchantBankAccount`, merchantBankAccountParam)
                            .then(response => {
                                console.log(response);
                            }).catch(error => {
                                console.log(error);
                            })
                    }
                    if (forms.value.storage === '2') {
                        const merchantBankAccountParam = {
                            accountHolderName: forms.value.recipientName,
                            bankAccountNumber: forms.value.account,
                            bankName: forms.value.bank,
                            id: 1,
                            merchantId: localStorage.getItem('sysStoreId'),
                            phoneNumber: forms.value.bankAccount,
                            remarks: forms.value.remarks
                        }
                        console.log(merchantBankAccountParam);
                        instance.put(`/api/merchant/bankAccount/updateModifyAccountById`, merchantBankAccountParam)
                            .then(response => {
                                console.log(response);
                            }).catch(error => {
                                console.log(error);
                            })
                    }
                    if (forms.value.accountType === '1') {
                        forms.value.accountType = '个人账户'
                    }
                    if (forms.value.accountType === '2') {
                        forms.value.accountType = '企业账户'
                    }

                    const withdrawalRecordsParam = {
                        recipientAccountNumber: forms.value.account,
                        amount: forms.value.amount,
                        amountHand: forms.value.amountHand,
                        bankAccount: forms.value.bankAccount,
                        feeType: forms.value.fixed,
                        withdrawalMethod: '银行卡',
                        remarks: forms.value.remarks,
                        recipientName: forms.value.recipientName,
                        openBanK: forms.value.bank,
                        rcipientAccountType: forms.value.accountType,
                        merchantId: localStorage.getItem('sysStoreId')
                    }
                    console.log(withdrawalRecordsParam);
                    instance.post(`/api/withdrawal/records/addNewWithdrawalRecords`, withdrawalRecordsParam)
                        .then(response => {
                            instance.get(`/api/merchant/balance/selectById/merchantBalance?merchantId=${localStorage.getItem('sysStoreId')}`)
                                .then(response => {
                                    totalIncomea.value = response.data.data
                                    totalIncomes.value = toRaw(totalIncomea.value)
                                    totalIncome.value = totalIncomes.value.totalIncome
                                    totalCompletedWithdrawals.value = totalIncomes.value.totalCompletedWithdrawals
                                    totalPendingSales.value = totalIncomes.value.totalPendingSales
                                    totalPendingWithdrawals.value = totalIncomes.value.totalPendingWithdrawals
                                    balance.value = totalIncomes.value.balance
                                    totalWithdrawn.value = totalIncomes.value.totalWithdrawn
                                    console.log(totalIncomes.value);
                                }).catch(error => {
                                    console.log(error);
                                })
                            instance.get(`/api/withdrawal/records/getWithdrawalRecordsById?merchantId=${localStorage.getItem('sysStoreId')}`)
                                .then(response => {
                                    record.value = response.data.data
                                    records.value = toRaw(record.value)
                                    console.log(records.value);
                                }).catch(error => {
                                    console.log(error);
                                })
                            ElMessage({
                                message: '提现成功',
                                type: 'success'
                            });
                            toggleModals()
                        }).catch(error => {
                            console.log(error);
                        })
                    return;
                }
                console.log(forms.value);
                if (forms.value.withdrawalMethod == '1' || forms.value.withdrawalMethod == '2') {
                    if (forms.value.accountType === '' || forms.value.recipientName === '' || forms.value.amount === '' || fileList.value.length === 0) {
                        ElMessage({
                            message: '请填写完整信息',
                            type: 'error'
                        });
                        return;
                    }
                    if (forms.value.accountType === '1') {
                        forms.value.accountType = '个人账户'
                    }
                    if (forms.value.accountType === '2') {
                        forms.value.accountType = '企业账户'
                    }
                    const picture = fileList.value[0].response.data
                    if (forms.value.withdrawalMethod == '1') {
                        const withdrawalRecordsParam = {
                            amount: forms.value.amount,
                            amountHand: forms.value.amountHand,
                            feeType: forms.value.fixed,
                            withdrawalMethod: '支付宝',
                            remarks: forms.value.remarks,
                            recipientName: forms.value.recipientName,
                            rcipientAccountType: forms.value.accountType,
                            paymentCode: picture,
                            merchantId: localStorage.getItem('sysStoreId')
                        }
                        console.log(withdrawalRecordsParam);
                        instance.post(`/api/withdrawal/records/addNewWithdrawalRecords`, withdrawalRecordsParam)
                            .then(response => {
                                instance.get(`/api/merchant/balance/selectById/merchantBalance?merchantId=${localStorage.getItem('sysStoreId')}`)
                                    .then(response => {
                                        totalIncomea.value = response.data.data
                                        totalIncomes.value = toRaw(totalIncomea.value)
                                        totalIncome.value = totalIncomes.value.totalIncome
                                        totalCompletedWithdrawals.value = totalIncomes.value.totalCompletedWithdrawals
                                        totalPendingSales.value = totalIncomes.value.totalPendingSales
                                        totalPendingWithdrawals.value = totalIncomes.value.totalPendingWithdrawals
                                        balance.value = totalIncomes.value.balance
                                        totalWithdrawn.value = totalIncomes.value.totalWithdrawn
                                        console.log(totalIncomes.value);
                                    }).catch(error => {
                                        console.log(error);
                                    })
                                instance.get(`/api/withdrawal/records/getWithdrawalRecordsById?merchantId=${localStorage.getItem('sysStoreId')}`)
                                    .then(response => {
                                        record.value = response.data.data
                                        records.value = toRaw(record.value)
                                        console.log(records.value);
                                    }).catch(error => {
                                        console.log(error);
                                    })
                                ElMessage({
                                    message: '提现成功',
                                    type: 'success'
                                });
                                toggleModals()
                            }).catch(error => {
                                console.log(error);
                            })
                    } else {
                        const withdrawalRecordsParam = {
                            amount: forms.value.amount,
                            amountHand: forms.value.amountHand,
                            feeType: forms.value.fixed,
                            withdrawalMethod: '微信',
                            remarks: forms.value.remarks,
                            recipientName: forms.value.recipientName,
                            rcipientAccountType: forms.value.accountType,
                            paymentCode: picture,
                            merchantId: localStorage.getItem('sysStoreId')
                        }
                        console.log(withdrawalRecordsParam);
                        instance.post(`/api/withdrawal/records/addNewWithdrawalRecords`, withdrawalRecordsParam)
                            .then(response => {
                                instance.get(`/api/merchant/balance/selectById/merchantBalance?merchantId=${localStorage.getItem('sysStoreId')}`)
                                    .then(response => {
                                        totalIncomea.value = response.data.data
                                        totalIncomes.value = toRaw(totalIncomea.value)
                                        totalIncome.value = totalIncomes.value.totalIncome
                                        totalCompletedWithdrawals.value = totalIncomes.value.totalCompletedWithdrawals
                                        totalPendingSales.value = totalIncomes.value.totalPendingSales
                                        totalPendingWithdrawals.value = totalIncomes.value.totalPendingWithdrawals
                                        balance.value = totalIncomes.value.balance
                                        totalWithdrawn.value = totalIncomes.value.totalWithdrawn
                                        console.log(totalIncomes.value);
                                    }).catch(error => {
                                        console.log(error);
                                    })
                                instance.get(`/api/withdrawal/records/getWithdrawalRecordsById?merchantId=${localStorage.getItem('sysStoreId')}`)
                                    .then(response => {
                                        record.value = response.data.data
                                        records.value = toRaw(record.value)
                                        console.log(records.value);
                                    }).catch(error => {
                                        console.log(error);
                                    })
                                ElMessage({
                                    message: '提现成功',
                                    type: 'success'
                                });
                                toggleModals()
                            }).catch(error => {
                                console.log(error);
                            })
                    }


                }
            }
        }).catch(error => {
            console.log(error);
        })


}

const handleSizeChangese = (val) => {
    page.pageSize = val;
    console.log(val);
    siftse();
};
//当前页改变时触发
const handleCurrentChangese = (val) => {
    page.pageNum = val;
    console.log(val);
    siftse();
};
const siftse = () => {
    console.log(page.pageNum);
    console.log(page.pageSize);
    loading.value = true
    instance.get(`/api/withdrawal/records/getWithdrawalRecordsById?merchantId=${localStorage.getItem('sysStoreId')}&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
        .then(response => {
            console.log(response);
            record.value = response.data.data.records
            records.value = toRaw(record.value)
            tatol.value = response.data.data.totalCount
            loading.value = false
            // console.log(records.value);
        }).catch(error => {
            console.log(error);
        })
}
</script>

<style>
* {
    margin: 0;
    padding: 0;
}

.container {
    position: fixed;
    top: 0;
    left: 15vw;
}

.homepage {
    margin-left: -4vw;
    margin-top: 11vh;
    width: 85vw;
    height: 80vh;
}

.homepageheader {
    width: 25vw;
    height: 30vh;
    /* box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.5); */
    margin-left: 4.5vw;
}

.homepagefooter {
    height: 53vh;
    margin-left: 4.5vw;
}

.homepage .modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
}

.homepage .modal-content {
    background-color: #fefefe;
    margin: 8% 15% 15% 30%;
    padding: 20px;
    border: 1px solid #888;
    width: 40%;
    left: 15vw;
}

.homepage .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.homepage .el-input--large {
    width: 60%;
}
</style>